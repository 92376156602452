import { ArrowForward } from "@mui/icons-material";
import { Button, Container, Divider, Grid, Paper, ToggleButton, Typography, styled, useTheme } from "@mui/material";
import { Field, Form, Formik } from "formik";
import { TextField, ToggleButtonGroup } from "formik-mui";
import organizationAPI from "src/controllers/organizations";

const StyledToggleButton = styled(ToggleButton)(({theme}) => ({
    borderRadius: '5px !important',
    border: `1px solid ${theme.colors.alpha.black[10]} !important`,
    font: '12px normal Inter !important',
    fontFamily: "Inter !important",
    marginRight: '12px',
    "& :last-of-type": {
        marginRight: 0
    },
}))

const StyledToggleButton2 = styled(ToggleButton)(({theme}) => ({
    borderRadius: '02px !important',
    border: `1px solid ${theme.colors.alpha.black[10]} !important`,
    font: '12px normal Inter !important',
    fontFamily: "Inter !important",
    marginRight: '12px',
    "& :last-of-type": {
        marginRight: 0
    },
    textTransform: 'unset'
}))

export default function OnboardingStepOne({ setStep, ...otherProps }) {
    const theme = useTheme()

    return (
        <Grid container item sx={{ p: 2, pb: 10, justifyContent: 'center' }}>
            <Formik
                initialValues={{
                    signUpReason: undefined,
                    schoolType: undefined, 
                    schoolSize: undefined,
                    name: ''
                }}
                onSubmit={(values, { setSubmitting }) => {
                    setSubmitting(true);
                    organizationAPI.initializeOnboarding(values)
                        .then(() => { window.history.pushState(null, "Alpaca Notify", "/welcome#1"); setStep(1); })
                        .catch((err) => { console.log(err) })
                }}
                validate={(values) => {
                    const errors: any = {}
                    if (!(values.signUpReason && values.schoolType && values.schoolSize && values.name)) {
                        errors.signUpReason = "Required"
                        errors.schoolType = "Required"
                        errors.schoolSize = "Required"
                        errors.name = "Required"
                    }
                    return errors
                }}
            >
                {({
                    values,
                    touched, 
                    isSubmitting,
                    submitForm,
                    dirty, isValid
                }) => (
                    <>
                    <Container maxWidth="md">
                        <Form>
                            <Grid item xs={12} sx={{ display: 'flex', alignItems: 'center', flexDirection: 'column', width: '60svw'  }} >
                                <Typography variant="h2">
                                    👋 Welcome to Alpaca Notify
                                </Typography>
                                <Typography variant="subtitle2" sx={{ marginTop: 1}}>
                                    Let's get you set up with the basics :)
                                </Typography>
                            </Grid>
                            <Grid item>
                                <Paper sx={{ p: 2 , mt: 4, borderBottomLeftRadius: 0, borderBottomRightRadius: 0 }}>
                                    <Typography variant="h4">Lets start with a  few questions</Typography>
                                    <Typography variant="body1">
                                        Your answers will help us tailor this experience to your organization
                                    </Typography>
                                </Paper>
                                <Paper sx={{ borderTopLeftRadius: 0, borderTopRightRadius: 0, borderTop: `1px solid ${theme.colors.alpha.black[10]}`, p: 2 }}>
                                    <Typography variant="h5" sx={{ mb: 1 }}>Why did you sign up with Alpaca?</Typography>
                                    <Field component={ToggleButtonGroup} name="signUpReason" type="checkbox" orientation="vertical" exclusive fullWidth>
                                        <StyledToggleButton2 sx={{ borderTopRightRadius: '5px !important', borderTopLeftRadius: '5px !important' }} value="need-system">
                                            I need a notification system for my school/organization.
                                        </StyledToggleButton2>
                                        <StyledToggleButton2 value="want-to-try">
                                            I want to try out Alpaca Notify to see if it is a good fit for my school.
                                        </StyledToggleButton2>
                                        <StyledToggleButton2 value="testy">
                                            I want to hear more about this Testy Testerson fellow...
                                        </StyledToggleButton2>
                                        <StyledToggleButton2 value="gray-lewis">
                                            I heard Gray Lewis is leading the dev team and I want to see if the hype is real....
                                        </StyledToggleButton2>
                                        <StyledToggleButton2 sx={{ borderBottomLeftRadius: '5px !important', borderBottomRightRadius: '5px !important' }} value="conference">
                                            I met Nate & Kyle at ISTE and they won't stop bugging me until I signed up. So here I am.
                                        </StyledToggleButton2>
                                    </Field>
                                    <Typography variant="h5" sx={{ mt: 2, mb: 1 }}>Where do you work?</Typography>
                                    <Field 
                                        component={ToggleButtonGroup} 
                                        name="schoolType" 
                                        exclusive
                                        type="checkbox"
                                    >
                                        <StyledToggleButton value="highschool" >High school</StyledToggleButton>
                                        <StyledToggleButton value="middleschool" >Middle school</StyledToggleButton>
                                        <StyledToggleButton value="elementary" >Elementary</StyledToggleButton>
                                        <StyledToggleButton value="other">Other</StyledToggleButton>
                                    </Field>
                                    <Typography variant="h5" sx={{ mt: 2, mb: 1 }}>How big is your organization?</Typography>
                                    <Field component={ToggleButtonGroup} name="schoolSize" type="checkbox" exclusive>
                                        <StyledToggleButton value="100">&lt;100</StyledToggleButton>
                                        <StyledToggleButton value="300">100-300</StyledToggleButton>
                                        <StyledToggleButton value="500">300-500</StyledToggleButton>
                                        <StyledToggleButton value="1000">&gt;500</StyledToggleButton>
                                    </Field>
                                </Paper>
                            </Grid>
                            <Grid item xs={12} sx={{ mt: 2 }}>
                                <Paper sx={{ p: 3 }}>
                                    <Typography variant="h4">Give your organization a name</Typography>
                                    <Field
                                        sx={{ mt: 2 }}
                                        fullWidth
                                        component={TextField}
                                        id="name"
                                        name="name"
                                        size="small"
                                        label="Organization name"
                                    />
                                    <Typography sx={{ mt: 1 }} variant="subtitle1">
                                        Your console is where you will do all of the scheduling, organizing and creation of your organization's notifications. Make sure that it is accurate to your organization.        
                                    </Typography> 
                                </Paper>
                            </Grid>
                        </Form>
                    </Container>
                    <Grid item justifySelf="flex-end" sx={{ position: "fixed", bottom: 0 }} xs={12}>
                        <Paper variant="outlined" sx={{
                            height: '64px',
                            width: "100svw",
                            paddingLeft: '20svw',
                            paddingRight: '20svw',
                            display: 'flex',
                            justifyContent: 'center',
                            paddingTop: '12px',
                            paddingBottom: '12px',
                            borderRadius: 0,
                        }}>
                            <Button sx={{ width: '256px' }} disabled={!(dirty && isValid)} variant="contained" endIcon={<ArrowForward/>} onClick={() => { submitForm() }}>Get Started</Button>
                        </Paper>
                    </Grid>
                    </>
                )}
            </Formik>
        </Grid>
    )
}