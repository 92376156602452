import { FormControlLabel, Radio, Tooltip, styled, useTheme } from "@mui/material";
import { Field } from "formik";
import { RadioGroup } from "formik-mui";
import BannerIcon from 'src/components/atoms/BannerIcon/BannerIcon';
import FullscreenIcon from 'src/components/atoms/FullscreenIcon/FullscreenIcon';
import FullscreenBannerIcon from 'src/components/atoms/FullscreenBannerIcon/FullscreenBannerIcon';
import PopupIcon from 'src/components/atoms/PopupIcon/PopupIcon';

const CustomMUIFormLabel = styled('div')(({ theme }) => ({
    position: 'absolute', backgroundColor: 'white', marginTop: '-16px', marginLeft: '-9px',
    paddingLeft: '4px',
    paddingRight: '4px',
    fontSize: '11px',
    fontFamily: 'Inter',
    fontWeight: 400,
    color: theme.colors.alpha.black[70],
}));

export default function AlpacaNotifStyleSelectFormik({ value }) {
    const theme = useTheme();
    return (
        <Field
            value={value}
            component={RadioGroup}
            name="notifStyle"
            sx={{
                flexDirection: 'row',
                justifyContent: 'space-between',
                border: `1px solid ${theme.colors.alpha.black[30]}`,
                borderRadius: '6px',
                p: .75,
                pl: 2,
            }}
        >   
            <CustomMUIFormLabel>Type</CustomMUIFormLabel>
            <Tooltip title="Fullscreen">
                <FormControlLabel
                    value="fullscreen"
                    control={<Radio size="small" />}
                    label={
                        <FullscreenIcon
                        sx={{ marginTop: '8px', color: theme.colors.primary['main'] }}
                        fontSize="medium"
                        ></FullscreenIcon>
                    }
                />
            </Tooltip>
            <Tooltip title="Fullscreen Banner">
                <FormControlLabel
                    value="fullscreenBanner"
                    control={<Radio size="small" />}
                    label={
                        <FullscreenBannerIcon
                        sx={{ marginTop: '8px', color: theme.colors.primary['main'] }}
                        fontSize="medium"
                        ></FullscreenBannerIcon>
                    }
                />
            </Tooltip>
            <Tooltip title="Popup">
                <FormControlLabel
                    value="popup"
                    control={<Radio size="small" />}
                    label={
                        <PopupIcon
                        sx={{ marginTop: '8px', color: theme.colors.primary['main'] }}
                        fontSize="medium"
                        ></PopupIcon>
                    }
                />
            </Tooltip>
            <Tooltip title="Banner">
                <FormControlLabel
                    value="banner"
                    control={<Radio size="small" />}
                    label={
                        <BannerIcon
                        sx={{ marginTop: '8px', color: theme.colors.primary['main'] }}
                        fontSize="medium"
                        ></BannerIcon>
                    }
                />
            </Tooltip>
        </Field>
    )
}