import { Bolt, Cancel, CancelOutlined, Close } from "@mui/icons-material";
import { 
    Chip,
    styled,
    ChipProps,
    lighten,
    darken,
    useTheme
} from "@mui/material";


const AlpacaChip = styled(Chip)({
    borderRadius: '8px',
    p: '3px',
    height: '28px',
    border: '2.5px solid',
    color: 'black',
    backgroundColor: 'white',
    borderColor: '#e2e2e2',
    margin: 0,
})

const AlpacaChipSmall = styled(Chip)(
    {
        color: 'white',
        border: 0,
        height: '22px',
        fontWeight: 500,
        borderRadius: '3px'
    } 
)


const RoundedSquare = styled('div')({
    borderRadius: 3,
    width: '14px',
    height: '14px',
})

interface TagProps extends ChipProps {
    tagColor: string, 
    functional?: boolean,
    selected?: boolean,
}

function getContrastYIQ(hexcolor){
	var r = parseInt(hexcolor.substr(0,2),16);
	var g = parseInt(hexcolor.substr(2,2),16);
	var b = parseInt(hexcolor.substr(4,2),16);
	var yiq = ((r*299)+(g*587)+(b*114))/1000;
	return (yiq >= 128) ? 'black' : 'white';
}

export default function Tag(props: TagProps) {
    const { label, tagColor, functional, selected, ...otherProps } = props
    const theme = useTheme();

    const tagColorHashless = tagColor.substring(1, tagColor.length - 1)

    return !selected ? (
      <AlpacaChipSmall
        {...otherProps}
        label={label}
        color="default"
        size="small"
        style={{
          background: lighten(tagColor, 0.5),
          color: darken(tagColor, 0.5),
          fontWeight: 500,
        }}
        icon={
          functional ? (
            <Bolt
              sx={{
                color: `${darken(tagColor, 0.65)} !important`,
                marginRight: '-8px !important'
              }}
            ></Bolt>
          ) : (
            <></>
          )
        }
      />
    ) : (
      <AlpacaChipSmall
        {...otherProps}
        label={label}
        color="default"
        size="small"
        style={{
          background: tagColor,
          borderColor: darken(tagColor, 0.2),
          borderWidth: '2px',
          borderStyle: 'solid',
          fontWeight: 500,
          color:
            getContrastYIQ(tagColorHashless) === 'black'
              ? `${darken(tagColor, 0.5)}`
              : theme.colors.alpha.white[100]
        }}
        icon={
          <Close
            sx={{
              fontSize: '8px',
              marginRight: '-6px !important',
              color: 
              getContrastYIQ(tagColorHashless) === 'black'
                ? `${darken(tagColor, 0.5)} !important`
                : `${theme.colors.alpha.white[100]} !important`
            }}
            
          ></Close>
        }
      />
    );
}