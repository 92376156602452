import axios from 'src/utils/axios';
const scheduleAPI: any = {};

interface recurringDetails {
  content: { title: string, subtitle: string, body: string },
  notifStyle: 'fullscreenBanner' | 'fullscreen' | 'popup' | 'banner',
  date: Date,
  cronString: string,
  skipDays?: number,
  name: string,
  targets: {
    groups: [string],
    members: [string],
  }
  endDate: Date,
  formData: any,
  stylingInfo: {
    backgroundColor: string,
    icon: string
  }
  lifetime: Number
}

scheduleAPI.getUpcoming = async (domain, startDate, endDate) => {
  const tz = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const result = await axios.get(`/organizations/getUpcoming`, {
    params: { domain, startDate, endDate, tz }
  })
  return result.data;
}

scheduleAPI.getUpcomingWithDetails = async (domain, startDate, endDate) => {
    const tz = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const result = await axios.get(`/organizations/getUpcoming`, {
      params: { domain, startDate, endDate, tz, fullDetails: true }
    })
    return result.data;
  }
  

scheduleAPI.getScheduledNotificationDetails = async (id) => {
    const result = await axios.get('/scheduling/getScheduledNotificationDetails', { 
        params: { id }
    })
    return result.data;
}

scheduleAPI.scheduleNotification = async (domain, targets, content, date, name, notifStyle, stylingInfo, notifLifetime) => {
  if (!(notifStyle === 'banner' || notifStyle === 'fullscreen' || notifStyle === 'fullscreenBanner' || notifStyle === 'popup')) {
    throw new Error('Could not parse notification type')
  } else {
    const result = await axios.post(`/scheduling/scheduleNotification`,
    { content, date, notifStyle, targets, name, stylingInfo, lifetime: notifLifetime },
    { params: { domain } }
  )
  return result.data;
  }
}

scheduleAPI.rescheduleNotification = async (domain, date, notificationID) => {
    const result = await axios.post(`/scheduling/rescheduleNotification`, 
    { date, notificationID },
    { params: { domain } })
    return result.data;
}

scheduleAPI.updateScheduledNotification = async (domain, update, notificationID) => {
  if (!(update.notifStyle === 'banner' || update.notifStyle === 'fullscreen' || update.notifStyle === 'fullscreenBanner' || update.notifStyle === 'popup')) {
    throw new Error('Could not parse notification type')
  } else {
    const result = await axios.post(`/scheduling/updateScheduledNotification`, 
    { update, notificationID },
    { params: { domain } }
  )
  return result.data;
  }
}

scheduleAPI.deleteScheduledNotification = async (domain, notificationID) => {
  const result = await axios.post(`/scheduling/deleteScheduledNotification`, 
    { notificationID },
    { params: { domain } }
  )
  return result.data;
} 

scheduleAPI.scheduleRecurringNotification = async (domain: string, { targets, content, date, notifStyle, name, cronString, skipDays, endDate, lifetime, stylingInfo, formData }: recurringDetails) => {
  if (!(notifStyle === 'banner' || notifStyle === 'fullscreen' || notifStyle === 'fullscreenBanner' || notifStyle === 'popup')) {
    throw new Error('Could not parse notification type')
  } else {
    const result = await axios.post(`/scheduling/scheduleRecurringNotification`, 
    { content, targets, date, name, notifStyle, recurrence: cronString, skipDays, endDate, stylingInfo, formData, lifetime },
    { params: { domain } }  
  )
  return result.data;
  }
}

scheduleAPI.rescheduleRecurringNotification = async (domain: string, notificationID, { targets, content, date, notifStyle, name, cronString, skipDays, endDate, lifetime, stylingInfo, formData }: recurringDetails) => {
  if (!(notifStyle === 'banner' || notifStyle === 'fullscreen' || notifStyle === 'fullscreenBanner' || notifStyle === 'popup')) {
    throw new Error('Could not parse notification type')
  } else {
    const result = await axios.post(`/scheduling/rescheduleRecurringNotification`, 
    { notificationID, content, targets, date, name, notifStyle, recurrence: cronString, skipDays, endDate, stylingInfo, formData, lifetime },
    { params: { domain } }  
  )
  return result.data;
  }
}

export default scheduleAPI;