import axios from 'src/utils/axios';
const organizationAPI: any = {};

organizationAPI.createGroup = async (name, domain) => {
  const result = await axios.post(`/organizations/groups/create`, {
    name
  }, {
    params: { domain }
  })
  return result.data;
}

organizationAPI.initializeOnboarding = async (surveyResults) => {
    const result = await axios.post('/organizations/initializeOnboarding', {
        surveyResults
    })
    return result.data;
}

organizationAPI.acceptTerms = async (checkboxes) => {
    const result = await axios.post('/organizations/acceptTerms', {
        checkboxes
    })
    return result.data;
}

export default organizationAPI;