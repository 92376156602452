import { Add, CheckBox, Close, CopyAll, Delete, DeleteForever, DeleteForeverOutlined, DeleteForeverTwoTone, Done, ExitToApp, ExpandMoreTwoTone, LinkTwoTone, Lock, SaveTwoTone, ScheduleTwoTone } from "@mui/icons-material";
import { Typography, Container, Card, Grid, Paper, Button, Divider, useTheme, Box, Pagination, CardContent, Collapse, SvgIcon, TextField, Modal, CardHeader, IconButton, FormHelperText, Stack, Checkbox, FormControlLabel, Alert } from "@mui/material";
import { useMutation, useQuery } from "@tanstack/react-query";
import { format } from "date-fns";
import { useSnackbar } from "notistack";
import { useMemo, useState, useReducer, useCallback } from "react";
import PageTitleWrapper from "src/components/PageTitleWrapper";
import AlpacaRecipientsPane from "src/components/molecules/AlpacaRecipientsPane/AlpacaRecipientsPane";

import webhooksAPI from "src/controllers/webhooks";
import AlpacaTemplateSearch from "src/components/molecules/AlpacaTemplateSearch/AlpacaTemplateSearch";
import { LoadingButton } from "@mui/lab";
import useAuth from "src/hooks/useAuth";
import templateAPI from "src/controllers/templates";

function AlpacaWebhookManager() {
    const theme = useTheme();
    const acc = useAuth();

    const [ expanded, setExpanded ] = useState<string | false>(false);
    const [ searchResults, setSearchResults ] = useState([]);
    const [ targetSearchValue, setTargetSearchValue ] = useState(null);
    const [ authModalOpen, setAuthModalOpen ] = useState(false);
    const [ authModalAccepted, setAuthModalAccepted ] = useState(false);
    const [ targets, setTargets ] = useState({ members: [], groups: [] })

    const handleAcceptModal = useCallback((event) => {
        setAuthModalAccepted(event.target.checked);
    }, [])
    
    /*
    const setTargets = (webhookID, targets) => {
        dispatch("")
    }
    */
   
    function reducer(state, action) {
        let nextState;
        switch (action.type) {
            case "fetched_webhooks":
                nextState = { ...state }
                nextState.webhooks = Object.assign({}, action.webhooks)
                return nextState;
            case "changed_name":
                nextState = { ...state }
                nextState.webhooks[action.id].name = action.nextName;
                return nextState;
            case "changed_template":
                nextState = { ...state }
                nextState.webhooks[action.id].template = action.nextTemplate;
                return nextState;
            case "changed_targets":
                nextState = { ...state }
                nextState.webhooks[action.id].targets = action.nextTargets;
                return nextState;
            default:
                throw new Error("Invalid action");
        }
    }

    const [ formState, dispatch ] = useReducer(reducer, { webhooks: null })

    const [ page, setPage ] = useState(1);
    const handleChange = useCallback((event, page) => {
        setPage(page)
    }, [])

    const { data, isLoading, refetch } = useQuery(['webhooksData', page - 1], () => {
        return webhooksAPI.listWebhooks(page - 1)
            .then((res) => { return res })
    }, { 
        onSuccess: (data) => { 
            const webhooks = data.webhookConfigs;
            let webhooksObj = {};
            webhooks.forEach((webhook) => {
                webhooksObj[webhook._id] = Object.assign({}, webhook);
            })
            dispatch({ type: "fetched_webhooks", webhooks: webhooksObj }) 
        },
        placeholderData: [],
    })

    const selectedWebhookData = useMemo(() => {
        if (!expanded) {
            return {}
        }
        return data.webhookConfigs.find((item) => { return item._id === expanded })
    }, [expanded, data])

    const { enqueueSnackbar } = useSnackbar();

    const gradientsArrayMap = useMemo(() => { return Object.keys(theme.colors.gradients) }, [theme])

    const createBlankWebhookMutation = useMutation<any>(() => { 
        return webhooksAPI.createWebhook()
    },{
        onSuccess: (data) => {
            setAuthModalOpen(true);
            refetch();
            templateQuery.refetch();
        }
    }
    )

    const deleteWebhookMutation = useMutation((webhookConfigID) => {
        return webhooksAPI.deleteWebhook(webhookConfigID)
    }, {
        onSuccess: () => {
            refetch();
        }
    })

    const updateWebhookMutation = useMutation({
        mutationFn: ({
            webhookConfigID,
            update
        }: {
            webhookConfigID: string;
            update: any;
        }) => {
            return webhooksAPI.updateWebhook(webhookConfigID, update);
        },
        onSuccess: () => {
            refetch();
        }
    });

    const templateQuery = useQuery({
        queryKey: ['templateData'],
        queryFn: () => {
            return templateAPI.listTemplates(acc.user.domain).then((res) => {
                return res.templates.map((obj) => {
                    return {
                        ...obj.content,
                        notifStyle: obj.style,
                        name: obj.name,
                        tags: obj.tags,
                        _id: obj._id,
                        stylingInfo: {
                            icon: obj.icon,
                            backgroundColor: obj.backgroundColor
                        },
                        createdAt: obj.createdAt,
                        updatedAt: obj.updatedAt
                    };
                });
            });
        },
        placeholderData: []
    });

    const setTargetsForId = useCallback((targets, id) => {
        dispatch({ type: "changed_targets", nextTargets: targets, id });
    }, [])

    return (
        <>
            <PageTitleWrapper>
                <Grid
                    container
                    direction="row" 
                    justifyContent="spaceBetween"
                    alignItems="center"
                >
                    <Grid display="flex" item>
                        <Typography
                            sx={{
                                marginRight: '10px',
                                mb: 0,
                                lineHeight: '50px',
                                fontSize: '24px'
                            }}
                            variant="h3"
                            component="h3"
                            gutterBottom
                        >
                            Webhooks
                        </Typography>
                        <Typography
                            sx={{
                                marginRight: '10px',
                                fontSize: '34px',
                                lineHeight: '50px',
                                mb: 0
                            }}
                            variant="h3"
                            component="h3"
                            gutterBottom
                        >
                            &#9702;
                        </Typography>
                        <Typography sx={{ lineHeight: '50px' }} variant="h6">
                            Trigger Alpaca notifications from external event sources
                        </Typography>
                    </Grid>
                </Grid>
            </PageTitleWrapper>
            <Container maxWidth="md">
                <Paper variant="outlined" >
                    <Modal open={authModalOpen} onClose={() => setAuthModalOpen(false)} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                        <Card sx={{ maxWidth: '700px', width: { xs: '100%', md: '66%' }, mr: 2, ml: 2 }}>
                            <Typography variant="h3" sx={{ p: 2, pt: 3, pb: 0, fontSize: '20px', }}>Webhook Authentication</Typography>
                            <CardContent sx={{ overflowWrap: 'break-word' }}>
                                <Stack direction="row" >
                                <TextField fullWidth sx={{ "& .MuiInputBase-root ": { borderTopRightRadius: 0, borderBottomRightRadius: 0, borderRightColor: 'transparent' }}} focused={false} value={createBlankWebhookMutation.data && createBlankWebhookMutation.data.jwt} contentEditable="false">
                                </TextField>
                                <Button 
                                    size="large"
                                    sx={{ lineHeight: '29px',fontSize: '14px', height: '100%', width: '120px', borderTopLeftRadius: 0, borderBottomLeftRadius: 0, ml: '-1px'}}
                                    onClick={() => { 
                                        enqueueSnackbar(('Successfully copied JWT Token to clipboard'), {variant: 'success', anchorOrigin: { vertical: 'bottom', horizontal: 'center' }, autoHideDuration: 2000 } );
                                        navigator.clipboard.writeText(createBlankWebhookMutation.data.jwt); 
                                    }} 
                                    variant="outlined" 
                                    startIcon={<CopyAll/>}
                                >Copy</Button>
                                </Stack>

                                <Alert severity="warning" sx={{ mt: 1}}>
                                    Warning! For security reasons this token will no longer be accessible to you once this panel has been closed. Please store it appropriately.
                                </Alert> 
                            </CardContent>
                            <CardContent sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', pt: 0, mt: 0 }}>
                                <FormControlLabel control={<Checkbox value={authModalAccepted} onChange={handleAcceptModal} />} label="I have stored my token in a safe place" />
                                <Button startIcon={<Done/>} disabled={!authModalAccepted} onClick={() => { setAuthModalOpen(false); setAuthModalAccepted(false); }} variant="contained">Done</Button>
                            </CardContent>
                        </Card>
                    </Modal>
                    <Grid container sx={{ minHeight: '638px' }} alignContent="space-between">
                        <Grid container item>
                            <Grid container item xs={12} sx={{ p: 2 }}>
                                <Grid item>
                                    <LoadingButton loading={createBlankWebhookMutation.isLoading || isLoading} variant="contained" onClick={() => {
                                        createBlankWebhookMutation.mutate()
                                    }} startIcon={<Add></Add>}>Create Webhook</LoadingButton>
                                </Grid>
                                <Divider></Divider>
                            </Grid> 
                                { formState.webhooks && data.webhookConfigs && data.webhookConfigs.map((webhookConfig, index) => 
                                <div key={webhookConfig._id}>
                                    { formState.webhooks[webhookConfig._id] && 
                                    <Grid key={webhookConfig._id} container item xs={12} sx={{ borderTop: `1px solid ${theme.colors.alpha.black[10]}`}}>
                                        <Grid container item justifyContent="space-between" sx={{ p: 2, cursor: 'pointer'  }} onClick={() => { setExpanded((expanded === webhookConfig._id) ? false:webhookConfig._id); }} >
                                            <Grid container alignItems="center" item xs={10} spacing={2}>
                                                <Grid item>
                                                    <Box sx={{ height: '48px', width: '48px', borderRadius: '999em', background: theme.colors.gradients[gradientsArrayMap[(index + (page*6) +10 ) % gradientsArrayMap.length]]}}></Box>
                                                </Grid>
                                                <Grid container item xs={9} direction="column">
                                                    <Grid item>
                                                        <Typography variant="h4">{ webhookConfig.name }</Typography>
                                                    </Grid>
                                                    <Grid item sx={{ display: 'flex', alignItems: 'center' }}>
                                                        <ScheduleTwoTone sx={{ fontSize: '14px', lineHeight: '14px', marginRight: '4px' }}></ScheduleTwoTone>
                                                        <Typography variant="body2" >
                                                            { `Created on ${format(new Date(webhookConfig.createdAt), "MMMM d, yyyy")}` }
                                                        </Typography>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                            <Grid container item xs={2} alignContent="center" alignItems="center" justifyContent="flex-end" >
                                                <Grid item display="flex" alignItems="center">
                                                    <SvgIcon fontSize="large" sx={{ transform: (expanded===webhookConfig._id) ? 'rotate(180deg)':'rotate(0deg)', transition: 'transform .2s ease' }}>
                                                        {<ExpandMoreTwoTone></ExpandMoreTwoTone>}
                                                    </SvgIcon>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        <Collapse in={ expanded===webhookConfig._id } sx={{ width: '100%', zIndex: 10 }}>
                                        <Grid container direction="row" spacing={2} sx={{ p: 2, pt: 0, mt: 0 }}>
                                            <Grid container item xs={8} alignContent="flex-start" spacing={2}>
                                                <Grid container item>
                                                    <Grid item>
                                                        <TextField 
                                                            size="small" 
                                                            label="Name" 
                                                            value={formState.webhooks[webhookConfig._id].name} 
                                                            onChange={(e) => dispatch({ type: 'changed_name', id: webhookConfig._id, nextName: e.target.value })}
                                                            >
                                                        </TextField>
                                                    </Grid>
                                                </Grid>
                                                <Grid container item>
                                                    <AlpacaTemplateSearch
                                                        options={templateQuery.data}
                                                        value={formState.webhooks[webhookConfig._id].template}
                                                        onChange={(e, value) => { dispatch({ type: 'changed_template', id: webhookConfig._id, nextTemplate: value }) }}
                                                    ></AlpacaTemplateSearch>
                                                </Grid>
                                            </Grid>
                                            <Grid item xs={4}>
                                                <Card variant="outlined" sx={{ boxShadow: 0, display: 'flex', height: '100%' }}>
                                                    <CardContent
                                                        sx={{
                                                            minHeight: '100%',
                                                            height: '300px',
                                                            display: 'flex',
                                                            flexGrow: 1,
                                                            flexDirection: 'column',
                                                            justifyContent: 'flex-start',
                                                            boxShadow: 0,
                                                            p: 1.5,
                                                            borderColor: theme.colors.alpha.black[30],
                                                        }}
                                                    >
                                                        <AlpacaRecipientsPane
                                                            sx={{
                                                                overflow: 'scroll',
                                                            }}
                                                            searchResults={searchResults}
                                                            setSearchResults={setSearchResults}
                                                            targetSearchValue={targetSearchValue}
                                                            setTargetSearchValue={setTargetSearchValue}
                                                            targets={formState.webhooks[webhookConfig._id].targets}
                                                            setTargets={(val) => {setTargetsForId(val, webhookConfig._id) }}
                                                        ></AlpacaRecipientsPane>
                                                    </CardContent>
                                                </Card>
                                            </Grid>
                                            <Grid xs={12} container item justifyContent="space-between">
                                                <Grid container item spacing={2} xs={8}>
                                                    <Grid item>
                                                        <Button 
                                                            sx={{ height: '100%'}}
                                                            onClick={() => { 
                                                                enqueueSnackbar(('Successfully saved webhook configuration'), {variant: 'success', anchorOrigin: { vertical: 'bottom', horizontal: 'center' }, autoHideDuration: 2000 } );
                                                                const update = { 
                                                                    name: formState.webhooks[webhookConfig._id].name,
                                                                    targets: formState.webhooks[webhookConfig._id].targets,
                                                                    template: formState.webhooks[webhookConfig._id].template,
                                                                }
                                                                updateWebhookMutation.mutate({ webhookConfigID: webhookConfig._id, update })
                                                            }} 
                                                            variant="contained" 
                                                            startIcon={<SaveTwoTone/>}
                                                        >Save</Button>
                                                    </Grid>
                                                    <Grid item>
                                                        <Button 
                                                            sx={{ height: '100%'}}
                                                            onClick={() => { 
                                                                enqueueSnackbar(('Successfully copied URL to clipboard'), {variant: 'success', anchorOrigin: { vertical: 'bottom', horizontal: 'center' }, autoHideDuration: 2000 } );
                                                                navigator.clipboard.writeText(`${process.env.REACT_APP_API_URL}/trigger/${webhookConfig.slug}`); 
                                                            }} 
                                                            variant="outlined" 
                                                            startIcon={<LinkTwoTone></LinkTwoTone>}
                                                        >Copy Webhook URL</Button>
                                                    </Grid>
                                                </Grid>
                                                <Grid item>
                                                    <Button
                                                        variant="outlined"
                                                        color="error"
                                                        startIcon={<DeleteForeverTwoTone/>}
                                                        onClick={() => {
                                                            deleteWebhookMutation.mutate(webhookConfig._id)
                                                        }}
                                                    >
                                                        Delete
                                                    </Button>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        </Collapse>
                                    </Grid>
                                    }
                                </div>
                                )}
                            <Divider sx={{ width: '100%'}}></Divider>
                        </Grid>
                        <Grid item xs={12} sx={{ display: 'flex', justifyContent: "center", p: 1, alignSelf: 'flex-end', marginTop: 'auto', borderTop: `1px solid ${theme.colors.alpha.black['10']}` }}>
                            <Pagination page={page} onChange={handleChange} count={Math.ceil(data.webhookConfigsLength / 6) || 0} hidePrevButton hideNextButton />
                        </Grid>
                    </Grid>
                </Paper>
            </Container>
        </>
    );
}

export default AlpacaWebhookManager;