import type { ReactNode } from 'react';

import AnalyticsTwoToneIcon from '@mui/icons-material/AnalyticsTwoTone';
import HealthAndSafetyTwoToneIcon from '@mui/icons-material/HealthAndSafetyTwoTone';
import AssignmentIndTwoToneIcon from '@mui/icons-material/AssignmentIndTwoTone';
import AccountTreeTwoToneIcon from '@mui/icons-material/AccountTreeTwoTone';
import StorefrontTwoToneIcon from '@mui/icons-material/StorefrontTwoTone';
import VpnKeyTwoToneIcon from '@mui/icons-material/VpnKeyTwoTone';
import ErrorTwoToneIcon from '@mui/icons-material/ErrorTwoTone';
import DesignServicesTwoToneIcon from '@mui/icons-material/DesignServicesTwoTone';
import SupportTwoToneIcon from '@mui/icons-material/SupportTwoTone';
import ReceiptTwoToneIcon from '@mui/icons-material/ReceiptTwoTone';
import BackupTableTwoToneIcon from '@mui/icons-material/BackupTableTwoTone';
import SmartToyTwoToneIcon from '@mui/icons-material/SmartToyTwoTone';
import { BookTwoTone, CalendarTodayTwoTone, Create, HistoryTwoTone, LibraryBooksTwoTone, QuickreplyTwoTone, SettingsTwoTone, WebhookTwoTone } from '@mui/icons-material';
import { CreateTwoTone } from '@mui/icons-material';

export interface MenuItem {
  link?: string;
  icon?: ReactNode;
  badge?: string;
  badgeTooltip?: string;

  items?: MenuItem[];
  name: string;
}

export interface MenuItems {
  items: MenuItem[];
  heading: string;
}

const menuItems: MenuItems[] = [
  {
    heading: '',
    items: [
      {
        name: 'Notifications',
        link: '',
        items: [
        {
            name: 'Compose',
            link: '/compose',
            icon: CreateTwoTone
        },
        {
            name: 'Calendar',
            link: '/calendar',
            icon: CalendarTodayTwoTone,
        },
        {
        name: 'Library',
        link: '/library',
        icon: LibraryBooksTwoTone
        },
        ]
      },
      {
        name: 'Settings',
        link: '',
        items: [
        {
            name: 'Quick Send',
            link: '/quick-send-settings',
            icon: QuickreplyTwoTone,
        },
        {
            name: 'Logging',
            link: "/logging",
            icon: HistoryTwoTone,
        },
        {
            name: "Webhooks",
            link: '/webhooks',
            icon: WebhookTwoTone,
        }
        ]
      },
    ]
  },
];

export default menuItems;
