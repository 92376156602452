import { Icon, styled } from "@mui/material"
import { Checkbox, CheckboxProps } from "formik-mui"
interface CustomCheckboxProps extends CheckboxProps {
    day: "monday" | "tuesday" | "wednesday" | "thursday" | "friday" | "saturday" | "sunday"
} 

function getIcon(active: boolean, day: string) {
    switch (day) {
        case "monday": 
            return (active) ? 
            <Icon fontSize="large">
                <img alt="Monday selected" style={{ height: "100%", transform: "translateY(-4px)" }} src="/static/images/icons/monday-active.svg"></img>
            </Icon>
            :
            <Icon fontSize="large">
                <img alt="Monday unselected" style={{ height: "100%", transform: "translateY(-4px)" }} src="/static/images/icons/monday.svg"></img>
            </Icon>
        case "tuesday": 
            return (active) ? 
            <Icon fontSize="large">
                <img alt="Tuesday selected" style={{ height: "100%", transform: "translateY(-4px)" }} src="/static/images/icons/tuesday-active.svg"></img>
            </Icon>
            :
            <Icon fontSize="large">
                <img alt="Tuesday unselected" style={{ height: "100%", transform: "translateY(-4px)" }} src="/static/images/icons/tuesday.svg"></img>
            </Icon>
        case "wednesday": 
            return (active) ? 
            <Icon fontSize="large">
                <img alt="Wednesday selected" style={{ height: "100%", transform: "translateY(-4px)" }} src="/static/images/icons/wednesday-active.svg"></img>
            </Icon>
            :
            <Icon fontSize="large">
                <img alt="Wednesday unselected" style={{ height: "100%", transform: "translateY(-4px)" }} src="/static/images/icons/wednesday.svg"></img>
            </Icon>
        case "thursday": 
            return (active) ? 
            <Icon fontSize="large">
                <img alt="Thursday selected" style={{ height: "100%", transform: "translateY(-4px)" }} src="/static/images/icons/thursday-active.svg"></img>
            </Icon>
            :
            <Icon fontSize="large">
                <img alt="Thursday unselected" style={{ height: "100%", transform: "translateY(-4px)" }} src="/static/images/icons/thursday.svg"></img>
            </Icon>
        case "friday": 
            return (active) ? 
            <Icon fontSize="large">
                <img alt="Friday selected" style={{ height: "100%", transform: "translateY(-4px)" }} src="/static/images/icons/friday-active.svg"></img>
            </Icon>
            :
            <Icon fontSize="large">
                <img alt="Friday unselected" style={{ height: "100%", transform: "translateY(-4px)" }} src="/static/images/icons/friday.svg"></img>
            </Icon>
        case "saturday": 
            return (active) ? 
            <Icon fontSize="large">
                <img alt="Saturday selected" style={{ height: "100%", transform: "translateY(-4px)" }} src="/static/images/icons/saturday-active.svg"></img>
            </Icon>
            :
            <Icon fontSize="large">
                <img alt="Saturday unselected" style={{ height: "100%", transform: "translateY(-4px)" }} src="/static/images/icons/saturday.svg"></img>
            </Icon>
        case "sunday": 
            return (active) ? 
            <Icon fontSize="large">
                <img alt="Sunday selected" style={{ height: "100%", transform: "translateY(-4px)" }} src="/static/images/icons/sunday-active.svg"></img>
            </Icon>
            :
            <Icon fontSize="large">
                <img alt="Sunday unselected" style={{ height: "100%", transform: "translateY(-4px)" }} src="/static/images/icons/sunday.svg"></img>
            </Icon>
    }
}

function AlpacaCheckbox(props: CustomCheckboxProps) {
    return (
      <Checkbox
        disableRipple
        color="default"
        checkedIcon={getIcon(true, props.day)}
        icon={getIcon(false, props.day)}
        indeterminate={false}
        {...props}
      />
    );
}

const StyledAlpacaCheckbox = styled(AlpacaCheckbox)(({theme}) => ({
    '&:hover': {
        '.material-icons': {
            'backgroundColor': theme.colors.primary['lighter'],
            'borderRadius': '6px'
        }
    }
}))

export default StyledAlpacaCheckbox;