import { Box } from '@mui/material';

import Logo from 'src/components/LogoSign';

import Lottie from 'react-lottie-player';
import LottieJson from './animation.js';

function AppInit() {
  return (
    <Box
      sx={{
        position: 'fixed',
        left: 0,
        top: 0,
        bottom: 0,
        right: 0,
        width: '100%',
        height: '100%'
      }}
      display="flex"
      alignItems="center"
      justifyContent="center"
    >
      <Lottie
        loop
        animationData={LottieJson}
        play
        style={{ width: 150, height: 150 }}
      >
      </Lottie>
    </Box>
  );
}

export default AppInit;
