import { SearchTwoTone } from '@mui/icons-material';
import {
    TextField,
    InputAdornment
} from '@mui/material';

export default function TemplateSearchBar(props) {
    const { filter, setFilter, ...otherProps } = props;

    return (
        <>
            <TextField
                {...otherProps}
                label={filter ? ' ' : 'Type your search terms here...'}
                fullWidth
                variant="outlined"
                sx={{
                    borderRadius: '8px',
                    borderWidth: '8px'
                }}
                value={filter}
                InputLabelProps={{
                    shrink: false,
                    sx: {
                        pl: 3.5
                    }
                }}
                onChange={(e) => setFilter(e.target.value)}
                InputProps={{
                    sx: {},
                    startAdornment: (
                        <InputAdornment position="start">
                            <SearchTwoTone color="primary"></SearchTwoTone>
                        </InputAdornment>
                    )
                }}
            />
        </>
    );
}
