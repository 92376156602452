export const NOTIF_CONSTRAINTS = {
    POPUP: {
        TITLE: 25,
        SUBTITLE: 50,
        BODY: 240,
    },
    FULLSCREEN: {
        TITLE: 24,
        SUBTITLE: 50,
        BODY: 350
    },
    FULLSCREEN_BANNER: {
        TITLE: 24,
        SUBTITLE: 48,
        BODY: 240,
    },
    BANNER: {
        TITLE: 24,
        SUBTITLE: 48, 
        BODY: 240,
    }
}

export enum NOTIF_STYLE_MAP { 
    'popup' = "POPUP",
    'fullscreen' = "FULLSCREEN",
    'fullscreenBanner' = "FULLSCREEN_BANNER",
    'banner' = "BANNER",
}