import { useState, useEffect, useCallback } from 'react';
import axios from 'src/utils/axios';

import { Helmet } from 'react-helmet-async';
import PageHeader from './PageHeader';
import Footer from 'src/components/Footer';

import { Grid } from '@mui/material';
import useRefMounted from 'src/hooks/useRefMounted';
import useAuth from 'src/hooks/useAuth';
import type { User } from 'src/models/user';
import PageTitleWrapper from 'src/components/PageTitleWrapper';

import Results from './Results';

import googleAPI from '../../../controllers/googleFunctions';

function ManagementUsers() {
  const isMountedRef = useRefMounted();
  const { user } = useAuth();
  const [users, setUsers] = useState<User[]>([]);
  const [selectedItems, setSelectedUsers] = useState<string[]>([]);

  const [ groupSelected, setGroupSelected ] = useState<any>(null)
  const [totalCount, setTotalCount] = useState<number>(0);

  const [limit, setLimit] = useState<number>(10);

  const [ isSubmittingGroupChange, setSubmittingState ] = useState(false);
  const [ isImportingGoogleUsers, setImportingGoogleUsers ] = useState(false);
  const [ groups, setGroups ] = useState([{name: "Example", id: "1"}]);

  const [ backendPageNumber, setBackendPageNumber ] = useState(0);

  const clearAndGetUsers = async () => {
    setUsers([]);
    try {
      googleAPI.fetchLocalDomainMembers(backendPageNumber, limit)
        .then((response) => {
          if (isMountedRef.current && response.data.users.length >= 1 ) {
            setTotalCount(response.data.totalCount)
            setUsers(response.data.users.map((user, index) => {
              return {
                email: user.primaryEmail,
                username: user.id,
                name: user.fullName,
                id: user.googleID, 
                groups: user.groups
              }
            }));
          }
        })
    } catch (err) {
      console.error(err);
    }
  }

  const submitGroupChange = async () => {
    setSubmittingState(true)
    try {
      const response = await axios.post(`/organizations/groups/${groupSelected.id}/addMember`, {
        "members": selectedItems
      }, {
        params: { domain: user.domain }
      })
      await clearAndGetUsers();
      setSubmittingState(false)
    } catch (err) {
      console.log(err)
      setSubmittingState(false)
    }
  }

  const submitGroupRemove = async () => {
    setSubmittingState(true)
    try {
      const response = await axios.post(`/organizations/groups/${groupSelected.id}/removeMember`, {
        "members": selectedItems
      }, {
        params: { domain: user.domain }
      })
      await clearAndGetUsers();
      setSubmittingState(false)
    } catch (err) {
      setSubmittingState(false)
    }
  }

  const handleImportGoogleUsers = async () => {
    setImportingGoogleUsers(true)
    await googleAPI.fetchDomainUsers()
    setImportingGoogleUsers(false)
  }

  const getUsers = () => {
    try {
      googleAPI.fetchLocalDomainMembers(backendPageNumber, limit)
        .then((response) => {
          if (isMountedRef.current && response.data.users.length >= 1 ) {
            setTotalCount(response.data.totalCount)
            setUsers(users.concat(response.data.users.map((user, index) => {
              return {
                email: user.primaryEmail,
                username: user.id,
                name: user.fullName,
                id: user.googleID, 
                groups: user.groups
              }
            })));
          }
        })
      setBackendPageNumber(backendPageNumber + 1)
    } catch (err) {
      console.error(err);
    }
}

  useEffect(getUsers, []);

  const getGroups = useCallback(async () => {
    try {
        const response = await axios.get('/organizations/groups', {
            params: { domain: user.domain }
        })
    
        if (isMountedRef.current) {
            setGroups(response.data.groups)
        }
    } catch (err) {
        console.error(err);
    }
    }, [isMountedRef]);
    
    useEffect(() => {
        getGroups()
    }, [getGroups])

  return (
    <>
      <Helmet>
        <title>Manage Google Users</title>
      </Helmet>
      <PageTitleWrapper>
        <PageHeader />
      </PageTitleWrapper>

      <Grid
        sx={{
          px: 2,
        }}
        container
        direction="row"
        justifyContent="center"
        alignItems="stretch"
        spacing={4}
      >
        <Grid item sx={{
          mx: { xs: 0, sm: 0, md: 0, lg: 12 }
        }} 
          xs={12}
        >
          <Results 
            submitGroupChange={submitGroupChange} 
            selectedItems={selectedItems} 
            setSelectedUsers={setSelectedUsers} 
            users={users} 
            setGroupSelected={setGroupSelected}
            groupSelected={groupSelected}
            isSubmittingGroupChange={isSubmittingGroupChange}
            tabs={groups}
            handleImportGoogleUsers={handleImportGoogleUsers}
            submitGroupRemove={submitGroupRemove}
            getUsers={getUsers}
            limit={limit}
            setLimit={setLimit}
            totalCount={totalCount}
            setTotalCount={setTotalCount}
          />
        </Grid>
      </Grid>
    </>
  );
}

export default ManagementUsers;
