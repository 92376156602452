import { Autocomplete, Avatar, Icon, ListItem, ListItemAvatar, ListItemText, SvgIcon, TextField, Tooltip, Typography } from "@mui/material";
import { useQuery } from "@tanstack/react-query";
import BannerIcon from "src/components/atoms/BannerIcon/BannerIcon";
import FullscreenBannerIcon from "src/components/atoms/FullscreenBannerIcon/FullscreenBannerIcon";
import FullscreenIcon from "src/components/atoms/FullscreenIcon/FullscreenIcon";
import PopupIcon from "src/components/atoms/PopupIcon/PopupIcon";
import templateAPI from "src/controllers/templates";
import useAuth from "src/hooks/useAuth";

import { notifStyleIcon } from "src/constants/iconConstants";

function AlpacaTemplateSearch(props) {
    return (
        <Autocomplete 
            disablePortal
            id="combo-box-demo"
            sx={{ width: 300 }}
            getOptionLabel={(option: any) => { return option.name }}
            renderInput={(params) => <TextField {...params} label="Template" />}
            isOptionEqualToValue={(option: any, value: any) => {
                if (option && value) {
                    return option._id === value._id
                }
                return false;
            }}
            renderOption={(props, option: any, state) => {
                return <ListItem {...props} key={option._id}>
                        { notifStyleIcon(option.notifStyle) }
                    <Typography variant="body1" sx={{ ml: 1 }}>
                        { option.name }
                    </Typography>
                </ListItem>
            }}
            {...props}
        ></Autocomplete>
    );
}

export default AlpacaTemplateSearch;