import { Link as RouterLink } from 'react-router-dom';
import {
  Box,
  Card,
  Link,
  Tooltip,
  Typography,
  Container,
  Alert,
  styled,
  useTheme,
  Divider,
  Badge
} from '@mui/material';
import { Helmet } from 'react-helmet-async';
import useAuth from 'src/hooks/useAuth';
import Auth0Login from '../LoginAuth0';
import FirebaseAuthLogin from '../LoginFirebaseAuth';
import JWTLogin from '../LoginJWT';
import AmplifyLogin from '../LoginAmplify';

import { useTranslation } from 'react-i18next';
import Logo from 'src/components/LogoSign';
import Scrollbar from 'src/components/Scrollbar';
import useMousePosition from 'src/hooks/useMousePosition';

const icons = {
  Auth0: '/static/images/logo/auth0.svg',
  FirebaseAuth: '/static/images/logo/firebase.svg',
  JWT: '/static/images/logo/jwt.svg',
  Amplify: '/static/images/logo/amplify.svg',
  Illustration: '/static/images/illustrations/together.svg'
};

const Content = styled(Box)(
  ({ theme }) => `
    display: flex;
    flex: 1;
    width: 100%;
    background: ${theme.colors.alpha.white[100]};

`
);

const MainContent = styled(Box)(
  ({ theme }) => `
  @media (min-width: ${theme.breakpoints.values.md}px) {
  }
  padding-left: 5px;
  padding-right: 20px;
  display: flex;
  align-items: center;
`
);

const SidebarWrapper = styled(Box)(
  ({ theme }) => `
    position: fixed;
    left: 0;
    top: 0;
    height: 100%;
    background: ${theme.colors.alpha.white[100]};
    width: 440px;
`
);

const SidebarContent = styled(Box)(
  ({ theme }) => `
  display: flex;
  flex-direction: column;
  padding: ${theme.spacing(6)};
`
);

const CardImg = styled(Card)(
  ({ theme }) => `
    display: inline-flex;
    align-items: center;
    justify-content: center;
    position: relative;
    border: 1px solid ${theme.colors.alpha.black[10]};
    transition: ${theme.transitions.create(['border'])};
    position: absolute;

    &:hover {
      border-color: ${theme.colors.primary.main};
    }
`
);

const TypographyH1 = styled(Typography)(
  ({ theme }) => `
    font-size: ${theme.typography.pxToRem(33)};
`
);

const AnimatedBackground = styled(Box)({
})

function LoginCover() {
  const { method } = useAuth() as any;
  const { t }: { t: any } = useTranslation();
  const theme = useTheme();

  const mousePosition = useMousePosition();
  
  return (
    <>
      <Helmet>
        <title>Login</title>
      </Helmet>
      <Content>
        <AnimatedBackground sx={{ 
            m: 2, 
            width: '66%', 
            backgroundImage: `url('/static/images/illustrations/AlpacaBgSVG.svg')`, 
            //backgroundSize: `${((mousePosition.x/window.screen.width+mousePosition.y/window.screen.height)*100)+1200}px`,
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat',
            display: 'flex', 
            //backgroundPosition: `${(mousePosition.x / 20) - 100}px ${(mousePosition.y / 20) - 40}px`,
            transition: 'background-position .8s ease-out',
            p: 4, 
            borderRadius: '30px'
        }}>
            <Typography variant="h1" sx={{ mt: 'auto', color: theme.colors.alpha.trueWhite[100], mr: '30%', fontSize: '48px' }}>The most intuitive notification system for schools ever created.</Typography>
        </AnimatedBackground>
        <MainContent>
          <Container
            sx={{
              display: 'flex',
              alignItems: 'center',
              flexDirection: 'column',
              justifyContent: 'space-between',
              height: '100%',
              pt: 6,
              pb: 6
            }}
            maxWidth="xs"
          >
            <Box sx={{ display: 'flex', alignItems: "center", ml: '-50px'}}>
                <img width="48" src="static/images/logo/alpaca-logo-no-text.svg" alt="Alpaca Logo">
                </img>
                <Badge badgeContent="Beta" color="success" sx={{ '& .MuiBadge-badge': { transform: 'translateX(45px) translateY(5px)' } }}>
                <Typography variant="h2" sx={{ paddingLeft: 2, fontFamily: 'all-round-gothic'}}>
                    Alpaca Notify
                </Typography>
                </Badge>
            </Box>
              <JWTLogin/>
              <Box sx={{ mt: 3 }}>
                <Typography variant="h5">
                    Easily dispatch and schedule notifications for your school or organization with Alpaca Notify.
                </Typography>

                <Typography variant="subtitle1" sx={{ mt: 1, lineHeight: 1.5 }}>
                    We also hate sales folks, here's a cool way to demo our product <a href="#">Schedule a demo</a> 
                </Typography>

                <Typography variant="subtitle1" textAlign="center" sx={{ mt: 2 }}>
                    Alpaca Notify Beta v1.0
                </Typography>
            </Box>
          
          </Container>
        </MainContent>
      </Content>
    </>
  );
}

export default LoginCover;
