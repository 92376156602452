import { Container, Grid, Typography } from '@mui/material';

import { useState, useRef } from 'react';
import { useMutation } from '@tanstack/react-query';

import useAuth from 'src/hooks/useAuth';

import scheduleAPI from 'src/controllers/schedule';
import PageTitleWrapper from '../PageTitleWrapper';
import AlpacaCalendar from '../organisms/AlpacaCalendar/AlpacaCalendar';
import AlpacaParse from 'src/utils/schedulingParser';

export default function AlpacaCalendarPage() {
    const acc = useAuth();

    const [detailsModalOpen, setDetailsModalOpen] = useState(false);
    const [detailsModalInfo, setDetailsModalInfo] = useState(null);
    const [scheduleModalOpen, setScheduleModalOpen] = useState(false);
    const [scheduleModalContent, setScheduleModalContent] = useState(null);
    const calendarRef = useRef(null);

    const [filter, setFilter] = useState('');

    const fetchEvents = async (info) => {
        const furledEvents = await scheduleAPI.getUpcoming(
            acc.user.domain,
            new Date(info.start).toISOString(),
            new Date(info.end).toISOString()
        );

        const events =  AlpacaParse.unfurlEvents(furledEvents, info.start, info.end);

        return events;
    };

    const deleteEvent = useMutation((eventID) => {
        return scheduleAPI.deleteScheduledNotification(
            acc.user.domain,
            eventID
        );
    });

    return (
        <>
            <PageTitleWrapper>
                <Grid
                    container
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                >
                    <Grid display="flex" item>
                        <Typography
                            sx={{
                                marginRight: '10px',
                                mb: 0,
                                lineHeight: '50px',
                                fontSize: '24px'
                            }}
                            variant="h3"
                            component="h3"
                            gutterBottom
                        >
                            Calendar
                        </Typography>
                        <Typography
                            sx={{
                                marginRight: '10px',
                                fontSize: '34px',
                                lineHeight: '50px',
                                mb: 0
                            }}
                            variant="h3"
                            component="h3"
                            gutterBottom
                        >
                            &#9702;
                        </Typography>
                        <Typography sx={{ lineHeight: '50px' }} variant="h6">
                            View and manage your scheduled notifications
                        </Typography>
                    </Grid>
                </Grid>
            </PageTitleWrapper>
            <Container maxWidth="xl">
                <AlpacaCalendar
                    filter={filter}
                    setFilter={setFilter}
                    detailsModalOpen={detailsModalOpen}
                    setDetailsModalOpen={setDetailsModalOpen}
                    setScheduleModalOpen={setScheduleModalOpen}
                    setDetailsModalInfo={setDetailsModalInfo}
                    setScheduleModalContent={setScheduleModalContent}
                    detailsModalInfo={detailsModalInfo}
                    scheduleModalOpen={scheduleModalOpen}
                    scheduleModalContent={scheduleModalContent}
                    fetchEvents={fetchEvents}
                    calendarRef={calendarRef}
                    deleteEvent={deleteEvent}
                 />
            </Container>
        </>
    );
}
