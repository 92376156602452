import { useState, useEffect } from 'react';

import {
    Card,
    CardContent,
    Typography,
    Stack,
    Container,
    Button, styled,
    Grid,
    IconButton,
    useTheme,
    TextField as MuiTextField,
    MenuItem, Tooltip,
    Collapse,
    SvgIcon
} from '@mui/material';

import { Formik, Form, ErrorMessage, useFormikContext } from 'formik';
import { Field } from 'formik';

import { TextField, Select } from 'formik-mui';
import { DatePicker, TimePicker } from 'formik-mui-lab';

import format from 'date-fns/format';

import {
    DescriptionTwoTone,
    Close,
    RepeatTwoTone,
    PaletteTwoTone,
    VisibilityTwoTone,
    InfoTwoTone
} from '@mui/icons-material';

import { ExpandMore } from '@mui/icons-material';

import googleAPI from '../../../controllers/googleFunctions';
import scheduleAPI from 'src/controllers/schedule';
import templateAPI from 'src/controllers/templates';
import AlpacaParse from 'src/utils/schedulingParser';

import useAuth from 'src/hooks/useAuth';

import CollapsibleHeader from 'src/components/atoms/CollapsibleHeader/CollapsibleHeader';
import AlpacaStyleEditor from 'src/components/molecules/AlpacaStyleEditor/AlpacaStyleEditor';
import AlpacaRecipientsPane from 'src/components/molecules/AlpacaRecipientsPane/AlpacaRecipientsPane';
import { NOTIF_CONSTRAINTS } from 'src/constants/notificationEnums';
import StyledAlpacaCheckbox from 'src/components/atoms/AlpacaCheckbox/AlpacaCheckbox';
import AlpacaNotifStyleSelectFormik from 'src/components/molecules/AlpacaNotifStyleSelectFormik/AlpacaNotifStyleSelectFormik';

const { 
    generateDailyRecurrenceCron, 
    generateWeeklyRecurrenceCron, 
    generateMonthlyRecurrenceCron, 
    generateYearlyRecurrenceCron, 
    calculateDailyEndDate, 
    calculateMonthlyEndDate, 
    calculateWeeklyEndDate,
    calculateYearlyEndDate,
} = AlpacaParse;

const StyledTextField = styled(MuiTextField)({
  '& .MuiInputBase-root': {
    height: '100%'
  }
});

function onKeyDown(keyEvent) {
    if ((keyEvent.charCode || keyEvent.keyCode) === 13) {
        keyEvent.preventDefault();
    }
}

interface initialValues {
    time: number,
    date: Date,
    notifStyle: 'banner' | 'fullscreenPopup' | 'fullscreen' | 'popup',
    subtitle: string,
    title: string,
    body: string,
    repeat: 'daily' | 'weekly' | 'monthly' | 'yearly' | 'none'
    ends: string,
    monday: boolean,
    tuesday: boolean,
    wednesday: boolean,
    thursday: boolean,
    friday: boolean,
    saturday: boolean,
    sunday: boolean,
    endsAfter: string,
    endsDate: Date,
    endsNumber: number,
    every: number,
    onDayOfMonth: number,
    onWeek: 1,
    onDateOrDaySwitch: 'onDateRadio' | 'onDayOfWeekRadio',
    dayOfWeek: number,
    notifLifetime: number,
    requireAcknowledge: boolean,
    backgroundColor: string,
    icon: string,
    monthOfYearSelect: number,
}

const CustomMUIFormLabel = styled('div')(({ theme }) => ({
    position: 'absolute', backgroundColor: 'white', marginTop: '-16px', marginLeft: '-9px',
    paddingLeft: '4px',
    paddingRight: '4px',
    fontSize: '11px',
    fontFamily: 'Inter',
    fontWeight: 400,
    color: theme.colors.alpha.black[70],
}));

export default function AlpacaCalendarScheduleModal({
  setScheduleModalOpen,
  scheduleModalContent,
  setCancelNotificationModalOpen,
  anyFieldTouched,
  setAnyFieldTouched,
}) {
    
    const theme = useTheme();
    const acc = useAuth();

    const [ targets, setTargets ] = useState(scheduleModalContent.targets);
    const [ recurrenceCollapseOpen, setRecurrenceCollapseOpen ] = useState(false);
    const [ editorSectionOpen, setEditorSectionOpen ] = useState('content');
    
    const [ targetSearchValue, setTargetSearchValue ] = useState(null);
    const [ searchResults, setSearchResults ] = useState([]);

    useEffect(() => {
        googleAPI.searchLocalTargets('')
            .then((res) => { setSearchResults(res.groups.concat(res.members))})
    }, [])

    function validate(values) {
        const errors: any = {};

        switch (values.repeat) {
            case "daily":
                if (values.endsAfter === "date") {
                    if (!values.endsDate) {
                        errors.endsDate = "You must specify an end date"
                    }
                }
                break;

            case "weekly":
                if (values.endsAfter === 'date' && !values.endsDate) {
                    errors.endsDate = "You forgot to select an end date."
                }
                console.log(values)
                const weekExists = (values.monday || values.tuesday || values.wednesday || values.thursday || values.friday || values.saturday || values.sunday);
    
                if (!weekExists) {
                    errors.monday = "No days of the week were selected."
                }
        }

        if (!values.body) { errors.body = "You must specify a notification body" }
        if (!values.title) { errors.title = "You must specify a notification title" }
        if (!values.subtitle) { errors.subtitle = "You must specify a notification subtitle" }

        if (values.notifStyle === "popup" && values.title.length > NOTIF_CONSTRAINTS.POPUP.TITLE) {
            errors.title = `The maximum length for a title on the pop-up notification style is ${NOTIF_CONSTRAINTS.POPUP.TITLE} characters.`
        }
    
        if (values.notifStyle === "popup" && values.subtitle.length > NOTIF_CONSTRAINTS.POPUP.SUBTITLE) {
            errors.subtitle = `The maximum length for a title on the pop-up notification style is ${NOTIF_CONSTRAINTS.POPUP.SUBTITLE} characters.`
        }

        const scheduledDate = new Date(values.time);
        scheduledDate.setDate(values.date.getDate());   
        scheduledDate.setMonth(values.date.getMonth());
        scheduledDate.setFullYear(values.date.getFullYear());
        scheduledDate.setSeconds(0, 0)

        const today = new Date();
        const todayString = today.toISOString().substring(0, 10);
        const scheduledString = scheduledDate.toISOString().substring(0, 10);
        if (todayString === scheduledString && scheduledDate < new Date()) { errors.time = "You must specify a time in the future"}
        if (values.date < new Date() && todayString !== scheduledString ) { errors.date = "You must specify a date in the future" }
        return errors;
    }

    const UpdateAnyTouched = () => {
        // Grab values and submitForm from context
        const { touched } = useFormikContext();
        useEffect(() => {
          // Submit the form imperatively as an effect as soon as form values.token are 6 digits long
          const fieldArray = Object.keys(touched);
          const isAnyFieldTouched = fieldArray.filter((field) => { return touched[field] === true }).length > 0;
            setAnyFieldTouched(isAnyFieldTouched)
        }, [touched]);
        return null;
    };

    return (
        <Container>
        <Card
            sx={{
            minHeight: { md: '400px' },
            padding: 1
            }}
        >
            <CardContent>
            <Stack direction="row" justifyContent="space-between"></Stack>
            <Grid container direction="column">
                <Formik
                validate={validate}
                initialValues={{
                    time: new Date().getTime(),
                    date: scheduleModalContent.date,
                    notifStyle: scheduleModalContent.notifStyle,
                    subtitle: scheduleModalContent.subtitle,
                    title: scheduleModalContent.title,
                    body: scheduleModalContent.body,
                    repeat: 'none',
                    ends: "01/23/2000",
                    monday: false,
                    tuesday: false,
                    wednesday: false,
                    thursday: false,
                    friday: false,
                    saturday: false,
                    sunday: false,
                    endsAfter: 'date',
                    endsDate: '',
                    endsNumber: 1,
                    every: 1,
                    onDayOfMonth: new Date().getDate(),
                    onWeek: 1,
                    onDateOrDaySwitch: 'onDateRadio',
                    dayOfWeek: 0,
                    monthOfYearSelect: new Date().getMonth() + 1,
                    notifLifetime: 30,
                    requireAcknowledge: false,
                    icon: (scheduleModalContent.stylingInfo) ? scheduleModalContent.stylingInfo.icon:'timer',
                    backgroundColor: (scheduleModalContent.stylingInfo) ? scheduleModalContent.stylingInfo.backgroundColor:'ffffff',
                }}
                onSubmit={(values: initialValues, { setSubmitting }) => {
                    const scheduledDate = new Date(values.time);
                    scheduledDate.setDate(values.date.getDate());   
                    scheduledDate.setMonth(values.date.getMonth());
                    scheduledDate.setFullYear(values.date.getFullYear());
                    scheduledDate.setSeconds(0, 0)
                    if (values.repeat === 'weekly') {
                        let endDate;
                        let cronString = generateWeeklyRecurrenceCron(
                            values,
                            scheduledDate
                        );
                        
                        endDate = calculateWeeklyEndDate(values.endsAfter, values.endsNumber, values.endsDate, cronString, scheduledDate)
                        if (values.endsAfter === "never") {
                            endDate = null;
                        } else {
                            endDate = endDate.toISOString();
                        }

                        scheduleAPI.scheduleRecurringNotification(
                            acc.user.domain,
                            {
                                targets,
                                content: {
                                    title: values.title,
                                    subtitle: values.subtitle,
                                    body: values.body,
                                }, 
                                date: scheduledDate.toISOString(),
                                notifStyle: values.notifStyle,
                                name: scheduleModalContent.name,
                                cronString,
                                skipDays: 0,
                                endDate: endDate,
                                lifetime: values.notifLifetime,
                                stylingInfo: {
                                    icon: values.icon,
                                    backgroundColor: values.backgroundColor
                                },
                                formData: {
                                    repeat: values.repeat,
                                    every: values.every,
                                    endsAfter: values.endsAfter,
                                    endsDate: values.endsDate,
                                    endsNumber: values.endsNumber,
                                    monday: values.monday,
                                    tuesday: values.tuesday,
                                    wednesday: values.wednesday,
                                    thursday: values.thursday,
                                    friday: values.friday,
                                    saturday: values.saturday,
                                    sunday: values.sunday,
                                    scheduledDate: scheduledDate.toISOString(),
                                },
                            }
                        )
                        .then((res) => { setSubmitting(false); setScheduleModalOpen(false); })
                    } else if (values.repeat === 'daily') {
                        let endDate;
                        let cronString = generateDailyRecurrenceCron(values, scheduledDate);
                        console.log(cronString)

                        endDate = calculateDailyEndDate(values.endsAfter, scheduledDate, values.endsDate, values.every, cronString, values.endsNumber)
                        if (values.endsAfter === "never") {
                            endDate = null;
                        } else {
                            endDate = endDate.toISOString();
                        }
                        
                        scheduleAPI.scheduleRecurringNotification(
                            acc.user.domain,
                            {
                                targets,
                                content: {
                                    title: values.title,
                                    subtitle: values.subtitle,
                                    body: values.body,
                                }, 
                                date: scheduledDate.toISOString(),
                                notifStyle: values.notifStyle,
                                name: scheduleModalContent.name,
                                cronString: cronString,
                                skipDays: values.every,
                                endDate: endDate,
                                lifetime: values.notifLifetime,
                                stylingInfo: {
                                    icon: values.icon,
                                    backgroundColor: values.backgroundColor
                                },
                                formData: {
                                    repeat: values.repeat,
                                    every: values.every,
                                    endsAfter: values.endsAfter,
                                    endsDate: values.endsDate,
                                    endsNumber: values.endsNumber,
                                    scheduledDate: scheduledDate.toISOString(),
                                }
                            }
                        )
                        .then((res) => { setSubmitting(false); setScheduleModalOpen(false);  })
                    } else if (values.repeat === 'monthly') {
                        let endDate;
                        const cronString = generateMonthlyRecurrenceCron(
                            values,
                            scheduledDate
                        );

                        endDate = calculateMonthlyEndDate(values.endsAfter, scheduledDate, values.endsDate, values.endsNumber, cronString)
                        scheduleAPI.scheduleRecurringNotification(
                            acc.user.domain,
                            {
                                targets,
                                content: {
                                    title: values.title,
                                    subtitle: values.subtitle,
                                    body: values.body,
                                }, 
                                date: scheduledDate.toISOString(),
                                notifStyle: values.notifStyle,
                                name: scheduleModalContent.name,
                                cronString: cronString,
                                skipDays: 0,
                                endDate: endDate.toISOString(),
                                lifetime: values.notifLifetime,
                                stylingInfo: {
                                    icon: values.icon,
                                    backgroundColor: values.backgroundColor
                                },
                                formData:
                                {
                                    repeat: values.repeat,
                                    onDayOfMonth: values.onDayOfMonth,
                                    every: values.every,
                                    endsAfter: values.endsAfter,
                                    endsDate: values.endsDate,
                                    endsNumber: values.endsNumber,
                                    scheduledDate: scheduledDate.toISOString(),
                                }
                            }
                        )
                        .then((res) => { setSubmitting(false); setScheduleModalOpen(false);  })
                    } else if (values.repeat === 'yearly') {
                        const cronString = generateYearlyRecurrenceCron(
                            values,
                            scheduledDate,
                        );
                        
                        scheduleAPI.scheduleRecurringNotification(
                            acc.user.domain,
                            {
                                targets,
                                content: {
                                    title: values.title,
                                    subtitle: values.subtitle,
                                    body: values.body,
                                },
                                date: scheduledDate.toISOString(),
                                notifStyle: values.notifStyle,
                                name: scheduleModalContent.name,
                                cronString,
                                skipDays: 0,
                                endDate: calculateYearlyEndDate(values.endsAfter, scheduledDate, values.endsDate, values.every, cronString, values.endsNumber),
                                lifetime: values.notifLifetime,
                                stylingInfo: {
                                    icon: values.icon,
                                    backgroundColor: values.backgroundColor
                                },
                                formData: {
                                    repeat: values.repeat,
                                    monthOfYearSelect: values.monthOfYearSelect,
                                    onDayOfMonth: values.onDayOfMonth,
                                    endsAfter: values.endsAfter,
                                    endsDate: values.endsDate,
                                    endsNumber: values.endsNumber,
                                    scheduledDate: scheduledDate.toISOString(),
                                }

                            }
                        )
                        .then((res) => { setSubmitting(false); setScheduleModalOpen(false); })
                    } else if (values.repeat === 'none') {
                        scheduleAPI.scheduleNotification(
                            acc.user.domain, 
                            targets,
                            { title: values.title, subtitle: values.subtitle, body: values.body }, 
                            scheduledDate.toISOString(), 
                            scheduleModalContent.name,
                            values.notifStyle, 
                            {
                                backgroundColor: values.backgroundColor,
                                icon: values.icon,
                            },
                            values.notifLifetime,
                        )
                            .then((res) => { setSubmitting(false); setScheduleModalOpen(false); })
                    }
                }}
                >
                {({ isSubmitting, setFieldValue, values, submitForm, errors }) => (
                    <Form
                    onKeyDown={onKeyDown}
                >   
                    <UpdateAnyTouched/>
                        <Grid container item xs={12} spacing={2}>
                            <Grid
                                container
                                item
                                xs={12}
                                direction="row"
                                justifyContent="space-between"
                            >

                                <Grid container item xs={8} justifyContent="space-between">
                                    <Grid display="flex" item>
                                        <IconButton onClick={() => { 
                                            if (anyFieldTouched) {
                                                setCancelNotificationModalOpen(true);
                                            } else {
                                                setScheduleModalOpen(false);
                                            }
                                        }}>
                                            <Close></Close>
                                        </IconButton>
                                        <Typography variant="h3" sx={{ lineHeight: "1.75em", ml: 1.5 }}>Creating scheduled notification</Typography>
                                    </Grid>
                                    <Grid item display="flex" alignItems="center" sx={{ mr: 1 }}>
                                        <Tooltip
                                            title={`This is the name of the template you selected when creating this notification`}
                                        >
                                        <SvgIcon color="primary" fontSize='small' sx={{ mr: '.25em' }}>
                                            <InfoTwoTone></InfoTwoTone>
                                        </SvgIcon>
                                        </Tooltip>
                                        <Typography color="primary" variant="body1">{ scheduleModalContent.name } </Typography>
                                    </Grid>
                                </Grid>
                                <Grid container item xs={4} display="flex" justifyContent="flex-end" spacing={1} paddingLeft="11px">
                                    <Grid xs={6} item>
                                        <Button fullWidth variant="outlined" color="error" sx={{ mr: 1 }} onClick={() => setCancelNotificationModalOpen(true)}>
                                            Delete
                                        </Button>
                                    </Grid>
                                    <Grid xs={6} item>
                                        <Button fullWidth variant="contained" color="primary" type="submit" >
                                            Save
                                        </Button>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid
                                item
                                container
                                xs={8}
                                spacing={2}
                                justifyContent="flex-start"
                                alignContent="flex-start"
                            >
                                    <Grid container item direction="row" xs={12} spacing={1} justifyContent="space-between" >
                                        <Grid container item spacing={1} xs={6} justifyContent="flex-end" sx={{ transform: 'translateY(1px)' }}>
                                            <Grid xs={6} item sx={{ maxHeight: '49px' }}>
                                                <Field
                                                    component={DatePicker}
                                                    label="Date"
                                                    name="date"
                                                    minDate={new Date()}
                                                    OpenPickerButtonProps={{ color: "primary" }}
                                                    inputFormat="MM/dd/yyyy"
                                                    renderInput={(params) => (
                                                    <StyledTextField
                                                        size="small"
                                                        sx={{ height: '100% !important' }}
                                                        {...params}
                                                        error={(errors.date !== undefined)}
                                                        label={(values.repeat === 'none') ? 'Delivery Date':'Recurrence Pattern Start Date'}
                                                    />
                                                    )}
                                                />
                                            </Grid>
                                            <Grid xs={6} item sx={{ maxHeight: '49px' }} >
                                                <Field
                                                    component={TimePicker}
                                                    OpenPickerButtonProps={{ color: "primary" }}
                                                    label="Time"
                                                    name="time"
                                                    type="time"
                                                    minDate={new Date()}
                                                    error={ (errors.time) }
                                                    renderInput={(params) => (
                                                    <StyledTextField    
                                                        size="small"
                                                        sx={{ height: '100% !important' }}
                                                        {...params}
                                                        error={(errors.time !== undefined)}
                                                    />
                                                    )}
                                                />
                                            </Grid>
                                            <Grid item xs={12} sx={ (errors.time === undefined && errors.date === undefined) && { padding: '0 !important', height: '0 !important' }}>
                                                <ErrorMessage name="date" render={(msg) => {return <div style={{ marginBottom: (errors.date !== undefined ) ? '':'1rem', color: theme.colors.error['main'] }}>{msg}</div>}}></ErrorMessage>
                                                <ErrorMessage name="time" render={(msg) => {return <div style={{ marginBottom: (errors.time !== undefined ) ? '':'1rem', color: theme.colors.error['main'] }}>{msg}</div>}}></ErrorMessage>
                                            </Grid>
                                        </Grid>
                                        <Grid item xs={6} display='flex' justifyContent="flex-end">
                                            <Button 
                                                variant="outlined" 
                                                size="small"
                                                startIcon={<RepeatTwoTone></RepeatTwoTone>}
                                                sx={(recurrenceCollapseOpen) ? 
                                                    { 
                                                        '&:hover': {
                                                            borderBottomColor: `${theme.palette.background.paper}`,
                                                            backgroundColor: `${theme.palette.background.paper}`,
                                                        },
                                                        borderColor: theme.colors.alpha.black[30],
                                                        paddingBottom: '26px',
                                                        paddingTop: '12px',
                                                        borderBottomColor: `${theme.palette.background.paper}`,
                                                        borderBottomLeftRadius: 0,
                                                        borderBottomRightRadius: 0,
                                                        transform: 'translateY(1px)',
                                                        zIndex: 3,
                                                    }:
                                                    {
                                                        borderColor: theme.colors.alpha.black[30],
                                                        height: '41px',
                                                        transform: 'translateY(1px)',
                                                        zIndex: 3,
                                                    }
                                                }
                                                endIcon={
                                                    <ExpandMore 
                                                        sx={{
                                                            transition: theme.transitions.create('transform', {
                                                                duration: theme.transitions.duration.shortest,
                                                            }),
                                                            transform: !recurrenceCollapseOpen ? 'rotate(0deg)' : 'rotate(180deg)',
                                                        }}
                                                    >
                                                    </ExpandMore>
                                                } 
                                                onClick={ ()=> setRecurrenceCollapseOpen(!recurrenceCollapseOpen) }
                                            >
                                                Recurrence Menu
                                            </Button>
                                        </Grid>
                                    </Grid>
                                    <Grid container item xs={12} sx={{paddingTop: '0 !important' }}>
                                        <Collapse in={recurrenceCollapseOpen} sx={{ transform: 'translateY(-10px)', width: '100%'}}>
                                        <Card variant="outlined" sx={{ borderTopRightRadius: (recurrenceCollapseOpen) ? 0:'', boxShadow: 0, p: 2, borderColor: theme.colors.alpha.black[30], transform: 'translateY(10px)' }}>
                                        <Grid container item spacing={1} direction="row" sx={{ display: 'flex', alignItems: 'flex-start', justifyContent: "flex-start" }} xs={12}>
                                            <Grid item xs={2} sx={{ mr: 1 }} >
                                                <Field
                                                    component={Select}
                                                    id="repeat"
                                                    name="repeat"
                                                    labelId="repeat-simple"
                                                    label="Repeat"
                                                    formControl={{
                                                        fullWidth: true,
                                                    }}
                                                    >
                                                    <MenuItem value="none">None</MenuItem>
                                                    <MenuItem value="daily">Daily</MenuItem>
                                                    <MenuItem value="weekly">Weekly</MenuItem>
                                                    <MenuItem value="monthly">Monthly</MenuItem>
                                                    <MenuItem value="yearly">Yearly</MenuItem>
                                                </Field>
                                            </Grid>
                                            { values.repeat === 'daily' && 
                                            <Grid container item xs={9} spacing={1} sx={{ display: 'flex', alignItems: 'center'}}>
                                                <Grid item>
                                                    <img alt="" src={'/static/images/illustrations/Arrow 1.svg'}></img>
                                                </Grid>
                                                <Grid item xs={2.125}>
                                                    <Field
                                                        component={TextField}
                                                        id="every"
                                                        name="every"
                                                        label="Every (days)"
                                                        fullWidth
                                                        InputProps={{ type: 'number', inputMode: 'numeric', pattern: 'd*', inputProps: { min: 1, max: 10 } }}
                                                    />
                                                </Grid>
                                                <Grid item>
                                                    <img alt="" src={'/static/images/illustrations/Arrow 1.svg'}></img>
                                                </Grid>
                                                <Grid item>
                                                    <Field
                                                        component={Select}
                                                        id="endsAfter"
                                                        name="endsAfter"
                                                        labelId="endsAfter-simple"
                                                        label="Ends after"
                                                        formControl={{
                                                            fullWidth: true,
                                                        }}
                                                        >
                                                        <MenuItem value="never">Never</MenuItem>
                                                        <MenuItem value="date">Chosen date</MenuItem>
                                                        <MenuItem value="occurrences">Occurences</MenuItem>
                                                    </Field>
                                                </Grid>
                                                <Grid item>
                                                    { values.endsAfter !== 'never' &&
                                                        <img alt="" src={'/static/images/illustrations/Arrow 1.svg'}></img>
                                                    }
                                                </Grid>
                                                <Grid item xs={3}>
                                                    { values.endsAfter === "date" && 
                                                        <Field
                                                            component={TextField}
                                                            id="endsDate"
                                                            name="endsDate"
                                                            label="End date"
                                                            InputProps={{ type: 'date', 
                                                                inputProps: { min: format(new Date(), 'yyyy-MM-dd') }
                                                            }}
                                                            InputLabelProps={{
                                                                shrink: true,
                                                            }}
                                                        />
                                                    } 
                                                    { values.endsAfter === "occurrences" &&
                                                        <Field
                                                            component={TextField}
                                                            id="endsNumber" 
                                                            name="endsNumber"
                                                            label="Occurences"
                                                            InputProps={{ type: 'number', inputMode: 'numeric', pattern: 'd*', inputProps: { min: 1, max: 100 }}}
                                                        />
                                                    }
                                                </Grid>
                                            </Grid>
                                            }
                                            { values.repeat === 'weekly' && 
                                            <Grid container item xs={9}>
                                                <Grid container item xs={12} wrap='nowrap' spacing={1} sx={{ display: 'flex', alignItems: 'flex-start', maxHeight: '80px', }}>
                                                    <Grid item sx={{ display: 'flex', alignItems: 'center'}}>
                                                        <img alt="" src={'/static/images/illustrations/Arrow 2.svg'} style={ { position: 'relative', top: '24px' } }></img>
                                                    </Grid>
                                                    <Grid item>
                                                        <Field
                                                            component={Select}
                                                            id="endsAfter"
                                                            name="endsAfter"
                                                            labelId="endsAfter-simple"
                                                            label="Ends after"
                                                            formControl={{
                                                                fullWidth: true,
                                                            }}
                                                            >
                                                            <MenuItem value="never">Never</MenuItem>
                                                            <MenuItem value="date">Chosen date</MenuItem>
                                                            <MenuItem value="occurrences">Occurences</MenuItem>
                                                        </Field>
                                                    </Grid>
                                                    { values.endsAfter !== 'never' &&
                                                    <>
                                                    <Grid item sx={{ display: 'flex', alignItems: 'center', position: 'relative', top: '24px'}}>
                                                        <img alt="" src={'/static/images/illustrations/Arrow 1.svg'}></img>
                                                    </Grid>
                                                    <Grid item xs={3}>
                                                        { (values.endsAfter === 'date') ? 
                                                            <Field
                                                                component={TextField}
                                                                id="endsDate"
                                                                name="endsDate"
                                                                label="Ends on"
                                                                InputProps={{ type: 'date', 
                                                                    inputProps: { min: format(new Date(), 'yyyy-MM-dd') }
                                                                }}
                                                                InputLabelProps={{
                                                                    shrink: true,
                                                                }}
                                                            />
                                                            :
                                                            <Field
                                                                component={TextField}
                                                                id="endsNumber" 
                                                                name="endsNumber"
                                                                label="Occurences"
                                                                InputProps={{ type: 'number', inputMode: 'numeric', pattern: 'd*', inputProps: { min: 1, max: 100 } }}
                                                            />
                                                        }
                                                    </Grid>
                                                    </>
                                                    }
                                                </Grid>
                                                <Grid container item xs={12} sx={{ display: 'flex', alignItems: 'center', marginLeft: '30px' }}>
                                                    <Card variant="outlined" sx={{ boxShadow: 0, ml: 1, borderColor: theme.colors.alpha.black[30], }}>
                                                        <Field component={StyledAlpacaCheckbox} type="checkbox" name="sunday" day="sunday" />
                                                        <Field component={StyledAlpacaCheckbox} type="checkbox" name="monday" day="monday"/>
                                                        <Field component={StyledAlpacaCheckbox} type="checkbox" name="tuesday" day="tuesday" />
                                                        <Field component={StyledAlpacaCheckbox} type="checkbox" name="wednesday" day="wednesday" />
                                                        <Field component={StyledAlpacaCheckbox} type="checkbox" name="thursday" day="thursday" />
                                                        <Field component={StyledAlpacaCheckbox} type="checkbox" name="friday" day="friday" />
                                                        <Field component={StyledAlpacaCheckbox} type="checkbox" name="saturday" day="saturday" />
                                                        <ErrorMessage name="monday" render={(msg) => { return <div style={{ paddingLeft: '8px', paddingBottom: '8px', color: theme.colors.error['main']}}>{msg}</div>}}></ErrorMessage>
                                                    </Card>
                                                </Grid>
                                            </Grid>
                                            }
                                            { values.repeat === 'monthly' &&
                                            <Grid container item xs={9}>
                                                <Grid container item xs={12} wrap='nowrap' spacing={1} sx={{ display: 'flex', alignItems: 'flex-start', maxHeight: '62px', }}>
                                                    <Grid item sx={{ display: 'flex', alignItems: 'center'}}>
                                                        <img alt="" src={'/static/images/illustrations/Arrow 2.svg'} style={ { position: 'relative', top: '24px' } }></img>
                                                    </Grid>
                                                    <Grid item xs={3}>
                                                        <Field
                                                            component={TextField}
                                                            id="every"
                                                            name="every"
                                                            label="Every (months)"
                                                            fullWidth
                                                            InputProps={{ type: 'number', inputMode: 'numeric', pattern: 'd*', inputProps: { min: 1, max: 10 } }}
                                                        />
                                                    </Grid>
                                                    <Grid item sx={{ display: 'flex', alignItems: 'center', position: 'relative', top: '24px'}}>
                                                        <img alt="" src={'/static/images/illustrations/Arrow 1.svg'}></img>
                                                    </Grid>
                                                    <Grid item >
                                                        <Field
                                                            component={Select}
                                                            id="endsAfter"
                                                            name="endsAfter"
                                                            labelId="endsAfter-simple"
                                                            label="Ends after"
                                                            formControl={{
                                                                fullWidth: true,
                                                            }}
                                                            >
                                                            <MenuItem value="never">Never</MenuItem>
                                                            <MenuItem value="date">Chosen date</MenuItem>
                                                            <MenuItem value="occurrences">Occurences</MenuItem>
                                                        </Field>
                                                    </Grid>
                                                    { values.endsAfter !== "never" &&
                                                        <>
                                                        <Grid item sx={{ display: 'flex', alignItems: 'center', position: 'relative', top: '24px'}}>
                                                            <img alt="" src={'/static/images/illustrations/Arrow 1.svg'}></img>
                                                        </Grid>
                                                        <Grid item xs={2.5}>
                                                            {(values.endsAfter === 'date') ? 
                                                                <Field
                                                                    component={TextField}
                                                                    id="endsDate"
                                                                    name="endsDate"
                                                                    label="End date"
                                                                    InputProps={{ type: 'date', 
                                                                        inputProps: { min: format(new Date(), 'yyyy-MM-dd') }
                                                                    }}
                                                                    InputLabelProps={{
                                                                        shrink: true,
                                                                    }}
                                                                />
                                                                :
                                                                <Field
                                                                    component={TextField}
                                                                    id="endsNumber" 
                                                                    name="endsNumber"
                                                                    label="Occurences"
                                                                    InputProps={{ type: 'number', inputMode: 'numeric', pattern: 'd*', inputProps: { min: 1, max: 100 } }}
                                                                />
                                                            }
                                                        </Grid>
                                                        </>
                                                    }
                                                </Grid>
                                                <Grid container item xs={12} sx={{ marginLeft: '28px', mt: 2 }}>
                                                    <Field
                                                        sx={{
                                                            ml: 1
                                                        }}
                                                        component={TextField}
                                                        id="onDayOfMonth" 
                                                        name="onDayOfMonth"
                                                        disabled={values.onDateOrDaySwitch === 'onDayOfWeekRadio'}
                                                        label="On (date)"
                                                        InputProps={{ type: 'number', inputMode: 'numeric', pattern: 'd*', inputProps: { min: 1, max: 31 } }}
                                                    />
                                                </Grid>
                                            </Grid>
                                            }
                                            { values.repeat === 'yearly' &&
                                            <Grid container item xs={9}>
                                                <Grid container item xs={12} wrap='nowrap' spacing={1} sx={{ display: 'flex', alignItems: 'flex-start', maxHeight: '62px', }}>
                                                    <Grid item sx={{ display: 'flex', alignItems: 'center'}}>
                                                        <img alt="" src={'/static/images/illustrations/Arrow 2.svg'} style={ { position: 'relative', top: '24px' } }></img>
                                                    </Grid>
                                                    <Grid item xs={3}>
                                                        <Field
                                                            component={Select}
                                                            id="monthOfYearSelect"
                                                            name="monthOfYearSelect"
                                                            labelId="monthOfYearSelect-simple"
                                                            label="Month"
                                                            formControl={{
                                                                fullWidth: true,
                                                            }}
                                                            >
                                                            <MenuItem value="1">January</MenuItem>
                                                            <MenuItem value="2">February</MenuItem>
                                                            <MenuItem value="3">March</MenuItem>
                                                            <MenuItem value="4">April</MenuItem>
                                                            <MenuItem value="5">May</MenuItem>
                                                            <MenuItem value="6">June</MenuItem>
                                                            <MenuItem value="7">July</MenuItem>
                                                            <MenuItem value="8">August</MenuItem>
                                                            <MenuItem value="9">September</MenuItem>
                                                            <MenuItem value="10">October</MenuItem>
                                                            <MenuItem value="11">November</MenuItem>
                                                            <MenuItem value="12">December</MenuItem>
                                                        </Field>
                                                    </Grid>
                                                    <Grid item sx={{ display: 'flex', alignItems: 'center', position: 'relative', top: '24px'}}>
                                                        <img alt="" src={'/static/images/illustrations/Arrow 1.svg'}></img>
                                                    </Grid>
                                                    <Grid item>
                                                        <Field
                                                            component={Select}
                                                            id="endsAfter"
                                                            name="endsAfter"
                                                            labelId="endsAfter-simple"
                                                            label="Ends after"
                                                            formControl={{
                                                                fullWidth: true,
                                                            }}
                                                            >
                                                            <MenuItem value="never">Never</MenuItem>
                                                            <MenuItem value="date">Chosen date</MenuItem>
                                                            <MenuItem value="occurrences">Occurences</MenuItem>
                                                        </Field>
                                                    </Grid>
                                                    {
                                                    values.endsAfter !== 'never' &&
                                                    <>
                                                        <Grid item sx={{ display: 'flex', alignItems: 'center', position: 'relative', top: '24px'}}>
                                                            <img alt="" src={'/static/images/illustrations/Arrow 1.svg'}></img>
                                                        </Grid>
                                                        <Grid item xs={2.5}>
                                                            {(values.endsAfter === 'date') ? 
                                                                <Field
                                                                    component={TextField}
                                                                    id="endsDate"
                                                                    name="endsDate"
                                                                    label="End date"
                                                                    InputProps={{ type: 'date', 
                                                                        inputProps: { min: format(new Date(), 'yyyy-MM-dd') }
                                                                    }}
                                                                    InputLabelProps={{
                                                                        shrink: true,
                                                                    }}
                                                                />
                                                                :
                                                                <Field
                                                                    component={TextField}
                                                                    id="endsNumber" 
                                                                    name="endsNumber"
                                                                    label="Occurences"
                                                                    InputProps={{ type: 'number', inputMode: 'numeric', pattern: 'd*', inputProps: { min: 1, max: 100 } }}
                                                                />
                                                            }
                                                        </Grid>
                                                    </>
                                                    }
                                                </Grid>
                                                <Grid container item xs={12} sx={{ marginLeft: '28px', mt: 2 }}>
                                                    <Field
                                                        sx={{
                                                            ml: 1
                                                        }}
                                                        component={TextField}
                                                        id="onDayOfMonth" 
                                                        name="onDayOfMonth"
                                                        label="On (date)"
                                                        InputProps={{ type: 'number', inputMode: 'numeric', pattern: 'd*', inputProps: { min: 1, max: 31 } }}
                                                    />
                                                </Grid>
                                            </Grid>
                                            }
                                        </Grid>
                                        </Card>
                                    </Collapse>

                                    </Grid>
                                    <Grid container item direction="row" xs={12} spacing={1} justifyContent="space-between">
                                        <Grid xs={6} item>
                                            <AlpacaNotifStyleSelectFormik value={values.notifStyle}></AlpacaNotifStyleSelectFormik>
                                        </Grid>
                                        <Grid xs={3} item>
                                            <Field
                                                component={Select}
                                                id="notifLifetime"
                                                name="notifLifetime"
                                                labelId="notifLifetime-simple"
                                                label="Notif. Lifetime"
                                                formControl={{
                                                    fullWidth: true,
                                                }}
                                                >
                                                <MenuItem value={5}>5 Minutes</MenuItem>
                                                <MenuItem value={15}>15 Minutes</MenuItem>
                                                <MenuItem value={30}>30 Minutes</MenuItem>
                                                <MenuItem value={45}>45 Minutes</MenuItem>
                                                <MenuItem value={60}>60 Minutes</MenuItem>
                                            </Field>
                                        </Grid>
                                    </Grid>
                                    
                                    <Grid container item xs={12} spacing={1}>
                                        <Grid item xs={12} >
                                            <CollapsibleHeader
                                                open={editorSectionOpen === 'content'}
                                                onClick={() => { setEditorSectionOpen((editorSectionOpen === 'content') ? 'none':'content'); }}
                                                icon={
                                                    <DescriptionTwoTone color="primary" fontSize="small"></DescriptionTwoTone>
                                                }
                                                title="Notification Content"
                                            >
                                            </CollapsibleHeader>
                                            <Collapse unmountOnExit in={(editorSectionOpen === 'content')}>
                                                <Grid container item spacing={1} sx={{ mt: 1.5 }}>
                                                    <Grid xs={12} item>
                                                        <Field
                                                            component={TextField}
                                                            label="Title"
                                                            name="title"
                                                            type="title"
                                                            size="small"
                                                            fullWidth
                                                        />
                                                    </Grid>
                                                    <Grid xs={12} item>
                                                        <Field
                                                            component={TextField}
                                                            label="Subtitle"
                                                            name="subtitle"
                                                            type="subtitle"
                                                            size="small"
                                                            fullWidth
                                                        />
                                                    </Grid>
                                                    <Grid xs={12} item>
                                                        <Field
                                                            component={TextField}
                                                            label="Body"
                                                            name="body"
                                                            type="body"
                                                            size="small"
                                                            multiline
                                                            rows={5}
                                                            fullWidth
                                                        />
                                                    </Grid>
                                                </Grid>
                                            </Collapse>
                                        </Grid>
                                        <Grid item xs={12} >
                                            <CollapsibleHeader
                                                open={editorSectionOpen === 'style'}
                                                onClick={() => { setEditorSectionOpen((editorSectionOpen === 'style') ? 'none':'style'); }}
                                                icon={
                                                    <PaletteTwoTone color="primary" fontSize="small"></PaletteTwoTone>
                                                }
                                                title="Notification Style"
                                            >
                                            </CollapsibleHeader>
                                            <Collapse sx={{ mt: 1.5 }} in={(editorSectionOpen === 'style')}>
                                                <Card sx={{ p: 2 }} variant="outlined">
                                                <Grid item>
                                                    <AlpacaStyleEditor
                                                        onChange={ (color) => { setFieldValue('backgroundColor', color); }}
                                                        icon={values.icon}
                                                        onIconChange={ (icon) => { setFieldValue('icon', icon); }}
                                                        backgroundColor={values.backgroundColor}
                                                    >
                                                    </AlpacaStyleEditor>
                                                </Grid>
                                                </Card>
                                            </Collapse>
                                        </Grid>
                                    </Grid>
                                        
                                </Grid>
                                <Grid container item xs={4} alignContent="flex-start" direction="row" spacing={1}>
                                    <Grid item xs={12}>
                                        <Button 
                                            variant="outlined" 
                                            sx={{ borderColor: theme.colors.gradients.purple3 }} 
                                            fullWidth 
                                            size="medium" 
                                            startIcon={<VisibilityTwoTone></VisibilityTwoTone>}
                                            onClick={() => {
                                                templateAPI.provisionPreview(
                                                    acc.user.domain, 
                                                    {
                                                        title: values.title,
                                                        subtitle: values.subtitle,
                                                        body: values.body,
                                                        backgroundColor: values.backgroundColor,
                                                        icon: values.icon,

                                                    },
                                                    values.notifStyle
                                                )
                                                .then((response) => window.open(response.url, '_blank', 'noreferrer'))
                                            }}
                                        >
                                            Preview
                                        </Button>
                                    </Grid>
                                    <Grid item xs={12} sx={{ height: 'calc(100% - 51.5px)', minHeight: '350px', maxHeight: '535px'} }>
                                        <Card
                                            variant="outlined"
                                                sx={{
                                                minWidth: '100%',
                                                display: 'flex',
                                                flexGrow: 1,
                                                flexDirection: 'column',
                                                justifyContent: 'flex-start',
                                                boxShadow: 0,
                                                height: '100%',
                                                p: 1.5,
                                                borderColor: theme.colors.alpha.black[30],
                                            }}
                                        >
                                            <Grid item>
                                                <AlpacaRecipientsPane
                                                    searchResults={searchResults}
                                                    setSearchResults={setSearchResults}
                                                    targetSearchValue={targetSearchValue}
                                                    setTargetSearchValue={setTargetSearchValue}
                                                    targets={targets}
                                                    setTargets={setTargets}
                                                ></AlpacaRecipientsPane>
                                            </Grid>
                                        
                                    </Card>
                                </Grid>
                            </Grid>
                        </Grid>
                </Form>
                )}
                </Formik>
            </Grid>
            </CardContent>
        </Card>
        </Container>
    );
}
