import axios from 'src/utils/axios';
const templateAPI: any = {};

templateAPI.listTemplates = async (domain) => {
  const result = await axios.get(`/templates/list`, {
    params: { domain }
  })
  return result.data;
}

templateAPI.saveTemplate = async (domain, content, notifStyle, name) => {
  const result = await axios.post(`/templates/save`, {
    content,
    style: notifStyle.id,
    name,
    domain
  })
  return result.data;
}

templateAPI.editTemplate = async (domain, templateID, update) => {
    const result = await axios.post('/templates/edit', {
        templateID,
        update
    }, {
        params: { domain }
    })
    return result.data
}

templateAPI.deleteTemplate = async (domain, templateID) => {
    const result = await axios.post('/templates/delete', {
        templateID
    }, {
        params: { domain }
    })
    return result.data
}

templateAPI.createTag = async (domain, name) => {
  const result = await axios.post('/tags/save', {
    name
  }, {
      params: { domain }
  })
  return { data: result.data, code: result.status } 
}

templateAPI.deleteTag = async (domain, tagID) => {
  const result = await axios.post('/tags/delete', {
    tagID
}, {
    params: { domain }
})
return result.data
}

templateAPI.listTags = async (domain) => {
  const result = await axios.get('/tags/list?', {
    params: {domain}
  })
  return result.data
}

templateAPI.provisionPreview = async (domain, content, notifStyle) => {
  const result = await axios.post(`/direct/provision`, {
    content,
    template: notifStyle,
    domain,
    name: `preview from ${new Date().toISOString()}`
  })
  return result.data;
}

export default templateAPI;