import axios from 'src/utils/axios';
const presetsAPI: any = {};

presetsAPI.listPresets = async (domain) => {
    const result = await axios.get(`/presets/list`, {
        params: { domain }
    });
    return result.data;
};

presetsAPI.updatePreset = async (domain, presetID, update) => {
    const result = await axios.post(
        `/presets/edit`,
        {
            presetID,
            update
        },
        {
            params: { domain }
        }
    );
    return result.data;
};

presetsAPI.createPreset = async (domain, templateID, index) => {
    const result = await axios.post(
        `/presets/quick-send/save`,
        {
            templateID,
            index
        },
        {
            params: { domain }
        }
    );
    return result.data;
};

presetsAPI.deletePreset = async (domain, presetID) => {
    const result = await axios.post(
        '/presets/delete',
        {
            presetID
        },
        {
            params: { domain }
        }
    );
    return result.data;
};

presetsAPI.reorderPreset = async (domain, presetID, index) => {
    const result = await axios.post(
        '/presets/reorder',
        {
            presetID,
            index
        },
        {
            params: { domain }
        }
    );
    return result.data;
};

presetsAPI.sendPreset = async (domain, presetID) => {
    const result = await axios.post(
        '/presets/send',
        {
            presetID
        },
        {
            params: { domain }
        }
    );
    return result.data;
};

export default presetsAPI;
