import {
    Autocomplete,
    Avatar,
    Divider,
    IconButton,
    List,
    ListItem,
    ListItemAvatar,
    ListItemText,
    Typography,
    styled,
    useTheme
} from '@mui/material';
import { TextField as MuiTextField } from '@mui/material';
import { useEffect, useState } from 'react';

import { Stack, CircularProgress } from '@mui/material';
import { DeleteTwoTone, Group, Person } from '@mui/icons-material';

import googleAPI from 'src/controllers/googleFunctions';

const StyledTextField = styled(MuiTextField)({
    '& .MuiInputBase-root': {
        height: '100%'
    }
});

const StyledWrapper = styled('div')({
    opacity: '.3'
});

function AlpacaRecipientsPane({
    searchResults,
    setSearchResults,
    targetSearchValue,
    setTargetSearchValue,
    targets,
    setTargets,
    ...otherProps
}) {
    const [autocompleteSearchLoading, setAutocompleteSearchLoading] = useState(false);
    const [searchBarTimeout, setSearchBarTimeout] = useState(null);
    const [searchTerm, setSearchTerm] = useState('');

    const theme = useTheme();

    const { disabled } = otherProps;

    useEffect(() => {
        return () => clearTimeout(searchBarTimeout);
    })

    return (
        <>
            <Autocomplete
                disabled={disabled}
                size="small"
                disableClearable
                getOptionLabel={(option) =>
                    option.fullName ? option.fullName : option.name
                }
                loading={autocompleteSearchLoading}
                loadingText={
                    <Stack direction="row">
                        <CircularProgress
                            color="info"
                            size="20px"
                            sx={{ pl: 0, mr: 1 }}
                        />{' '}
                        Loading...
                    </Stack>
                }
                inputValue={searchTerm}
                renderInput={(params) => (
                    <StyledTextField
                        size="small"
                        label="Search for groups or individuals here..."
                        {...params}
                    ></StyledTextField>
                )}
                options={searchResults}
                renderOption={(params, option) => {
                    return option.fullName ? (
                        <ListItem {...params}>
                            <ListItemAvatar>
                                <Avatar>
                                    <Person></Person>
                                </Avatar>
                            </ListItemAvatar>
                            {option.fullName}
                        </ListItem>
                    ) : (
                        <ListItem {...params}>
                            <ListItemAvatar>
                                <Avatar>
                                    <Group></Group>
                                </Avatar>
                            </ListItemAvatar>
                            {option.name}
                        </ListItem>
                    );
                }}
                value={targetSearchValue}
                onChange={(event, value) => {
                    setTargetSearchValue(null);
                    setTargets(
                        value.fullName
                            ? {
                                  members: targets.members.concat(value),
                                  groups: targets.groups
                              }
                            : {
                                  groups: targets.groups.concat(value),
                                  members: targets.members
                              }
                    );
                    setTimeout(() => setSearchTerm(''), 1);
                }}
                onInputChange={(event, val) => {
                    setSearchTerm(val);
                    clearTimeout(searchBarTimeout);
                    setAutocompleteSearchLoading(true);
                    const newTimer = setTimeout(() => {
                        googleAPI.searchLocalTargets(val).then((res) => {
                            console.log(res)
                            setSearchResults(res.groups.concat(res.members));
                            setAutocompleteSearchLoading(false);
                        });
                    }, 400);
                    setSearchBarTimeout(newTimer);
                }}
                isOptionEqualToValue={(option, value) => {
                    return option._id === value._id;
                }}
                autoComplete={true}
                forcePopupIcon={false}
                defaultValue={null}
                autoHighlight={true}
                noOptionsText={
                    searchTerm.length < 5
                        ? 'Enter more search terms to identify recipients'
                        : 'No recipients found'
                }
            ></Autocomplete>
            <List
                sx={{
                    p: 0,
                    mt: 1.5,
                    overflow: 'scroll',
                    '&::-webkit-scrollbar': { display: 'none' }
                }}
            >
                <Typography
                    variant="h4"
                    sx={{
                        pb: 1,
                        position: 'sticky',
                        top: 0,
                        background: theme.palette.background.paper,
                        zIndex: 2
                    }}
                >
                    {targets.members.length + targets.groups.length} Recipients
                </Typography>
                {targets.members.length >= 1 && (
                    <>
                        <Typography
                            variant="subtitle2"
                            sx={{
                                pb: 1,
                                position: 'sticky',
                                top: 0,
                                background: theme.palette.background.paper,
                                zIndex: 2
                            }}
                        >
                            {targets.members.length} Individuals
                        </Typography>
                        <Divider></Divider>
                    </>
                )}
                {targets.members.map((target, index) => {
                    return (
                        <ListItem
                            sx={{
                                pl: 1,
                                borderBottom: `1px solid ${theme.colors.alpha.black[10]}`
                            }}
                            key={index}
                            secondaryAction={
                                <IconButton
                                    color="error"
                                    onClick={(e) => {
                                        setTargets({
                                            members: targets.members.filter(
                                                (item, _index) =>
                                                    _index !== index
                                            ),
                                            groups: targets.groups
                                        });
                                    }}
                                    edge="end"
                                    aria-label="delete"
                                >
                                    <DeleteTwoTone color="error" />
                                </IconButton>
                            }
                        >
                            <ListItemAvatar>
                                <Avatar>
                                    <Person></Person>
                                </Avatar>
                            </ListItemAvatar>
                            <ListItemText
                                primary={
                                    target.fullName
                                        ? target.fullName
                                        : target.name
                                }
                            ></ListItemText>
                        </ListItem>
                    );
                })}
                {targets.groups.length > 0 && (
                    <>
                        <Typography
                            variant="subtitle2"
                            sx={{
                                pb: 1,
                                pt: targets.members.length >= 1 ? 1 : 0,
                                position: 'sticky',
                                top: 0,
                                background: theme.palette.background.paper,
                                zIndex: 2
                            }}
                        >
                            {targets.groups.length} Groups
                        </Typography>
                        <Divider></Divider>
                    </>
                )}
                {targets.groups.map((target, index) => {
                    return (
                        <ListItem
                            sx={{
                                pl: 1,
                                borderBottom: `1px solid ${theme.colors.alpha.black[10]}`
                            }}
                            key={index}
                            secondaryAction={
                                <IconButton
                                    color="error"
                                    onClick={(e) => {
                                        setTargets({
                                            groups: targets.groups.filter(
                                                (item, _index) =>
                                                    _index !== index
                                            ),
                                            members: targets.members
                                        });
                                    }}
                                    edge="end"
                                    aria-label="delete"
                                >
                                    <DeleteTwoTone color="error" />
                                </IconButton>
                            }
                        >
                            <ListItemAvatar>
                                <Avatar>
                                    <Group></Group>
                                </Avatar>
                            </ListItemAvatar>
                            <ListItemText
                                primary={
                                    target.fullName
                                        ? target.fullName
                                        : target.name
                                }
                            ></ListItemText>
                        </ListItem>
                    );
                })}
            </List>
        </>
    );
}

export default AlpacaRecipientsPane;
