import axios from 'src/utils/axios';

const API_URL = process.env.REACT_APP_API_URL;

const googleAPI: any = {};

googleAPI.importGoogleOrganization = async () => {
    const googleUsers = await axios.get(`/oauth/importGoogleOrganization`)
    return googleUsers;
}

googleAPI.importGoogleGroups = async () => {
    const googleUsers = await axios.get(`/oauth/importGoogleGroups`)
    return googleUsers;
}

googleAPI.fetchLocalDomainMembers = async () => {
    const localGoogleMembers = await axios.get('/oauth/fetchLocalGoogleMembers')
    return localGoogleMembers.data;
}

googleAPI.fetchLocalDomainMembers = async () => {
    const localGoogleMembers = await axios.get('/oauth/fetchLocalGoogleMembers')
    return localGoogleMembers.data;
}

googleAPI.searchLocalDomainMembers = async (searchTerm) => {
    const localGoogleMembers = await axios.get(`/oauth/searchLocalGoogleMembers?searchTerm=${searchTerm}`)
    return localGoogleMembers.data;
}

googleAPI.searchDomainMembers = async (searchTerm) => {
    const googleMembers = await axios.get(`/oauth/searchDirectoryUsers?searchTerm=${searchTerm}`)
    return googleMembers.data
}

googleAPI.searchDomainGroups = async (searchTerm) => {
    const googleGroups = await axios.get(`/oauth/searchDirectoryGroups?searchTerm=${searchTerm}`)
    return googleGroups.data.results
}

googleAPI.searchLocalTargets = async (searchTerm) => {
    const targets = await axios.get(`/oauth/searchLocalTargets?searchTerm=${searchTerm}`)
    return targets.data;
}

googleAPI.listDomainGroups = async (pageToken=null) => {
    const result = await axios.get("/oauth/listDomainGroups", {
        params: {
            pageToken
        }
    });
    return result.data
}

googleAPI.excludeGroups = async (groupsToExclude) => {
    const result = await axios.post("/oauth/excludeGoogleGroups", {
        groupsToExclude
    });
    return result.data
}

export default googleAPI;