import { Card, Container, Grid, InputAdornment, TextField, Typography, useTheme } from '@mui/material';
import { DataGrid, GridColDef, GridToolbarExport, GridToolbarContainer } from '@mui/x-data-grid';

import { useState } from 'react';

import { useQuery } from '@tanstack/react-query';
import PageTitleWrapper from 'src/components/PageTitleWrapper';
import Tag from 'src/components/atoms/Tag';
import loggingAPI from 'src/controllers/logging';
import useAuth from 'src/hooks/useAuth';
import { SearchTwoTone } from '@mui/icons-material';

function StatusChip(status) {
    const theme = useTheme();
    switch (status) {
        case "SUCCESS":
            return <Tag tagColor={theme.colors.success['main']} label='Success'></Tag>
        case "FAILURE":
            return <Tag tagColor={theme.colors.error['main']} label="Failure"></Tag>
        case "WARNING":
            return <Tag tagColor={theme.colors.warning['main']} label="Warning"></Tag>
    }
}

function ActionText(status, text) {
    switch (status) {
        case "SUCCESS":
            return <Typography variant="body1" color="success.dark">{ text }</Typography>
        case "FAILURE":
            return <Typography variant="body1" color="error.dark">{ text }</Typography>
        case "WARNING":
            return <Typography variant="body1" color="warning.dark">{ text }</Typography>
    }
}

function SearchField(props) {
    const { searchTerm, setSearchTerm } = props;
    return (
        <TextField
            autoFocus
            key="searchBox"
            label={searchTerm ? ' ' : 'Type your search terms here...'}
            fullWidth
            size="small"
            variant="outlined"
            sx={{
                borderRadius: '8px',
                borderWidth: '8px',
                "& .MuiInputBase-root": {
                    height: '40px',
                    lineHeight: '40px'
                },
                "& .MuiFormLabel-root": {
                    lineHeight: '22px'
                }
            }}
            value={searchTerm}
            InputLabelProps={{
                shrink: false,
                sx: {
                    pl: 3.5
                }
            }}
            onChange={ (e) => setSearchTerm(e.target.value) }
            InputProps={{
                sx: { },
                startAdornment: (
                    <InputAdornment position="start">
                        <SearchTwoTone color="primary"></SearchTwoTone>
                    </InputAdornment>
                )
            }}
        />
    )
}

function AlpacaLogging() {

    const acc = useAuth();
    const theme = useTheme();
    
    const [paginationModel, setPaginationModel] = useState({
        page: 0,
        pageSize: 10,
    });

    const result: any = useQuery({
        queryKey: ['logData', paginationModel.page, paginationModel.pageSize],
        queryFn: (props) => loggingAPI.listLogs(acc.user.domain, paginationModel.page, paginationModel.pageSize),
        placeholderData: { logs: [], logsCount: 0 }
    })

    const [ searchTerm, setSearchTerm ] = useState('');
    const { data, isLoading } = result;

    const searchQuery = useQuery({
        queryKey: ['logSearchData', paginationModel.page, paginationModel.pageSize, searchTerm],
        queryFn: (props) => loggingAPI.searchLogs(acc.user.domain, searchTerm, paginationModel.page, paginationModel.pageSize),
        placeholderData: { logs: [], logsCount: 0 }
    })

    const columns: GridColDef[] = [
        {
          field: 'status',
          headerName: 'Status',
          width: 120,
          headerClassName: 'first-col',
          editable: false,
          align: 'left',
          renderCell: (params) => {
            return (
                StatusChip(params.row.status)
            )
          }
        },
        {
          field: 'action',
          headerName: 'Action',
          width: 180,
          editable: false,
          renderCell: (params) => {
            return (
                ActionText(params.row.status, params.row.action)
            )
          }
        },
        {
          field: 'object',
          headerName: 'Object',
          width: 110,
          editable: false,
        },
        {
          field: 'email',
          headerName: 'Email',
          valueGetter: (params) => {
            return params.row.initiator.email
          },
          width: 200,
        },
        {
            field: 'description',
            headerName: "Description",
            width: 250,
        },
        {
            field: 'createdAt.date',
            headerName: "Date",
            width: 125,
            valueGetter: (params) => {
                return new Date(params.row.createdAt).toLocaleDateString();
            }
        },
        {
            field: 'createdAt.time',
            headerName: "Time",
            width: 100,
            valueGetter: (params) => {
                return new Date(params.row.createdAt).toLocaleTimeString();
            }
        }
    ];

    function CustomToolbar() {
        return (
            <GridToolbarContainer key="customToolbar" sx={{ p: 2 }}>
            <Grid container justifyContent="space-between">
                <Grid item xs={6}>
                    <SearchField searchTerm={searchTerm} setSearchTerm={setSearchTerm}></SearchField>
                </Grid>
                <Grid item >
                    <GridToolbarExport variant="contained" size="medium" />
                </Grid>
            </Grid>
            </GridToolbarContainer>
        );
    }

    return (
        <>
            <PageTitleWrapper>
                <Grid
                    container
                    direction="row"
                    justifyContent="spaceBetween"
                    alignItems="center"
                >
                    <Grid display="flex" item>
                        <Typography
                            sx={{
                                marginRight: '10px',
                                mb: 0,
                                lineHeight: '50px',
                                fontSize: '24px'
                            }}
                            variant="h3"
                            component="h3"
                            gutterBottom
                        >
                            Logging
                        </Typography>
                        <Typography
                            sx={{
                                marginRight: '10px',
                                fontSize: '34px',
                                lineHeight: '50px',
                                mb: 0
                            }}
                            variant="h3"
                            component="h3"
                            gutterBottom
                        >
                            &#9702;
                        </Typography>
                        <Typography sx={{ lineHeight: '50px' }} variant="h6">
                            Audit actions that have been taken in your organization
                        </Typography>
                    </Grid>
                </Grid>
            </PageTitleWrapper>
            <Container sx={{ pb: 3 }}>
                <Card variant="outlined">
                    <DataGrid
                        sx={{ 
                            borderRadius: 0,
                            borderRight: 0,
                            borderLeft: 0,
                            borderTop: 0,
                            '& .first-col': {
                                marginLeft: '8px',
                                marginRight: '-8px',
                            },
                            "& .MuiChip-root": {
                                ml: '8px',
                            },
                            "& .MuiDataGrid-columnHeaders": {
                                backgroundColor: theme.colors.alpha.black[5],
                                borderTop: `1px solid ${theme.colors.alpha.black[10]}`,
                                borderRadius: 0,
                            },
                            minHeight: 'calc(100vh - 185px)'
                        }}
                        getRowId={(row) => row._id}
                        rows={(searchTerm.length > 0) ? searchQuery.data.logs: data.logs }    
                        columns={columns}
                        disableRowSelectionOnClick
                        slots={{ toolbar: CustomToolbar }}
                        pageSizeOptions={[10, 20, 25]}
                        density="standard"
                        paginationModel={paginationModel}
                        paginationMode="server"
                        onPaginationModelChange={setPaginationModel}
                        rowCount={data.logsCount}
                        loading={isLoading}
                    />
                </Card>
            </Container>
        </>
    );
}

export default AlpacaLogging