import parser from 'cron-parser';
import parseDate from 'date-fns/parse'
import later from '@breejs/later';
import { addDays, isBefore, parseISO } from 'date-fns';

interface daysOfWeek {
    monday: boolean,
    tuesday: boolean,
    wednesday: boolean,
    thursday: boolean,
    friday: boolean,
    saturday: boolean,
    sunday: boolean,
}

const daysOfWeekMap = [
    'monday',
    'tuesday',
    'wednesday',
    'thursday',
    'friday',
    'saturday',
    'sunday'
]

const shortenedDaysOfWeekMap = [
    'mon',
    'tue',
    'wed',
    'thu',
    'fri',
    'sat',
    'sun',
]

interface DailyRecurrenceCronProps {
    every: number, endsAfter: string, endsDate: Date, endsNumber: number
}

interface WeeklyRecurrenceCronProps {
    every: number, endsAfter: string, endsDate: Date, endsNumber: number,
    monday: boolean,
    tuesday: boolean,
    wednesday: boolean,
    thursday: boolean,
    friday: boolean,
    saturday: boolean,
    sunday: boolean,
}

interface MonthlyRecurrenceCronProps {
    every: number, endsAfter: string, endsDate: Date, endsNumber: number, onDayOfMonth: number, dayOfWeek: number, onDateOrDaySwitch: 'onDateRadio' | 'onDayOfWeekRadio'
}

interface YearlyRecurrenceCronProps {
    onDayOfMonth, endsAfter, endsDate, endsNumber
}

const AlpacaParse = {
    unfurlEvents: function (events, start, end) {
        return events.flatMap((event) => {
            const runDateObj = new Date((!event.repeatInterval && event.lastRunAt) ? event.lastRunAt:event.nextRunAt);
            const eventEndDateObj = parseISO(event.endDate);
            const searchEndDateObj = end;
            const startDateObj = start;

            const currentDate = (isBefore(startDateObj, runDateObj)) ? runDateObj.toISOString() : startDateObj.toISOString();
            let scheduleEndDate = (isBefore(eventEndDateObj, searchEndDateObj)) ? eventEndDateObj:searchEndDateObj;
            if (event.repeatInterval && event.skipDays < 1) {
                const cron = later.parse.cron(event.repeatInterval, true);
                const schedulePreNext = later.schedule(cron);
                const schedule = later.schedule(cron).next(Infinity, parseISO(currentDate), scheduleEndDate);

                if (!schedule.length) return [];
                
                return schedule.map((date, index) => {
                    return {
                        instance: true,
                        start: date,
                        end: date,
                        id: event._id,
                        editable: false,
                        title: event.name, 
                        classNames: ['recurring-event'],
                    }
                })
            } else if (event.repeatInterval && event.skipDays >= 1) {
                const recurrenceExpansions = [];
                recurrenceExpansions.push({
                    instance: true,
                    start: runDateObj,
                    end: runDateObj,
                    title: event.name,
                    id: event._id,
                    editable: false,
                    classNames: ['recurring-event'],
                });

                let nextDate = addDays(runDateObj, event.skipDays + 1);

                (function addRecurrenceInstanceFromSkipDays() {
                    if ((nextDate <= eventEndDateObj || event.endDate === null) && nextDate <= searchEndDateObj) {
                        recurrenceExpansions.push({
                            instance: true,
                            start: nextDate.toISOString(),
                            end: nextDate.toISOString(),
                            title: event.name,
                            classNames: ['recurring-event'],
                            id: event._id,
                            editable: false,
                        });
                        nextDate = addDays(nextDate, event.skipDays + 1);
                        addRecurrenceInstanceFromSkipDays();
                    }
                })();

                return recurrenceExpansions
            } else {
                return {
                    start: runDateObj.toISOString(),
                    end: runDateObj.toISOString(),
                    title: event.name, 
                    id: event._id,
                }
            }
        })
    },
    generateDailyRecurrenceCron: function ({every, endsAfter, endsDate, endsNumber}: DailyRecurrenceCronProps, date) {
        const onDays = (every > 1) ? `*/${every}` : '*';
        return `0 ${date.getMinutes()} ${date.getUTCHours()} * * *`
    },
    generateLocalDailyRecurrenceCron: function ({ every, endsAfter, endsDate, endsNumber }: DailyRecurrenceCronProps, date) {
        const onDays = (every > 1) ? `*/${every}` : '*';
        return `0 ${date.getMinutes()} ${date.getHours()} * * *`
    },
    generateWeeklyRecurrenceCron: function ({ every, endsAfter, endsDate, endsNumber, monday, tuesday, wednesday, thursday, friday, saturday, sunday }: WeeklyRecurrenceCronProps, date) {
        let daysOfWeekString = ''
        const daysOfWeek = {
            monday, tuesday, wednesday, thursday, friday, saturday, sunday
        }

        Object.keys(daysOfWeek).forEach((item, index, arr) => { 
            if (daysOfWeek[item] === true) {
                const num = shortenedDaysOfWeekMap[daysOfWeekMap.indexOf(item)];
                daysOfWeekString = daysOfWeekString + num + ','
            }
        })
        return `0 ${date.getMinutes()} ${date.getUTCHours()} * * ${daysOfWeekString.substring(0, daysOfWeekString.length - 1)}`  
    },
    generateLocalWeeklyRecurrenceCron: function ({ every, endsAfter, endsDate, endsNumber, monday, tuesday, wednesday, thursday, friday, saturday, sunday }: WeeklyRecurrenceCronProps, date) {
        let daysOfWeekString = ''
        const daysOfWeek = {
            monday, tuesday, wednesday, thursday, friday, saturday, sunday
        }

        Object.keys(daysOfWeek).forEach((item, index, arr) => { 
            if (daysOfWeek[item] === true) { 
                const day = shortenedDaysOfWeekMap[daysOfWeekMap.indexOf(item)];
                daysOfWeekString = daysOfWeekString + day + ','
            }
        })
        return `0 ${date.getMinutes()} ${date.getHours()} * * ${daysOfWeekString.substring(0, daysOfWeekString.length - 1)}`  
    },
    generateMonthlyRecurrenceCron: function ({ every, endsAfter, endsDate, endsNumber, onDayOfMonth, dayOfWeek, onDateOrDaySwitch }: MonthlyRecurrenceCronProps, date) {
        date.setDate(onDayOfMonth);
        let recurrenceString = (onDateOrDaySwitch === 'onDateRadio') ? 
        `0 ${date.getMinutes()} ${date.getUTCHours()} ${date.getUTCDate()} */${every} *`
            :
        `0 ${date.getMinutes()} ${date.getUTCHours()} * */${every} ${dayOfWeek}`
        return recurrenceString
    },
    generateLocalMonthlyRecurrenceCron: function ({every, endsAfter, endsDate, endsNumber, onDayOfMonth, dayOfWeek, onDateOrDaySwitch }: MonthlyRecurrenceCronProps, date) {
        date.setDate(onDayOfMonth);
        let recurrenceString = `0 ${date.getMinutes()} ${date.getHours()} ${date.getDate()} */${every} *`
        return recurrenceString
    },
    generateYearlyRecurrenceCron: function ({ monthOfYearSelect, onDayOfMonth, endsAfter, endsDate, endsNumber }, date) {
        let recurrenceString = `0 ${date.getMinutes()} ${date.getUTCHours()} ${onDayOfMonth} ${monthOfYearSelect} *`;
        return recurrenceString
    },
    generateLocalYearlyRecurrenceCron: function ({ monthOfYearSelect, onDayOfMonth, endsAfter, endsDate, endsNumber }, date) {
        let recurrenceString = `0 ${date.getMinutes()} ${date.getHours()} ${onDayOfMonth} ${monthOfYearSelect} *`;
        return recurrenceString
    },
    calculateWeeklyEndDate: function(endsAfter, endsNumber, endsDate, cronString, scheduledDate) {
        let calculatedEndDate;
        if (endsAfter === 'occurrences') {
            let endDate;
            const interval = parser.parseExpression(cronString, { tz: "Etc/Greenwich", currentDate: scheduledDate })
            if (endsNumber === 1) {
                calculatedEndDate = parseISO(scheduledDate.toISOString());
            }
            for (let i = 1; i < endsNumber; i++) {
                endDate = interval.next();
            }
            if (endsNumber !== 1) {
                calculatedEndDate = parseDate(endDate._date.ts, 'T', new Date())
            }
        } else {
            const endsDateObj = parseDate(endsDate, 'yyyy-MM-dd', new Date())
            calculatedEndDate = new Date(scheduledDate);
            calculatedEndDate.setDate(endsDateObj.getDate())
            calculatedEndDate.setMonth(endsDateObj.getMonth())
            calculatedEndDate.setFullYear(endsDateObj.getFullYear())
            calculatedEndDate.setTime(calculatedEndDate.getTime() + 60000)
        }
        return calculatedEndDate;
    },
    calculateMonthlyEndDate: function (endsAfter, scheduledDate, endsDate, endsNumber, cronString) {
        let calculatedEndDate;
        if (endsAfter === 'occurrences') {
            let endDate;

            const interval = parser.parseExpression(cronString, { tz: "Etc/Greenwich", currentDate: scheduledDate });
            for (let i = 0; i < endsNumber - 1; i++) {
                endDate = interval.next();
            }
            calculatedEndDate = parseDate(endDate._date.ts, 'T', new Date())

        } else if (endsAfter === 'date') {
            const endsDateObj = parseDate(endsDate, 'yyyy-MM-dd', new Date())
            calculatedEndDate = new Date(scheduledDate);
            calculatedEndDate.setDate(endsDateObj.getDate())
            calculatedEndDate.setMonth(endsDateObj.getMonth())
            calculatedEndDate.setFullYear(endsDateObj.getFullYear())
            calculatedEndDate.setTime(calculatedEndDate.getTime() + 60000)
        } else {
            calculatedEndDate = null;
        }
        return calculatedEndDate;
    },
    calculateDailyEndDate: function (endsAfter, scheduledDate, endsDate, every, cronString, endsNumber) {
        let calculatedEndDate;
        if (endsAfter === 'occurrences') {
            let endDate;
            if (every > 1) {
                endDate = new Date(scheduledDate.toJSON())
                for (let i = 1; i < endsNumber; i++) {
                    endDate.setDate(endDate.getDate() + every)
                }
                calculatedEndDate = endDate;
            } else {
                const interval = parser.parseExpression(cronString, { tz: "Etc/Greenwich", currentDate: scheduledDate });
                for (let i = 1; i < endsNumber; i++) {
                    endDate = interval.next();
                }
                calculatedEndDate = parseDate(endDate._date.ts, 'T', new Date())
            }
        } else if (endsAfter === 'date') {
            const endsDateObj = parseDate(endsDate, 'yyyy-MM-dd', new Date())
            calculatedEndDate = new Date(scheduledDate);
            calculatedEndDate.setDate(endsDateObj.getDate())
            calculatedEndDate.setMonth(endsDateObj.getMonth())
            calculatedEndDate.setFullYear(endsDateObj.getFullYear())
            calculatedEndDate.setTime(calculatedEndDate.getTime() + 60000)
        } else {
            calculatedEndDate = null;
        }
        return calculatedEndDate;
    },
    calculateYearlyEndDate: function (endsAfter, scheduledDate, endsDate, every, cronString, endsNumber) {
        let calculatedEndDate;
        if (endsAfter === 'occurrences') {
            let endDate;
            const interval = parser.parseExpression(cronString, { tz: "Etc/Greenwich", currentDate: scheduledDate });
            for (let i = 0; i < endsNumber; i++) {
                endDate = interval.next();
            }
            calculatedEndDate = parseDate(endDate._date.ts, 'T', new Date())
        } else if (endsAfter === 'date') {
            const endsDateObj = parseDate(endsDate, 'yyyy-MM-dd', new Date())
            calculatedEndDate = new Date(scheduledDate);
            calculatedEndDate.setDate(endsDateObj.getDate())
            calculatedEndDate.setMonth(endsDateObj.getMonth())
            calculatedEndDate.setFullYear(endsDateObj.getFullYear())
            calculatedEndDate.setTime(calculatedEndDate.getTime() + 60000)
        } else {
            calculatedEndDate = null;
        }
        return calculatedEndDate;
    },
}

export default AlpacaParse