import { useState, useRef, useCallback, useEffect } from 'react';

import axios from 'src/utils/axios';

import {
  Box,
  Menu,
  Tooltip,
  IconButton,
  Button,
  ListItemText,
  ListItem,
  List,
  Typography,
  styled,
  Autocomplete,
  TextField,
  Divider
} from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import { useTranslation } from 'react-i18next';

import GroupTwoToneIcon from '@mui/icons-material/GroupAddTwoTone';
import RemoveGroupTwoTone from '@mui/icons-material/GroupRemoveTwoTone';
import MoreVertTwoToneIcon from '@mui/icons-material/MoreVertTwoTone';
import VerifiedUserTwoToneIcon from '@mui/icons-material/VerifiedUserTwoTone';

import useRefMounted from 'src/hooks/useRefMounted';
import useAuth from 'src/hooks/useAuth';
import notificationAPI from 'src/controllers/notifications'

const ButtonError = styled(Button)(
  ({ theme }) => `
     background: ${theme.colors.error.main};
     color: ${theme.palette.error.contrastText};

     &:hover {
        background: ${theme.colors.error.dark};
     }
    `
);

function BulkActions({ submitGroupChange, groupSelected, setGroupSelected, isSubmittingGroupChange, submitGroupRemove, selectionInGroup }) {
  const [onMenuOpen, menuOpen] = useState<boolean>(false);
  const moreRef = useRef<HTMLButtonElement | null>(null);
  const { t }: { t: any } = useTranslation();
  const isMountedRef = useRefMounted();
  const [groups, setGroups] = useState([{name: "", id: ''}]);
  const { user } = useAuth();

  const openMenu = (): void => {
    menuOpen(true);
  };

  const closeMenu = (): void => {
    menuOpen(false);
  };

  const handleChange = (e, value) => {
    setGroupSelected(value)
  }

  const getGroups = useCallback(async () => {
    try {
        const response = await axios.get('/organizations/groups', {
            params: { domain: user.domain }
        })
        if (isMountedRef.current) {
            setGroups(response.data.groups)
        }
    } catch (err) {
      console.error(err);
    }
  }, [isMountedRef]);
    
    useEffect(() => {
        getGroups();
    }, [getGroups]);

  return (
    <>
      <Box display="flex" alignItems="center" justifyContent="space-between">
        <Box display="flex" alignItems="center" sx={{"width": "80%"}}>
          <Autocomplete
            sx={{
              width: "33%",
            }}
            size="small"
            limitTags={3}
            options={groups}
            value={groupSelected}
            onChange={handleChange}
            getOptionLabel={(option) => option.name}
            //defaultValue={[jobsTags[0], jobsTags[1]]}
            renderInput={(params) => (
              <TextField
                {...params}
                fullWidth
                variant="outlined"
                label={t('Groups')}
                placeholder={t('Select tags...')}
              />
            )}
          />
          <LoadingButton
            sx={{
              ml: 2
            }}
            startIcon={<GroupTwoToneIcon />}
            variant="contained"
            loading={isSubmittingGroupChange}
            //onClick={}
            disabled={ groupSelected === null || selectionInGroup === 1 }
            onClick={ submitGroupChange }
          >
            {t('Add to group')}
          </LoadingButton>
          <LoadingButton
            sx={{
              ml: 2
            }}
            startIcon={<RemoveGroupTwoTone />}
            variant="contained"
            color="error"
            loading={isSubmittingGroupChange}
            //onClick={}
            disabled={ groupSelected === null || selectionInGroup === 0 }
            onClick={ submitGroupRemove }
          >
            {t('Remove from group')}
          </LoadingButton>
        </Box>
        <IconButton
          color="primary"
          onClick={openMenu}
          ref={moreRef}
          sx={{
            ml: 2,
            p: 1
          }}
        >
          <MoreVertTwoToneIcon />
        </IconButton>
      </Box>

      <Menu
        disableScrollLock
        keepMounted
        anchorEl={moreRef.current}
        open={onMenuOpen}
        onClose={closeMenu}
        anchorOrigin={{
          vertical: 'center',
          horizontal: 'center'
        }}
        transformOrigin={{
          vertical: 'center',
          horizontal: 'center'
        }}
      >
        <List
          sx={{
            p: 1
          }}
          component="nav"
        >
          <ListItem button>
            <ListItemText primary={t('Bulk edit accounts')} />
          </ListItem>
          <ListItem button>
            <ListItemText primary={t('Close selected accounts')} />
          </ListItem>
        </List>
      </Menu>
    </>
  );
}

export default BulkActions;
