import {
    Card,
    Grid,
    CardContent,
    CardActions,
    Typography,
    styled,
    useTheme,
    lighten,
} from '@mui/material'

import { DragIndicator } from '@mui/icons-material'

import Tag from 'src/components/atoms/Tag'

export default function DraggableTemplateCard(props) {
    const theme = useTheme()
    const { details, ...otherProps } = props
    const DraggableSignifier = styled('div')`
        width: 24px;
        display: flex;
        align-items: center;
        border-right: 1px solid ${theme.colors.alpha.black[10]};
        margin-top: 8px;
        margin-bottom: 8px;
    `   
    return (
        <Card
            data-name={details.name}
            data-title={details.title}
            data-subtitle={details.subtitle}
            data-notif-style={details.notifStyle}
            data-notif-tags={details.tags}
            data-body={details.body}
            data-styling-info={JSON.stringify(details.stylingInfo)}
            variant="outlined"
            sx={{
                overflow: 'hidden',
                cursor: 'pointer',
                height: '100%',
                border: `1px solid #e0e2e6`,
                transition: 'transform .25s',
                '&:hover': {
                    transform: 'scale(1.05)',
                },
            }}
            {...otherProps}
        >
            <Grid
                container
                direction="row"
                sx={{
                    justifyContent: 'flex-start',
                    display: 'flex',
                    height: '100%',
                    minHeight: '96px',
                }}
            >
                <DraggableSignifier>
                    <DragIndicator
                        sx={{
                            color: theme.colors.alpha.black[50],
                        }}
                    />
                </DraggableSignifier>
                <Grid
                    item
                    container
                    direction="column"
                    xs={10}
                    sx={{
                        padding: '12px',
                        pr: 0,
                        textAlign: 'right',
                        lineHeight: '16px',
                        flex: 1,
                    }}
                    justifyContent="space-between"
                >
                    <CardContent
                        sx={{
                            p: 0,
                            width: '100%',
                        }}
                    >
                        <Grid item sx={{}}>
                            <Typography
                                sx={{
                                    textAlign: 'left',
                                    lineHeight: '18px',
                                    whiteSpace: 'nowrap',
                                    fontSize: '14px',
                                    overflow: 'hidden',
                                    textOverflow: 'ellipsis',
                                }}
                                variant="h3"
                            >
                                {details.name}
                            </Typography>
                        </Grid>
                        <Typography
                            sx={{
                                textOverflow: 'ellipsis',
                                whiteSpace: 'nowrap',
                                textAlign: 'left',
                                mt: '.125em',
                                overflow: 'hidden',
                                fontSize: '13px',
                            }}
                            variant="subtitle2"
                        >
                            {details.title}
                        </Typography>
                    </CardContent>
                    <CardActions
                        sx={{
                            padding: '0',
                            justifySelf: 'flex-end',
                            marginTop: 'auto',
                            paddingTop: '10px',
                            maxWidth: '100%',
                            display: 'flex',
                            flexWrap: 'wrap',
                        }}
                        disableSpacing
                    >
                        <Grid container direction="row" spacing={0.5}>
                            {details.tags &&
                                details.tags.map((tag, index) => (
                                    <Grid key={tag._id} item>
                                        <Tag
                                            functional={tag.functional}
                                            label={tag.name}
                                            tagColor={tag.color}
                                            key={tag._id}
                                        />
                                    </Grid>
                                ))}
                        </Grid>
                    </CardActions>
                </Grid>
            </Grid>
        </Card>
    )
}
