import { useRoutes } from 'react-router-dom';
import router from 'src/router';

import { ReactQueryDevtools } from '@tanstack/react-query-devtools'

import { SnackbarProvider } from 'notistack';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import useAuth from 'src/hooks/useAuth';

import { CssBaseline } from '@mui/material';
import ThemeProvider from './theme/ThemeProvider';
import AppInit from './components/AppInit';

import { GoogleOAuthProvider } from '@react-oauth/google';

import { QueryClient, QueryClientProvider } from '@tanstack/react-query'

const queryClient = new QueryClient();

function App() {
  const content = useRoutes(router);
  const auth = useAuth();

  return (
    <ThemeProvider>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <QueryClientProvider client={queryClient}>
          <GoogleOAuthProvider clientId="137840602750-frdlideb6a34rvi44hjfp332p4flgtqe.apps.googleusercontent.com">
            <SnackbarProvider
              maxSnack={6}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right'
              }}
            >
              <CssBaseline />
              {auth.isInitialized ? content : <AppInit />}
              <ReactQueryDevtools initialIsOpen={false} />
            </SnackbarProvider>
          </GoogleOAuthProvider>
        </QueryClientProvider>
      </LocalizationProvider>
    </ThemeProvider>
  );
}
export default App;
