import {
    Diamond,
    KeyboardArrowLeft,
    KeyboardArrowRight,
} from '@mui/icons-material';
import {
    Tabs,
    Tab,
    Box,
    MobileStepper,
    Button,
    Grid,
    SvgIcon,
    styled,
    useTheme,
} from '@mui/material';
import { useState } from 'react';
import { PaletteTwoTone } from '@mui/icons-material';
import { blue } from '@mui/material/colors';

import { iconOptions, iconMap } from '../../../constants/iconConstants';

interface TabPanelProps {
    children?: React.ReactNode;
    dir?: string;
    index: number;
    value: number;
}

function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`full-width-tabpanel-${index}`}
            aria-labelledby={`full-width-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ pb: .25, pt: .25, mt: 1 }}>
                    {children}
                </Box>
            )}
        </div>
    );
}

const StyledGrid = styled(Grid)(({ theme }) => ({
    'display': 'flex',
    'justifyContent': 'center',
    'alignItems': 'center',
    'alignContent': 'center',
    'minHeight': '50px',
    'minWidth': '50px',
    'textAlign': 'center',
    'maxWidth': '48px',
    borderRadius: '6px',
    '&.active': {
        outline: `2px solid ${theme.colors.primary['main']}`,
        outlineOffset: '-6px',
    },
    '&:hover': {
        background: theme.colors.alpha.black['10']
    },
    flex: '1 0 18%',
    color: '#223354 !important'
}));

const Div = styled('div')(({ theme }) => ({
    borderRadius: "4px",
    height: "32px",
    width: '32px',
    "&.active": {
        border: `2px solid ${theme.colors.primary['main']}`
    },
    cursor: "pointer"
}));

const StyledMobileStepper = styled(MobileStepper)(({ theme }) => ({
    "&.MuiMobileStepper-root": {
        background: 'unset',
    }
}))

function getContrastYIQ(hexcolor) {
    if (hexcolor === 'ffffff') {
        return '#1A75FF'
    }

    var r = parseInt(hexcolor.substr(0, 2), 16);
    var g = parseInt(hexcolor.substr(2, 2), 16);
    var b = parseInt(hexcolor.substr(4, 2), 16);
    var yiq = ((r * 299) + (g * 587) + (b * 114)) / 1000;
    return (yiq >= 150) ? 'black' : 'white';
}

export default function AlpacaStyleEditor(props) {
    const { backgroundColor, setBackgroundColor, onChange, icon, setIcon, onIconChange } = props;

    const theme = useTheme();

    const [tab, setTab] = useState(0);
    const [activeStep, setActiveStep] = useState(0);

    const handleNext = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const handleTabChange = (event, newVal) => {
        setTab(newVal);
    };

    const colors = [
        '89D964',
        'FFBE5E',
        'CE93D8',
        '5E9EFF',
        '089285',
        'E6EE9C',
        'EF5350',
        'BCAAA4',
        'FDD835',
        '7E57C2',
        'E28030',
        '682B7A',
        '027F13',
        '0271D9',
    ]

    const generateColorBlock = (color, active = false) => {
        return (<Div style={{ border: (active) ? `1px solid ${theme.colors.primary['main']}` : '', backgroundColor: '#' + color }} ></Div>)
    }

    return (
        <Grid container rowSpacing={2}>
            <Grid item xs={12}>
                <Tabs
                    value={tab}
                    onChange={handleTabChange}
                    aria-label="basic tabs example"
                >
                    <Tab iconPosition="start" icon={<Diamond />} label="Icon" />
                    <Tab
                        iconPosition="start"
                        icon={<PaletteTwoTone></PaletteTwoTone>}
                        label="Color"
                    />
                </Tabs>
            </Grid>
            <Grid item xs={4}>
                <Box
                    sx={{
                        backgroundColor: `#${backgroundColor}`,
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        marginRight: 2,
                        height: '100%',
                        borderRadius: '8px',
                        border: `1px solid ${theme.colors.alpha.black[30]}`,
                        maxHeight: "170px",
                    }}
                >
                    <SvgIcon sx={{ fontSize: '48px', color: getContrastYIQ(backgroundColor) }}>
                        {icon !== 'none' &&
                            iconMap[icon]
                        }
                    </SvgIcon>
                </Box>
            </Grid>
            <Grid item xs={8} sx={{ mt: '-3px', maxWidth: '290px !important' }}>
                <TabPanel value={tab} index={0} >
                    {activeStep === 0 && (
                        <Grid container sx={{ justifyContent: 'space-between', border: `1px solid ${theme.colors.alpha.black['30']}`, borderRadius: '8px', p: 1, mt: '-9px', }}>
                            {iconOptions.slice(0, 15).map((iconName, key) => {
                                const iconDOM = iconMap[iconName];
                                return (
                                    <StyledGrid
                                        key={key}
                                        onClick={() => { onIconChange(iconName) }}
                                        className={(icon === iconName) ? 'active' : ''}
                                        item
                                    >
                                        <SvgIcon>
                                            {iconDOM}
                                        </SvgIcon>
                                    </StyledGrid>
                                )
                            })
                            }
                        </Grid>
                    )}
                    {
                        activeStep === 1 && (
                            <Grid container sx={{ justifyContent: 'space-between', border: `1px solid ${theme.colors.alpha.black['30']}`, borderRadius: '8px', p: 1, mt: '-9px', }}>
                                {iconOptions.slice(16, 31).map((iconName, key) => {
                                    const iconDOM = iconMap[iconName];
                                    return (
                                        <StyledGrid
                                            key={key}
                                            onClick={() => { onIconChange(iconName) }}
                                            className={(icon === iconName) ? 'active' : ''}
                                            item
                                        >
                                            <SvgIcon>
                                                {iconDOM}
                                            </SvgIcon>
                                        </StyledGrid>
                                    )
                                })
                                }
                            </Grid>
                        )
                    }
                </TabPanel>
                <TabPanel value={tab} index={1}>
                    <Grid container spacing={1} sx={{ mt: '-10px', maxWidth: '290px !important', minHeight: '170px', justifyContent: 'center', border: `1px solid ${theme.colors.alpha.black[30]}`, p: 1, borderRadius: '8px', pb: 2, pr: 2, ml: '0 !important' }}>
                        <Grid item >
                            <Div onClick={() => onChange('ffffff')} style={{ backgroundColor: '#ffffff', border: `1px solid ${theme.colors.alpha.black['30']}` }}></Div>
                        </Grid>
                        {
                            colors.map((color, i) => {
                                return (
                                    <Grid item key={i} onClick={() => { onChange(color) }}>
                                        {generateColorBlock(color, (color === backgroundColor))}
                                    </Grid>
                                )
                            })
                        }
                    </Grid>
                </TabPanel>
            </Grid>
            {tab === 0 &&
                <Grid container item xs={12} sx={{ pt: '0px !important', pb: '8px' }} justifyContent="flex-start">
                    <Grid item xs={4}></Grid>
                    <Grid item xs={8} sx={{ maxWidth: '290px !important' }}>
                        <StyledMobileStepper
                            variant="dots"
                            steps={2}
                            position="static"
                            activeStep={activeStep}
                            sx={{ flexGrow: 1 }}
                            nextButton={
                                <Button
                                    size="small"
                                    onClick={handleNext}
                                    disabled={activeStep === 1}
                                >
                                    <KeyboardArrowRight />
                                </Button>
                            }
                            backButton={
                                <Button
                                    size="small"
                                    onClick={handleBack}
                                    disabled={activeStep === 0}
                                >
                                    <KeyboardArrowLeft />
                                </Button>
                            }
                        />
                    </Grid>
                </Grid>
            }
        </Grid>
    );
}
