import axios from "src/utils/axios";
const webhooksAPI: any = {};

webhooksAPI.listWebhooks = async (page) => {
    const result = await axios.get(`/webhooks/list`, {
        params: {
            page
        }
    })
    return result.data
}

webhooksAPI.createWebhook = async () => {
    const result = await axios.post('/webhooks/create', {
        webhookConfig: {
            name: "Untitled Webhook",
            origin: "No origin configured"
        }
    })
    return result.data
}

webhooksAPI.deleteWebhook = async (webhookConfigID) => {
    const result = await axios.post('/webhooks/delete', {
        webhookConfigID
    })
    return result.data
}

webhooksAPI.updateWebhook = async (webhookConfigID, update) => {
    const result = await axios.post('/webhooks/update', { 
        webhookConfigID, 
        update
    })
    return result.data
}

export default webhooksAPI;