import * as Yup from 'yup';
import { useState, type FC } from 'react';
import { Formik } from 'formik';
import { Link as RouterLink , useNavigate } from 'react-router-dom';

import { GoogleLogin } from '@react-oauth/google';

import {
  Box,
  Button,
  FormHelperText,
  TextField,
  Checkbox,
  Typography,
  Link,
  FormControlLabel,
  CircularProgress,
  Alert,
  useTheme
} from '@mui/material';
import useAuth from 'src/hooks/useAuth';
import useRefMounted from 'src/hooks/useRefMounted';
import { useTranslation } from 'react-i18next';

const LoginJWT: FC = () => {
  const { login, registerWithGoogle } = useAuth() as any;
  const isMountedRef = useRefMounted();
  const { t }: { t: any } = useTranslation();

  const [ error, setError ] = useState(null);

  const theme = useTheme();

  let navigate = useNavigate();

  return (
    <Formik
      initialValues={{
        email: '',
        password: '',
        terms: false,
        submit: null
      }}
      validationSchema={Yup.object().shape({
        email: Yup.string()
          .email(t('The email provided should be a valid email address'))
          .max(255)
          .required(t('The email field is required')),
        password: Yup.string()
          .max(255)
          .required(t('The password field is required')),
        terms: Yup.boolean().oneOf(
          [true],
          t('You must agree to our terms and conditions')
        )
      })}
      onSubmit={async (
        values,
        { setErrors, setStatus, setSubmitting }
      ): Promise<void> => {
        try {
          const result = await login(values.email, values.password);

          if (isMountedRef.current) {
            setStatus({ success: true });
            setSubmitting(false);
          }
        } catch (err) {
          if (isMountedRef.current) {
          console.error(err);
            setStatus({ success: false });
            setErrors({ submit: err.message });
            setSubmitting(false);
          }
        }
      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting,
        touched,
        values
      }): JSX.Element => (
        <form noValidate onSubmit={handleSubmit}>
            <Typography
                variant="h3"
                sx={{
                mb: 3,
                fontSize: '22px',
                textAlign: 'center'
                }}
            >
                {t('Log in or register')}
            </Typography>
            <GoogleLogin
              onSuccess={(credentialResponse: { credential?: string, clientId?: string, select_by?: string }) => {
                registerWithGoogle(credentialResponse.credential)
                    .then((user) => {
                        if (user.error) {
                            setError(user.error);
                        }
                        if (user.onboardingStep || user.onboardingStep === 0) {
                            navigate(`/welcome#${user.onboardingStep}`)
                        } else { 
                            navigate("/calendar"); 
                        }
                    })
                    .catch((error) => {
                        console.log(error)
                    })
              }}
              onError={() => {
                console.log('Login Failed');
              }}
              width="400px"
              theme={(theme.palette.mode === 'dark') ? 'filled_black':'outline'}
            />
            { error && <Box sx={{ mt: 2 }}>
                <Alert severity="error">{error}</Alert>
            </Box> }
            <Box
              alignItems="center"
              display={{ xs: 'block', md: 'flex' }}
              justifyContent="spaceBetween"
              
            >
            </Box>

            {Boolean(touched.terms && errors.terms) && (
            <FormHelperText error>{errors.terms}</FormHelperText>
            )}


        </form>
      )}
    </Formik>
  );
};

export default LoginJWT;
