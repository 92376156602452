
import { Box, Stack, SvgIcon, Typography, IconButton, useTheme } from '@mui/material';
import { DescriptionTwoTone } from '@mui/icons-material';
import { ExpandMore } from '@mui/icons-material';

export default function CollapisibleHeader(props) {
    const { onClick, icon, open, title } = props;
    const theme = useTheme();
    return (
        <Box 
            sx={{
                backgroundColor: theme.colors.primary['lighter'],
                p: .25,
                pl: 1,
                cursor: 'pointer',
                borderRadius: "8px"
            }}
            onClick={onClick}
        >
            <Stack direction="row" display="flex" justifyContent="space-between">
                <Stack direction="row" display="flex" alignItems="center" spacing={1}>
                    <SvgIcon>
                        { icon }
                    </SvgIcon>
                    <Typography variant="h5" color="primary">
                       { title }
                    </Typography>
                </Stack>
                <IconButton>
                    <ExpandMore
                        sx={{
                            transform: (open) && 'rotate(180deg)',
                            transition: 'transform .25s ease'
                        }}
                        color="primary"
                    ></ExpandMore>
                </IconButton>
            </Stack>
        </Box>
    )
}