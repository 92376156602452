import axios from "src/utils/axios";
const loggingAPI: any = {};

loggingAPI.listLogs = async (domain, pageNumber = 0, pageLength = 50) => {
    const result = await axios.get(`/logs/list`, 
    { params: { domain, pageNumber, pageLength } })
    return result.data
}

loggingAPI.searchLogs = async (domain, searchTerm, pageNumber = 0, pageLength = 50) => {
    const result = await axios.get(`/logs/search`, 
    { params: { domain, searchTerm, pageNumber, pageLength } })
    return result.data
}

export default loggingAPI;