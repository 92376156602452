import {
  Box,
  Tooltip,
  Badge,
  TooltipProps,
  tooltipClasses,
  Typography,
  styled,
  useTheme
} from '@mui/material';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const logo = '/static/images/logo/2x/Artboard 1@2x.png';

const LogoWrapper = styled(Link)(
  ({ theme }) => `
        color: ${theme.colors.alpha.trueWhite[100]};
        display: flex;
        text-decoration: none;
        width: 230px;
        height: 50px;
        display: flex;
        font-weight: bold;
        font-size: 24px;
        line-height: 50px;
        border-radius: 5px;
        a:visited {
          color: ${theme.colors.alpha.trueWhite[100]};
        }
`
);

const LogoSignWrapper = styled(Box)(
  () => `
        width: 52px;
        height: 38px;
`
);

const LogoSign = styled(Box)(
  ({ theme }) => `
        background: ${theme.general.reactFrameworkColor};
        width: 18px;
        height: 18px;
        border-radius: ${theme.general.borderRadiusSm};
        position: relative;
        transform: rotate(45deg);
        top: 3px;
        left: 17px;

        &:after, 
        &:before {
            content: "";
            display: block;
            width: 18px;
            height: 18px;
            position: absolute;
            top: -1px;
            right: -20px;
            transform: rotate(0deg);
            border-radius: ${theme.general.borderRadiusSm};
        }

        &:before {
            background: ${theme.palette.primary.main};
            right: auto;
            left: 0;
            top: 20px;
        }

        &:after {
            background: ${theme.palette.secondary.main};
        }
`
);

const LogoSignInner = styled(Box)(
  ({ theme }) => `
`
);

const StyledBadge = styled(Badge)(({ theme }) => ({
  '& .MuiBadge-badge': {
    position: 'relative',
    top: '-58px',
    width: '35px',
    left: '130px',
  },
  '& .MuiBadge-root': {
    zIndex: '2 !important',
    display: 'none',
  }
}));

const TooltipWrapper = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.colors.alpha.trueWhite[100],
    color: theme.palette.getContrastText(theme.colors.alpha.trueWhite[100]),
    fontSize: theme.typography.pxToRem(12),
    fontWeight: 'bold',
    borderRadius: theme.general.borderRadiusSm,
    boxShadow:
      '0 .2rem .8rem rgba(7,9,25,.18), 0 .08rem .15rem rgba(7,9,25,.15)'
  },
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.colors.alpha.trueWhite[100]
  }
}));

function Logo({variant}) {
  const { t }: { t: any } = useTranslation();
  const theme = useTheme();

  const ImgWrapper = function(props) {
    return (
      <Box sx={{ 
        display: "box",
        height: '50px', 
        width: '50px', 
        backgroundColor: theme.palette.secondary.main, 
        borderRadius: '999em', 
        marginRight: '15px', 
        border: `2px solid ${theme.colors.alpha.white[70]}`, 
        overflow: 'hidden', 
        transform: 'margin-top: 2px' }}>
        {props.children}
      </Box>
    )
  }

  const imgStyle = {
    transform: "translateY(2px) translateX(2px)",
  }

  return (
    <TooltipWrapper title={t('HMMMMMMMM-SCREE-HAW!')} arrow>
        <img width="184" alt={"The Alpaca Notify logo, a white alpaca with glasses surrounded by a cyan chat bubble"} style={imgStyle} src={logo}></img>
    </TooltipWrapper>
  );
}

export default Logo;
