import { Suspense, useState, useRef, useMemo } from 'react';

import FullCalendar from '@fullcalendar/react'; // must go before plugins
import dayGridPlugin from '@fullcalendar/daygrid';
import listPlugin from '@fullcalendar/list';
import interactionPlugin, { Draggable } from '@fullcalendar/interaction';

import { Modal, lighten, Card, Container, Typography, CardContent, styled, Stack, Grid, useTheme, CircularProgress, Button, Menu, MenuItem, Autocomplete, TextField, LinearProgress, Tooltip } from '@mui/material';

import DraggableTemplateCard from 'src/components/molecules/DraggableTemplateCard/DraggableTemplateCard';
import { useEffect } from 'react';
import useAuth from 'src/hooks/useAuth';

import { useQuery } from '@tanstack/react-query';

import templateAPI from 'src/controllers/templates';
import scheduleAPI from 'src/controllers/schedule';
import TemplateSearchBar from 'src/components/molecules/TemplateSearchBar/TemplateSearchBar';
import { ArrowBackTwoTone, Clear, WarningTwoTone, SortTwoTone } from '@mui/icons-material';
import ExpandMoreTwoToneIcon from '@mui/icons-material/ExpandMoreTwoTone';
import Tag from "src/components/atoms/Tag";
import { compareAsc, isFuture } from 'date-fns';

import AlpacaCalendarDetailsModal from '../AlpacaCalendarDetailsModal/AlpacaCalendarDetailsModal';
import AlpacaCalendarScheduleModal from '../AlpacaCalendarScheduleModal/AlpacaCalendarScheduleModal';

const recentlyAddedCompare = (a, b) => {
  if (a.createdAt < b.createdAt) {
      return 1
  } else if (b.createdAt > a.createdAt) {
      return -1
  }
  return 0
}

const recentlyModifiedCompare = (a, b) => {
  if (a.updatedAt > b.updatedAt) {
      return -1
  } else if (b.updatedAt > a.updatedAt) {
      return 1
  }
  return 0
}

const alphabeticalTitleCompare = (a, b) => {
  if (a.name > b.name) {
      return 1
  } else if (b.name > a.name) {
      return -1
  }
  return 0
} 

const StyledWrapper = styled('div')(({theme}) => `
        .fc {
            height: calc(100vh - 66px - 60px - 32px - 62px);
            width: 100%;
        }
        .fc-day-past .fc-daygrid-event {
            background-color: ${theme.colors.alpha.black[30]};
        }
        .fc-day-past {
            background-color: ${theme.colors.alpha.black[10]};
        }
        td {
            background-color: ${theme.colors.alpha.white[100]};
        }
        .fc-col-header-cell {
            background-color: ${theme.colors.alpha.black[10]};
        }
        .fc-button {
            background-color: transparent;
            border: 1px solid transparent;
            color: ${theme.colors.primary['main']};
            border-radius: 6px;
            padding: 5px 6px !important;
        }
        .fc-button:hover {
            background-color: ${theme.colors.primary['lighter']};
            color: ${theme.colors.primary['main']};
            border: 1px solid transparent;
        }
        .fc-button:active {
            background-color: ${theme.colors.primary['dark']} !important;
            border: 1px solid ${theme.colors.primary['main']} !important;
        }
        .fc-button:focus {
            box-shadow: none !important;
        }
        .fc-button-active {
            background-color: transparent !important;
            border: 1px solid ${theme.colors.primary['main']} !important;
            color: ${theme.colors.primary['main']} !important;
        }
        .fc-button-active:hover {
            background-color: ${theme.colors.primary['lighter']} !important;
        }
        .fc-today-button:disabled {
            background-color: transparent;
            border: 1px solid ${theme.colors.primary['light']};
            color: ${theme.colors.primary['light']};
        }
        .fc-today-button:active {
            color: ${theme.colors.primary['main']};
        }
        .fc-today-button:hover {
            color: ${theme.colors.primary['main']};
            background-color: ${theme.colors.primary['lighter']} !important;
        }

        .fc-icon {  
            transform: translateY(-1px);
        }
        .fc-daygrid-event {
            background-color: ${theme.colors.primary['main']};
            color: ${theme.colors.alpha.trueWhite[100]};
            cursor: pointer;
        }
        .fc-daygrid-event-dot {
            border-color: ${theme.colors.alpha.trueWhite[100]};
        }
        .fc-day-today {
            background-color: ${theme.colors.alpha.trueWhite[100]} !important;
        }
        .fc-day-disabled {
            background-color: ${lighten(theme.colors.primary['lighter'], .3)} !important;
        }
        .fc-customprev-button {
            border: 0;
            font-family: Inter;
            font-weight: bold;
            border-radius: 999em;
            background-color: 0 !important;
        }
        .fc-customprev-button:hover {
            background-color: ${theme.colors.primary['lighter']} !important;
            border: 0 !important;
            color: ${theme.colors.primary['main']};
        }
        .fc-customprev-button:active {
            background-color: ${theme.colors.primary['light']} !important;
            border: 0 !important;
            color: ${theme.colors.alpha.white[100]} !important;
        }
        .fc-customprev-button:focus {
            box-shadow: none !important;
        }
        .fc-customnext-button {
            border: 0;
            font-family: Inter;
            font-weight: bold;
            border-radius: 999em;
            background-color: 0 !important;
        }
        .fc-customnext-button:hover {
            background-color: ${theme.colors.primary['lighter']} !important;
            border: 0 !important;
            color: ${theme.colors.primary['main']};
        }
        .fc-customnext-button:active {
            background-color: ${theme.colors.primary['light']} !important;
            border: 0 !important;
            color: ${theme.colors.alpha.white[100]} !important;
        }
        .fc-customnext-button:focus {
            box-shadow: none !important;
        }
        .fc-header-toolbar {
            margin-bottom: 1em !important;
        }
        .fc-day-today .fc-daygrid-day-number {
          color: ${theme.colors.primary['main']};
          font-weight: bold;
        }
        .recurring-event.fc-daygrid-event {
          background-color: white;
          border: 1px solid #1A75FF;
          color: #1A75FF;
          padding-top: 1px;
          padding-bottom: 1px;

          .fc-daygrid-event-dot {
            border-color: #1A75FF;
          } 
        } 
        .fc-day-past .recurring-event {
            background-color: transparent;
            color: ${theme.colors.alpha.black[50]} !important;
            border: 1px solid ${theme.colors.alpha.black[50]} !important;
            .fc-daygrid-event-dot {
                border-color: ${theme.colors.alpha.black[50]} !important;
            }
          }
          
        .fc-day-past .past-event {
            color: white;
            border: none;
            .fc-daygrid-event-dot {
                border-color: white;
            }
        }
        .fc-popover.fc-day-past {
            background-color: white;
        }
        .past-event {
            background-color: transparent;
            color: ${theme.colors.alpha.black[50]};
            border: 1px solid ${theme.colors.alpha.black[50]};
            .fc-daygrid-event-dot {
                border-color: ${theme.colors.alpha.black[30]};
            }
            padding-top: 1px;
            padding-bottom: 1px;  
        }
        .fc-highlight {
            border: 1px solid ${theme.colors.primary['main']};
            background-color: ${theme.colors.primary['lighter']};
        }
        `   
)

const RoundedSquare = styled('div')({
  borderRadius: 3,
  width: '14px',
  height: '14px',
})

const sortModes = [
  {
      name: "Alphabetical",
      id: "alphabeticalTitleCompare"
  },
  {
      name: "Recently created",
      id: "recentlyAddedCompare"
  },
  {
      name: "Recently modified",
      id: "recentlyModifiedCompare"
  },
];

const filterMap = {
  "alphabeticalTitleCompare": alphabeticalTitleCompare,
  "recentlyAddedCompare": recentlyAddedCompare,
  "recentlyModifiedCompare": recentlyModifiedCompare
}


export default function AlpacaCalendar(props) {
    const {
        filter,
        setFilter,
        detailsModalInfo,
        setDetailsModalInfo,
        setDetailsModalOpen,
        setScheduleModalContent,
        setScheduleModalOpen,
        scheduleModalOpen,
        scheduleModalContent,
        detailsModalOpen,
        calendarRef,
        fetchEvents,
        deleteEvent,

    } = props;

    const theme = useTheme();

    const [ cancelNotificationModalOpen, setCancelNotificationModalOpen ] = useState(false);
    const [ deleteNotificationModalOpen, setDeleteNotificationModalOpen ] = useState(false);
    const [ sortFilter, setSortFilter ] = useState({
        name: "Recently created",
        id: "addedCompare"
    });
    const [ sortMenuOpen, setSortFilterMenuOpen ] = useState(false);
    const [ tagFilter, setTagFilter ] = useState([]);
    const [ tagFilterInput, setTagFilterInput ] = useState('');
    const [ fetchingEvents, setFetchingEvents ] = useState(false);

    const [ anyFieldTouched, setAnyFieldTouched ] = useState(false);

    const viewStartBoundary = new Date().setDate(new Date().getDate() - 1);
    const actionRef1 = useRef<any>(null);
    const acc = useAuth();
    const { data, isLoading } = useQuery({
        queryKey: ['templateData'],
        queryFn: () => {
            return templateAPI.listTemplates(acc.user.domain)
                .then((res) => {
                    return res.templates.map((obj) => { 
                        return { 
                            ...obj.content, 
                            notifStyle: obj.style, 
                            name: obj.name, 
                            tags: obj.tags,
                            _id: obj._id,
                            stylingInfo: { icon: obj.icon, backgroundColor: obj.backgroundColor },
                            createdAt: obj.createdAt,
                            updatedAt: obj.updatedAt
                        } 
                    })
                })
            },
        placeholderData: []
    })

    const tagsQuery = useQuery(['tagsData'], () => {
      return templateAPI.listTags(acc.user.domain)
          .then((res) => {
              return res.tags
          })
      }, {
      placeholderData: []
    })

    const filteredData = useMemo(() => {
        const result = (filter === '' && tagFilter.length === 0) ?
            data.toSorted(filterMap[sortFilter.id])
        :
            data.filter((template) => {
                let nameMatch = template.name.toUpperCase().includes(filter.toUpperCase());
                if (filter.length < 1) { nameMatch = true }
                let tagMatch;
                if (tagFilter.length > 0) {
                    tagMatch = tagFilter.some((tag) => {
                        let result = false;
                        for (let i=0; i < template.tags.length; i++) {
                          if (template.tags[i]._id === tag._id) {
                            result = true;
                          }
                        }
                        return result;
                      })
                } else {
                    tagMatch = true;
                }

                return nameMatch && tagMatch
            }).toSorted(filterMap[sortFilter.id]);
        return result;
    }, [data, filter, sortFilter.id, tagFilter]);

    useEffect(() => {
        let draggableEl = document.getElementsByClassName('draggable-templates-container')[0] as HTMLElement
        new Draggable(draggableEl, {
            itemSelector: ".MuiCard-root",
            eventData: function(eventEl) {
              let title = eventEl.getAttribute("data-title");
              let name = eventEl.getAttribute("data-name");
              let subtitle = eventEl.getAttribute("data-subtitle");
              let tags = eventEl.getAttribute("data-tags");
              let body = eventEl.getAttribute("data-body");
              let notifStyle = eventEl.getAttribute("data-notif-style");
              let stylingInfo = JSON.parse(eventEl.getAttribute("data-styling-info"));
              return {
                details: {
                    title,
                    name,
                    body,
                    tags,
                    subtitle,
                    notifStyle,
                    targets: { groups: [], members: [] },
                    stylingInfo,
                },
                title: name,
              };
            }
          });
    }, [])

    return (
      <>
        <Modal
          open={scheduleModalOpen}
          onClose={() => {
            if (anyFieldTouched) {
                setCancelNotificationModalOpen(!cancelNotificationModalOpen);
            } else {
                setScheduleModalOpen(false);
            }
          }}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center'
          }}
        >
          <>
            <Suspense fallback={<CircularProgress />}>
              <AlpacaCalendarScheduleModal
                setScheduleModalOpen={setScheduleModalOpen}
                scheduleModalContent={scheduleModalContent}
                setCancelNotificationModalOpen={setCancelNotificationModalOpen}
                setAnyFieldTouched={setAnyFieldTouched}
                anyFieldTouched={anyFieldTouched}
              ></AlpacaCalendarScheduleModal>
            </Suspense>
          </>
        </Modal>
        <Modal
          open={detailsModalOpen}
          onClose={() => {
            if (anyFieldTouched) {
                setCancelNotificationModalOpen(!cancelNotificationModalOpen);
            } else {
                setDetailsModalOpen(false);
            }
          }}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center'
          }}
        >
          <>
              <AlpacaCalendarDetailsModal
                setDetailsModalOpen={setDetailsModalOpen}
                detailsModalInfo={detailsModalInfo}
                setDeleteNotificationModalOpen={setDeleteNotificationModalOpen}
                anyFieldTouched={anyFieldTouched}
                setAnyFieldTouched={setAnyFieldTouched}
                setCancelNotificationModalOpen={setCancelNotificationModalOpen}
                cancelNotificationModalOpen={cancelNotificationModalOpen}
              ></AlpacaCalendarDetailsModal>
          </>
        </Modal>
        <Modal
          open={cancelNotificationModalOpen}
          onClose={() => setCancelNotificationModalOpen(false)}
        >
          <Container maxWidth="xs" sx={{ mt: '15%' }}>
            <Card sx={{ p: 2 }}>
              <Typography variant="h4">
                You have unsaved changes
              </Typography>
              <Typography sx={{ mt: 0.5 }} variant="body1">
                Are you sure you want to discard changes to this notification?
                </Typography>
              <Stack
                sx={{ mt: 2 }}
                direction="row"
                justifyContent="space-between"
              >
                <Button
                  onClick={() => setCancelNotificationModalOpen(false)}
                  variant="outlined"
                  startIcon={<ArrowBackTwoTone></ArrowBackTwoTone>}
                >
                  Go back
                </Button>
                <Button
                  onClick={() => {
                    setCancelNotificationModalOpen(false);
                    setScheduleModalOpen(false);
                    setDetailsModalOpen(false);
                  }}
                  startIcon={<WarningTwoTone></WarningTwoTone>}
                  color="error"
                  variant="outlined"
                  sx={{ "&:hover": {backgroundColor: theme.colors.error['main'], color: theme.colors.alpha.trueWhite[100]} }}
                    >
                  Continue
                </Button>
              </Stack>
            </Card>
          </Container>
        </Modal>
        <Modal
          open={deleteNotificationModalOpen}
          onClose={() => setDeleteNotificationModalOpen(false)}
        >
          <Container maxWidth="xs" sx={{ mt: '15%' }}>
            <Card sx={{ p: 2 }}>
              <Typography variant="h4" >
                Are you sure you want to delete this notification?
              </Typography>
              <Typography sx={{ mt: 0.5 }} variant="body1">
                Deleting this notification is irreversible and any custom content added to this notification will be lost without editing the template.
              </Typography>
              <Stack
                sx={{ mt: 2 }}
                direction="row"
                justifyContent="space-between"
              >
                <Button
                  onClick={() => setDeleteNotificationModalOpen(false)}
                  variant="outlined"
                  startIcon={<ArrowBackTwoTone></ArrowBackTwoTone>}
                >
                  Go back
                </Button>
                <Button
                  onClick={() => {
                    deleteEvent.mutate(detailsModalInfo.id, {
                      onSuccess: () => {
                        setDeleteNotificationModalOpen(false);
                        setDetailsModalOpen(false);
                      }
                    })
                  }}
                  startIcon={<WarningTwoTone></WarningTwoTone>}
                  color="error"
                  variant="outlined"
                  sx={{ "&:hover": {backgroundColor: theme.colors.error['main'], color: theme.colors.alpha.trueWhite[100]} }}
                >
                  Delete
                </Button>
              </Stack>
            </Card>
          </Container>
        </Modal>
        <Grid
          container
          direction="row"
          spacing={1}
          justifyContent="space-between"
        >
          <Grid
            container
            item
            direction="column"
            sm={3}
            flexWrap="nowrap"
            sx={{ pr: 1 }}
          >
            <Card
              sx={{
                p: 2,
                top: '-18px',
                backgroundColor: `${theme.colors.alpha.trueWhite[100]}`,
                zIndex: 2,
                mb: 2
              }}
              variant="outlined"
            >
              <Button
                sx={{
                  borderRadius: '6px',
                  '&:hover': { borderWidth: '1px' }
                }}
                onClick={() => setSortFilterMenuOpen(true)}
                ref={actionRef1}
                value={sortFilter.id}
                variant="outlined"
                size="small"
                endIcon={<ExpandMoreTwoToneIcon fontSize="small" />}
                startIcon={<SortTwoTone></SortTwoTone>}
              >
                {sortFilter.name}
              </Button>
              <Menu
                anchorEl={actionRef1.current}
                onClose={() => setSortFilterMenuOpen(false)}
                open={sortMenuOpen}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'left'
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'left'
                }}
              >
                {sortModes.map((mode) => (
                  <MenuItem
                    key={mode.id}
                    onClick={() => {
                      setSortFilter(mode);
                      setSortFilterMenuOpen(false);
                    }}
                  >
                    {mode.name}
                  </MenuItem>
                ))}
              </Menu>
              <TemplateSearchBar
                style={{ marginTop: '8px' }}
                filter={filter}
                setFilter={setFilter}
              ></TemplateSearchBar>
              <Autocomplete
                sx={{ 
                    mt: 1,
                    "& .MuiAutocomplete-inputRoot": {
                        paddingRight: '45px !important'
                    },
                    "& .MuiAutocomplete-endAdornment": {
                        right: '4px !important',
                    }
                }}
                multiple
                size="small"
                forcePopupIcon={false}
                renderInput={(
                  params
                ) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    name="tags"
                    value={tagFilterInput}
                    onChange={(e) => setTagFilterInput(e.target.value)}
                    label={ (tagFilterInput.length === 0 && tagFilter.length === 0) ? 'Tags':'' }
                    InputLabelProps={{ shrink: false }} 
                  />
                )}
                getOptionLabel={(option: {
                  name: string;
                  color: string;
                  functional?: boolean;
                }) => option.name}
                getOptionDisabled={(option) =>
                  tagFilter.some((tag) => tag._id === option._id)
                }
                isOptionEqualToValue={(option, value) =>
                  option._id === value._id
                }
                onChange={(e, value) => { setTagFilter(value)}}
                value={tagFilter}
                renderTags={(tags: [{ _id: string, name: string, color: string }]) => {
                  return tags.map((tag, index) => (
                    <Tag
                      sx={{ mb: 0.25, mt: 0.25, mr: 0.5 }}
                      label={tag.name}
                      tagColor={tag.color}
                      key={index}
                      onDelete={() => {
                        setTagFilter(tagFilter.filter((tag) => tag._id !== tags[index]._id));
                      }}
                      deleteIcon={<Clear></Clear>}
                    ></Tag>
                  ));
                }}
                renderOption={(params, option) => {
                  return (
                    <li {...params}>
                      <RoundedSquare
                        style={{
                          backgroundColor: option.color,
                          marginRight: '5px'
                        }}
                      ></RoundedSquare>
                      {option.name}
                    </li>
                  );
                }}
                options={tagsQuery.data}
              />

            </Card>
            <Grid
              container
              item
              rowSpacing={1}
              className="draggable-templates-container"
              sx={{
                pr: '15px',
                pl: '15px',
                pb: '30px',
                ml: '-15px',
                width: 'calc(100% + 30px)',
                maxHeight: '67vh',
                overflowY: 'scroll',
                overflowX: 'visible',
                '&::-webkit-scrollbar': { display: 'none' },
                marginTop: '-32px',
                paddingTop: '15px'
              }}
            >
              {filteredData.map((template, index) => {
                return (
                  <Grid sx={{ width: '100%' }} key={index} item>
                    <DraggableTemplateCard
                        onClick={() => {  
                            const { title, name, body, tags, subtitle, notifStyle, stylingInfo } = template;
                            const event = {
                                details: {
                                    title,
                                    name,
                                    body,
                                    tags,
                                    subtitle,
                                    notifStyle,
                                    stylingInfo,
                                    targets: { groups: [], members: [] }
                                },

                            };
                            setScheduleModalContent({
                                date: new Date(),
                                ...event.details
                              });
                            setScheduleModalOpen(true);
                        }}
                      key={index}
                      className="draggable-template"
                      details={{
                        title: template.title,
                        subtitle: template.subtitle,
                        body: template.body,
                        name: template.name,
                        tags: template.tags,
                        notifStyle: template.notifStyle,
                        stylingInfo: template.stylingInfo,
                      }}
                    ></DraggableTemplateCard>
                  </Grid>
                );
              })}
            </Grid>
          </Grid>
          <Grid item sm={9} xl={9}>
            <Card variant="outlined">
              <CardContent sx={{ p: 2, pt: (fetchingEvents) ? '18px':'' }}>
                <StyledWrapper>
                {
                    fetchingEvents &&
                    <LinearProgress sx={{ position: 'relative', ml: '-18px', mt: '-18px', width: 'calc(100% + 36px)', mb: '12px'}} />
                }
                  <FullCalendar
                    defaultTimedEventDuration={0}
                    ref={calendarRef}
                    views={{
                        dayGridMonth: {
                            dayMaxEvents: 4 // adjust to 6 only for timeGridWeek/timeGridDay
                        }
                    }}
                    editable
                    droppable={true}
                    loading={(isLoading) => setFetchingEvents(isLoading)}
                    events={fetchEvents}
                    plugins={[dayGridPlugin, interactionPlugin, listPlugin]}
                    initialView="dayGridMonth"
                    customButtons={{
                      customprev: {
                        icon: 'chevron-left',
                        click: function () {
                          calendarRef.current.getApi().prev();
                        }
                      },
                      customnext: {
                        icon: 'chevron-right',
                        click: function () {
                          calendarRef.current.getApi().next();
                        }
                      }
                    }}
                    headerToolbar={{
                      start: 'customprev today customnext',
                      center: 'title',
                      end: 'dayGridMonth dayGridWeek listWeek'
                    }}
                    eventClick={({ event }) => {
                        if (isFuture(event.start)) {
                            setDetailsModalInfo({
                                date: event.start,
                                id: event.id,
                                ...event.extendedProps.details
                            });
                            setDetailsModalOpen(true);
                        }
                    }}
                    eventReceive={(info) => {
                      const { event } = info;
                      info.revert();
                      setScheduleModalContent({
                        date: event.start,
                        id: event.id,
                        ...event.extendedProps.details
                      });
                      setScheduleModalOpen(true);
                    }}
                    eventDrop={({ event, oldEvent, view }) => {
                        console.log(event)
                        scheduleAPI.rescheduleNotification(
                          acc.user.domain,
                          event.start,
                          event.id
                        )
                        .then(() => view.calendar.getEventSources()[0].refetch())
                    }}
                    eventConstraint={{ start: viewStartBoundary }}
                    eventClassNames={(arg) => {
                        const compareVal = compareAsc(arg.event.start, new Date());
                        if (compareVal === -1) {
                            return ['past-event']
                        }
                    }}
                  />
                </StyledWrapper>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </>
    );
}