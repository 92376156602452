import img_0 from './images/img_0.png';

const animation = {
    "v": "5.9.0",
    "fr": 29.9700012207031,
    "ip": 0,
    "op": 180.00000733155,
    "w": 1920,
    "h": 1080,
    "nm": "Loading Animation 1",
    "ddd": 0,
    "assets": [
        {
            "id": "image_0",
            "w": 1565,
            "h": 1710,
            "u": '',
            "p": img_0,
            "e": 0
        }
    ],
    "layers": [
        {
            "ddd": 0,
            "ind": 1,
            "ty": 4,
            "nm": "Shape Layer 1",
            "sr": 1,
            "ks": {
                "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 11
                },
                "r": {
                    "a": 0,
                    "k": 0,
                    "ix": 10
                },
                "p": {
                    "a": 0,
                    "k": [
                        960,
                        540,
                        0
                    ],
                    "ix": 2,
                    "l": 2
                },
                "a": {
                    "a": 0,
                    "k": [
                        0,
                        0,
                        0
                    ],
                    "ix": 1,
                    "l": 2
                },
                "s": {
                    "a": 0,
                    "k": [
                        100,
                        100,
                        100
                    ],
                    "ix": 6,
                    "l": 2
                }
            },
            "ao": 0,
            "shapes": [
                {
                    "ty": "gr",
                    "it": [
                        {
                            "ind": 0,
                            "ty": "sh",
                            "ix": 1,
                            "ks": {
                                "a": 0,
                                "k": {
                                    "i": [
                                        [
                                            0,
                                            0
                                        ],
                                        [
                                            64.004,
                                            0.731
                                        ],
                                        [
                                            0,
                                            0
                                        ],
                                        [
                                            0,
                                            0
                                        ],
                                        [
                                            0,
                                            0
                                        ],
                                        [
                                            -66.026,
                                            -0.718
                                        ],
                                        [
                                            0,
                                            0
                                        ],
                                        [
                                            0,
                                            60.033
                                        ]
                                    ],
                                    "o": [
                                        [
                                            0,
                                            0
                                        ],
                                        [
                                            -87.5,
                                            -1
                                        ],
                                        [
                                            0,
                                            0
                                        ],
                                        [
                                            0,
                                            0
                                        ],
                                        [
                                            0,
                                            0
                                        ],
                                        [
                                            92,
                                            1
                                        ],
                                        [
                                            0,
                                            0
                                        ],
                                        [
                                            0,
                                            -86
                                        ]
                                    ],
                                    "v": [
                                        [
                                            332,
                                            -442.5
                                        ],
                                        [
                                            -319.5,
                                            -442
                                        ],
                                        [
                                            -403.5,
                                            -359.5
                                        ],
                                        [
                                            -403,
                                            442
                                        ],
                                        [
                                            -310.5,
                                            363
                                        ],
                                        [
                                            310,
                                            364
                                        ],
                                        [
                                            403,
                                            284
                                        ],
                                        [
                                            403.5,
                                            -352
                                        ]
                                    ],
                                    "c": true
                                },
                                "ix": 2
                            },
                            "nm": "Path 1",
                            "mn": "ADBE Vector Shape - Group",
                            "hd": false
                        },
                        {
                            "ty": "st",
                            "c": {
                                "a": 0,
                                "k": [
                                    0.043137256056,
                                    0.815686285496,
                                    0.917647063732,
                                    1
                                ],
                                "ix": 3
                            },
                            "o": {
                                "a": 0,
                                "k": 100,
                                "ix": 4
                            },
                            "w": {
                                "a": 0,
                                "k": 40,
                                "ix": 5
                            },
                            "lc": 2,
                            "lj": 2,
                            "bm": 0,
                            "nm": "Stroke 1",
                            "mn": "ADBE Vector Graphic - Stroke",
                            "hd": false
                        },
                        {
                            "ty": "tr",
                            "p": {
                                "a": 0,
                                "k": [
                                    0,
                                    0
                                ],
                                "ix": 2
                            },
                            "a": {
                                "a": 0,
                                "k": [
                                    0,
                                    0
                                ],
                                "ix": 1
                            },
                            "s": {
                                "a": 0,
                                "k": [
                                    100,
                                    100
                                ],
                                "ix": 3
                            },
                            "r": {
                                "a": 0,
                                "k": 0,
                                "ix": 6
                            },
                            "o": {
                                "a": 0,
                                "k": 100,
                                "ix": 7
                            },
                            "sk": {
                                "a": 0,
                                "k": 0,
                                "ix": 4
                            },
                            "sa": {
                                "a": 0,
                                "k": 0,
                                "ix": 5
                            },
                            "nm": "Transform"
                        }
                    ],
                    "nm": "Shape 1",
                    "np": 2,
                    "cix": 2,
                    "bm": 0,
                    "ix": 1,
                    "mn": "ADBE Vector Group",
                    "hd": false
                },
                {
                    "ty": "tm",
                    "s": {
                        "a": 1,
                        "k": [
                            {
                                "i": {
                                    "x": [
                                        0.833
                                    ],
                                    "y": [
                                        0.833
                                    ]
                                },
                                "o": {
                                    "x": [
                                        0.167
                                    ],
                                    "y": [
                                        0.167
                                    ]
                                },
                                "t": 0,
                                "s": [
                                    100
                                ]
                            },
                            {
                                "i": {
                                    "x": [
                                        0.833
                                    ],
                                    "y": [
                                        0.833
                                    ]
                                },
                                "o": {
                                    "x": [
                                        0.167
                                    ],
                                    "y": [
                                        0.167
                                    ]
                                },
                                "t": 12,
                                "s": [
                                    71
                                ]
                            },
                            {
                                "t": 171.000006964973,
                                "s": [
                                    0
                                ]
                            }
                        ],
                        "ix": 1
                    },
                    "e": {
                        "a": 0,
                        "k": 100,
                        "ix": 2
                    },
                    "o": {
                        "a": 1,
                        "k": [
                            {
                                "i": {
                                    "x": [
                                        0.833
                                    ],
                                    "y": [
                                        0.833
                                    ]
                                },
                                "o": {
                                    "x": [
                                        0.167
                                    ],
                                    "y": [
                                        0.167
                                    ]
                                },
                                "t": 12,
                                "s": [
                                    177
                                ]
                            },
                            {
                                "t": 167.000006802049,
                                "s": [
                                    -903
                                ]
                            }
                        ],
                        "ix": 3
                    },
                    "m": 1,
                    "ix": 2,
                    "nm": "Trim Paths 1",
                    "mn": "ADBE Vector Filter - Trim",
                    "hd": false
                }
            ],
            "ip": 0,
            "op": 960.000039101602,
            "st": 0,
            "bm": 0
        },
        {
            "ddd": 0,
            "ind": 2,
            "ty": 2,
            "nm": "Layer 2/Icon.ai",
            "cl": "ai",
            "refId": "image_0",
            "sr": 1,
            "ks": {
                "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 11
                },
                "r": {
                    "a": 0,
                    "k": 0,
                    "ix": 10
                },
                "p": {
                    "a": 0,
                    "k": [
                        960,
                        540,
                        0
                    ],
                    "ix": 2,
                    "l": 2
                },
                "a": {
                    "a": 0,
                    "k": [
                        782.5,
                        855,
                        0
                    ],
                    "ix": 1,
                    "l": 2
                },
                "s": {
                    "a": 0,
                    "k": [
                        54,
                        54,
                        100
                    ],
                    "ix": 6,
                    "l": 2
                }
            },
            "ao": 0,
            "ip": 0,
            "op": 960.000039101602,
            "st": 0,
            "bm": 0
        }
    ],
    "markers": []
}

export default animation;