import React, { useState, useLayoutEffect, useCallback } from 'react';

import {
    Card,
    Typography,
    CardContent,
    Stack, 
    Grid,
    Tooltip,
    styled,
    SvgIcon,
    LinearProgress,
    Snackbar,
    Alert,
} from '@mui/material';

import IconButton, { IconButtonProps } from '@mui/material/IconButton';

import LogoIncludedIcon from 'src/components/atoms/LogoIncludedIcon/LogoIncludedIcon';
import LogoNotIncludedIcon from 'src/components/atoms/LogoNotIncludedIcon/LogoNotIncludedIcon';

import { useTheme } from '@mui/material/styles';

import { notifStyleIcon } from 'src/constants/iconConstants';
import { LoadingButton } from '@mui/lab';

interface ExpandMoreProps extends IconButtonProps {
    expand: boolean;
}   

const ExpandMore = styled((props: ExpandMoreProps) => {
    const { expand, ...other } = props;
    return <IconButton {...other} />;
    })(({ theme, expand }) => ({
        transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
        marginLeft: 'auto',
        transition: theme.transitions.create('transform', {
        duration: theme.transitions.duration.shortest,
    }),
}));

interface details {
    notifStyle: string,
    name: string,
    title: string,
    subtitle: string,
    body: string,
    tags: [{ name: string, color: string, id: string | number, functional?: boolean }], 
    recurrence: string,
    logo: boolean,
    icon: boolean
}

export default function QuickSendCard({ details, sendPresetMutation, id, ...otherProps }) {
    const theme = useTheme();
    const [expanded, setExpanded] = React.useState(false);
    const ref = React.useRef<HTMLInputElement>(null);

    const [ sendInProgress, setSendInProgress ] = useState(false);
    const [ sendSuccess, setSendSuccess ] = useState(false);
    const [ showOverflowText, setShowOverflowText ] = useState(false)

    const handleQuickSend = useCallback(() => {
        setSendInProgress(true);
        sendPresetMutation.mutate({templateID: id}, { onMutate: () => { setSendInProgress(true) }, onSuccess: () => { setSendInProgress(false); setSendSuccess(true); setTimeout(() => setSendSuccess(false), 6000) }})
    }, [sendPresetMutation, id])

    useLayoutEffect(() => {
      if (ref.current && ((46 < ref.current.scrollHeight))) {
          setShowOverflowText(true);
      } else {
          setShowOverflowText(false);
      }
    }, [details.tags]);

    return (
      <>
        <Snackbar
            anchorOrigin={{ horizontal: 'center', vertical: 'bottom' }}
            color="success"
            open={sendSuccess}
            autoHideDuration={6000}
            onClose={() => setSendSuccess(false)}
        >
              <Alert severity="success" sx={{ width: '100%' }}>
                Successfully sent your quick send preset to the predefined recipients list
            </Alert>
        </Snackbar>
        <Card
            draggable={ otherProps.draggable }
            data-title={ details.title }
            data-name={ details.name }
            sx={{
            width: '100%',
            cursor: 'pointer',
            pb: 1.5,
            mb: 2,
            minHeight: '110px'
            }}
            variant="outlined"
        >
          <Grid
            container
            direction="row"
            sx={{ justifyContent: 'space-between', display: 'flex' }}
          >
            <Grid
              item
              container
              direction="column"
              xs={10}
              sx={{
                padding: '14px',
                pr: 0,
                textAlign: 'right',
                lineHeight: '16px',
                flex: 1,
                pb: 0
              }}
              justifyContent="space-between"
            >
              <CardContent
                sx={{
                  p: 0,
                  width: "100%", 
                  minHeight: "45px",
                  pb: '0 !important'
                }}
              >
                <Grid item sx={{
                  pb: 0
                }}>
                <Stack
                    direction={'row'}
                    alignItems="center"
                    justifyContent="flex-start"
                    spacing={'4px'}
                >
                { notifStyleIcon(details.notifStyle) }
                <Typography variant="h4" sx={{
                    textOverflow: 'ellipsis',
                    whiteSpace: 'nowrap',
                    textAlign: 'left', 
                    mt: '.125em',
                    width: '100%',
                    overflow: 'hidden',
                    fontSize: '16px',
                    fontWeight: 500,
                    color: theme.colors.primary['main']
                }}>
                    {details.name}
                </Typography>
                </Stack>
                </Grid>
                <Typography
                  sx={{
                      textOverflow: 'ellipsis',
                      whiteSpace: 'nowrap',
                      textAlign: 'left', 
                      mt: '.5em',
                      width: '100%',
                      overflow: 'hidden',
                      fontSize: '15px'
                  }}
                  variant="h4"
                  >
                  {details.title}
                </Typography>
                <Typography
                  sx={{
                      textOverflow: 'ellipsis',
                      whiteSpace: 'nowrap',
                      textAlign: 'left', 
                      width: '100%',
                      overflow: 'hidden',
                      fontSize: '15px'
                  }}
                  variant="subtitle2"
                  >
                  {details.subtitle}
                </Typography>
              </CardContent>
            </Grid>

            <Grid
              item
              container
              sx={{
                height: '100%',
                padding: 2,
                pt: 1,
                pb: 0,
                textAlign: 'right',
                lineHeight: '16px',
              }}
              alignItems="center"
              alignContent="center"
              alignSelf="flex-end"
              spacing={2}
            >
                <Grid xs={8} item >
                    <LinearProgress variant={(sendInProgress) ? "indeterminate":"determinate"} color={(sendSuccess) ? "success":"secondary"} value={(sendSuccess) ? 100:0} />
                </Grid>
                <Grid xs={4} item>
                    <LoadingButton loading={sendInProgress} fullWidth variant="contained" onClick={handleQuickSend}>Send</LoadingButton>
                </Grid>
            </Grid>
          </Grid>
        </Card>
      </>
    );
}

