import { Button, Chip, CircularProgress, Container, Divider, FormControlLabel, Grid, InputAdornment, List, ListItem, ListItemButton, ListItemText, Paper, Radio, RadioGroup, Stack, TextField, Typography, styled, useTheme } from "@mui/material";
import GoogleIcon from '@mui/icons-material/Google';
import { Add, Construction, Download, Remove, SearchTwoTone } from "@mui/icons-material";
import { UseMutationResult, useMutation, useQuery } from "@tanstack/react-query";
import googleAPI from "src/controllers/googleFunctions";
import { useCallback, useMemo, useState } from "react";
import { LoadingButton } from "@mui/lab";
import { DataGrid, GridColDef } from "@mui/x-data-grid";

const ListItemStyled = styled(ListItem)(({ theme }) => ({
    paddingLeft: '12px',
    borderBottom: `1px solid ${theme.colors.alpha.black[10]}`,
    width: '100%'
}))

const DataGridStyled = styled(DataGrid)(({ theme }) => ({
    "& .MuiDataGrid-columnHeaders": {
        backgroundColor: `${theme.colors.alpha.black[5]}`,
        padding: 0,
        height: '40px',
        minHeight: '40px !important',
    },
    "& .MuiDataGrid-columnHeader": {
        height: '40px',
        textTransform: 'uppercase'
    },
    "& .MuiDataGrid-root .MuiDataGrid-columnHeader:focus, &.MuiDataGrid-root .MuiDataGrid-cell:focus": {
        outline: "none !important",
    },
    "& .MuiDataGrid-root .MuiDataGrid-columnHeader:focus, &.MuiDataGrid-root .MuiDataGrid-cell:focus-within": {
        outline: "none !important",
    },
}))

export default function OnboardingStepTwo({ setStep, ...otherProps }) {
    const theme = useTheme();

    const [filter, setFilter] = useState('');
    const [searchBarTimeout, setSearchBarTimeout] = useState(null);
    const [searchResults, setSearchResults] = useState([]);
    const [searchIsLoading, setSearchIsLoading] = useState(false);
    const [groupsToExclude, setGroupsToExclude] = useState([]);
    const [importAllUsers, setImportAllUsers] = useState('all');
    const [groupsHaveBeenImported, setGroupsHaveBeenImported] = useState(false)

    const groupsStateLookup = useMemo(() => {
        const groupStateLookup = {}
        groupsToExclude.forEach((id) => {
            groupStateLookup[id] = true;
        })
        return groupStateLookup
    }, [groupsToExclude])

    const handleExclude = useCallback((id) => {
        const indexOfId = groupsToExclude.indexOf(id);
        if (indexOfId === -1) {
            setGroupsToExclude(groupsToExclude.concat([id]))
        } else {
            setGroupsToExclude(groupsToExclude.filter((value) => value !== id))
        }
    }, [groupsToExclude])

    const columns: GridColDef[] = useMemo(() => [
        {
            field: 'name',
            headerName: 'Name',
            width: 150,
            flex: 1
        },
        { field: 'id', headerName: 'ID', width: 150, flex: 1 },
        {
            field: 'description',
            headerName: "Description",
            flex: 1,
            sortable: false,
        },
        {
            field: 'directMembersCount',
            headerName: 'Users',
            type: 'number',
            width: 80,
            align: 'left',
            headerAlign: 'left',
        },
        {
            field: "state",
            headerName: 'Excluded',
            sortable: false,
            flex: 1,
            align: 'center',
            headerAlign: 'center',
            width: 100,
            renderCell: (params) => {
                const excluded = groupsStateLookup[params.id]
                return <Chip color={excluded ? 'error' : 'success'} label={(excluded) ? "Excluded" : "Included"}></Chip>;
            }
        },
        {
            field: 'action',
            headerName: 'action',
            align: 'center',
            headerAlign: 'center',
            flex: 1,
            sortable: false,
            renderCell: (params) => {
                const excluded = groupsStateLookup[params.id]
                return <Button variant="outlined" size="small" color={(excluded) ? 'success' : 'error'} onClick={() => handleExclude(params.id)}>{(excluded) ? "Include Group" : "Exclude group"}</Button>;
            }
        }
    ], [handleExclude, groupsStateLookup]);

    const searchGroupsMutation: UseMutationResult = useMutation({
        mutationKey: ['searchGroupsList'],
        mutationFn: ({ filter }: { filter: string }) => {
            if (filter.length>0) {
                setSearchIsLoading(true)
                return googleAPI.searchDomainGroups(filter)
                .then((res) => {
                    setSearchResults(res);
                    setSearchIsLoading(false);
                    return res;
                })
            } else {
                return []
            }

        },
    })

    const [nextPageToken, setNextPageToken] = useState(null);
    const [lastPage, setLastPage] = useState(null);

    const [paginationModel, setPaginationModel] = useState({ page: 0, pageSize: 10 })

    const listGroupsQuery = useQuery({
        queryKey: ['importGroupsList', paginationModel.page],
        staleTime:Infinity,
        queryFn: () => {
            return googleAPI.listDomainGroups(nextPageToken)
                .then((res) => {
                    if (res) {
                        if (res.nextPageToken) {
                            setNextPageToken(res.nextPageToken)
                        } else {
                            setLastPage(paginationModel.page);
                        }
                        return res.groups;
                    } else {
                        return []
                    }
                })
        },
        keepPreviousData: true,
        placeholderData: [],
    })

    const importUsersMutation = useMutation(["importUsers"], {
        mutationFn: () => {
            return googleAPI.importGoogleOrganization();
        },
        onSuccess: () => {
            setGroupsHaveBeenImported(true)
        },
        onError: () => {
            setGroupsHaveBeenImported(false)
        }
    })

    const importAllGroupsMutation = useMutation<any>(["importAllGroups"], {
        mutationFn: () => {
            return googleAPI.importGoogleGroups();
        },
        onSuccess: () => {
            setGroupsHaveBeenImported(true)
        },
        onError: () => {
            setGroupsHaveBeenImported(false)
        }
    })

    const handleSubmit = useCallback(() => {
        if (importAllUsers === 'all') {
            googleAPI.excludeGroups([])
                .then((res) => {
                    setStep(2);
                    window.history.pushState(null, "Alpaca Notify", "/welcome#2");
                })
        } else {
            googleAPI.excludeGroups(groupsToExclude)
                .then((res) => {
                    setStep(2);
                    window.history.pushState(null, "Alpaca Notify", "/welcome#2");
                })
        }
    }, [importAllUsers, groupsToExclude, setStep])

    const usersAreLoading = useMemo(() => {
        if (groupsHaveBeenImported === false) {
            return true;
        }
        return (listGroupsQuery.data && listGroupsQuery.data.length === 0 && searchResults.length === 0)
    }, [listGroupsQuery.data, searchResults.length, groupsHaveBeenImported])

    const dataToDisplay = useMemo(() => {
        if (filter.length > 0 && searchGroupsMutation.data) {
            return searchGroupsMutation.data
        } else {
            return listGroupsQuery.data
        }
    }, [filter, searchGroupsMutation, listGroupsQuery])

    return (
        <>
            <Container maxWidth="md" sx={{ display: 'flex', justifyContent: 'center', paddingBottom: 9 }}>
                <Grid container direction="column">
                    <Grid item sx={{ textAlign: 'center' }}>
                        <Typography variant="h2">
                            Import your Google Users and Groups
                        </Typography>
                    </Grid>
                    <Grid item sx={{ textAlign: 'center', mt: 1 }}>
                        <Typography variant="subtitle2">
                            Alpaca Notify uses your existing Google groups and users to facilitate the sending of your notifications.
                        </Typography>
                    </Grid>
                    <Grid item sx={{ mt: 3, justifyContent: "center", display: 'flex' }}>
                        <LoadingButton loading={importUsersMutation.isLoading || importAllGroupsMutation.isLoading} onClick={() => { importUsersMutation.mutate(); importAllGroupsMutation.mutate(); }} sx={{ backgroundColor: theme.palette.background.paper }} variant="outlined" startIcon={<GoogleIcon></GoogleIcon>}>Import Google Users & Groups</LoadingButton>
                    </Grid>
                    {groupsHaveBeenImported &&
                        <>
                            <Divider sx={{ mt: 4, mb: 4 }}></Divider>
                            <Typography variant="h2">Select groups to sync with Alpaca Notify</Typography>
                            <RadioGroup sx={{ mt: '8px' }} value={importAllUsers} onChange={(e, val) => { setImportAllUsers(val) }}>
                                <FormControlLabel value="all" control={<Radio sx={{ pt: '6px', pb: '6px' }} />} label="Import all users" />
                                <FormControlLabel value="some" control={<Radio sx={{ pt: '6px', pb: '6px' }} />} label="Import users from selected groups" />
                            </RadioGroup>
                            {importAllUsers === 'some' &&
                                <Paper sx={{ p: 3, mt: 2 }}>
                                    <Stack direction="row" spacing={1} sx={{ mb: 2 }}>
                                        <Typography sx={{ fontSize: '38px' }} variant="h2">
                                            {(importAllGroupsMutation.data && importAllGroupsMutation.data.data.length) || 0}
                                        </Typography>
                                        <Typography variant="subtitle2">
                                            Groups<br /> imported
                                        </Typography>
                                        <Divider orientation="vertical" sx={{ ml: '24px !important', mr: '12px !important' }}></Divider>
                                        <Typography sx={{ fontSize: '38px' }} variant="h2">
                                            {groupsToExclude.length || 0}
                                        </Typography>
                                        <Typography variant="subtitle2">
                                            Groups<br /> excluded
                                        </Typography>
                                    </Stack>
                                    <TextField
                                        {...otherProps}
                                        label={filter ? ' ' : 'Type your search terms here...'}
                                        fullWidth
                                        variant="outlined"
                                        sx={{
                                            borderRadius: '8px',
                                            borderWidth: '8px',
                                            mb: 2
                                        }}
                                        value={filter}
                                        InputLabelProps={{
                                            shrink: false,
                                            sx: {
                                                pl: 3.5
                                            }
                                        }}
                                        onChange={(e) => {
                                            setFilter(e.target.value);
                                            clearTimeout(searchBarTimeout);
                                            const newTimer = setTimeout(() => {
                                                searchGroupsMutation.mutate({ filter: e.target.value });
                                            }, 400);
                                            setSearchBarTimeout(newTimer);
                                        }}
                                        InputProps={{
                                            sx: {},
                                            startAdornment: (
                                                <InputAdornment position="start">
                                                    <SearchTwoTone color="primary"></SearchTwoTone>
                                                </InputAdornment>
                                            ),
                                            endAdornment: searchIsLoading && (
                                                <CircularProgress size="28px"></CircularProgress>
                                            )
                                        }}
                                    />
                                    <Paper sx={{
                                        minHeight: '300px',
                                        overflow: 'scroll',
                                    }} >
                                        <DataGridStyled 
                                            onPaginationModelChange={(newPaginationModel => { if (lastPage === null || newPaginationModel.page <= lastPage) {setPaginationModel({ page: newPaginationModel.page, pageSize: 10 })} })} 
                                            paginationMode="server"
                                            paginationModel={paginationModel}
                                            disableColumnMenu={true}
                                            disableRowSelectionOnClick
                                            hideFooterPagination={filter.length >0}
                                            rows={dataToDisplay} 
                                            columns={columns}
                                            pageSizeOptions={[10]}
                                            rowCount={9999999999}
                                            loading={listGroupsQuery.isFetching || searchIsLoading}
                                            sx={{
                                              ".MuiTablePagination-displayedRows": {
                                                display: "none",
                                              },
                                              "minHeight": "300px"
                                            }}
                                        ></DataGridStyled>
                                    </Paper>
                                </Paper>
                            }
                        </>
                    }
                </Grid>
            </Container>
            <Grid item justifySelf="flex-end" sx={{ position: "fixed", bottom: 0 }} xs={12}>
                <Paper variant="outlined" sx={{
                    height: '64px',
                    width: "100svw",
                    paddingLeft: '20svw',
                    paddingRight: '20svw',
                    display: 'flex',
                    justifyContent: 'space-between',
                    paddingTop: '12px',
                    paddingBottom: '12px',
                    borderRadius: 0,
                }}>
                    <Button sx={{ width: '128px' }} variant="contained" disabled >Back</Button>
                    <Button sx={{ width: '128px' }} disabled={!groupsHaveBeenImported} variant="contained" onClick={() => { handleSubmit(); }}>Next</Button>
                </Paper>
            </Grid>
        </>
    )
}