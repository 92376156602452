import { Button, Container, Divider, Grid, Paper, Typography } from "@mui/material";
import Link from '@mui/material/Link';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';

export default function OnboardingStepThree({ setStep, ...otherProps }) {
    return (
    <>
        <Container maxWidth="md" sx={{ pb: 12}}>
            <Typography variant="h2">
                Install the Alpaca Extension
            </Typography>
            <Typography sx={{mb: 1, mt: .5}} variant="subtitle2">
            Alpaca Notify works by sending notifications to Chromebooks using the Alpaca Notify extension. Before you can start sending out notifications, you will need to deploy the Alpaca Notify extension to your organization's Chromebooks. Let's help you get this set in a few easy steps!
            </Typography >
            <Link href="mailto:support@alpacanotify.com">Something's not making sense? Ask for help here. <ArrowForwardIcon sx={{ fontSize: '16px', transform: 'translateY(2px)' }}></ArrowForwardIcon></Link>
            <Divider sx={{ mt: 3, mb: 3 }}></Divider>
            <ol style={{ display: "grid", gap: "24px"}}>
            <Typography variant="h4">
                <li>
            Ensure that you’re logged into Google Chrome with an account from your Google workspace
                </li>
            </Typography>
                <ol type="a">
                    <li>
                    The email should end in something other than @gmail.com, like @alpacanotify.com
                    </li>
                </ol>
            <Typography variant="h4">
                <li>
                Navigate to the extension’s Chrome Webstore listing using the following link: <br></br>
                <Typography variant="body1">
                <a href="https://chrome.google.com/webstore/detail/fkgghccfcindgcofbkpcfaphiekepahl/preview?hl=en&authuser=1">https://chrome.google.com/webstore/detail/fkgghccfcindgcofbkpcfaphiekepahl/preview?hl=en&authuser=1</a>
                </Typography>
                </li>
            </Typography>
            
            <Typography variant="h4">
                <li>
                Press “Add to Chrome”, after which a dialog will pop up. Press the “Add extension” button on that dialog
                </li>
            </Typography>
            <img src="/static/images/screenshots/chrome-page.png" height="350"></img>
            <img src="/static/images/screenshots/chrome-install-dialog.png" height="350"></img>

            <Typography variant="h4">
                <li>
                Close all Google Chrome windows, and then relaunch Google Chrome, making sure to select the correct profile if you have multiple profiles on your Google Chrome installation. 
                </li>
            </Typography>
            <Typography variant="h4">
                <li>
                At this point, you should be able to receive notifications on your computer. To test, go to the “Quick Compose” page on the Alpaca Notify console and send yourself a notification. 
                </li>
            </Typography>
            </ol>
        </Container>
            <Grid item justifySelf="flex-end" sx={{ position: "fixed", bottom: 0 }} xs={12}>
                <Paper variant="outlined" sx={{
                    height: '64px',
                    width: "100svw",
                    paddingLeft: '20svw',
                    paddingRight: '20svw',
                    display: 'flex',
                    justifyContent: 'space-between',
                    paddingTop: '12px',
                    paddingBottom: '12px',
                    borderRadius: 0,
                }}>
                    <Button sx={{ width: '128px' }} variant="contained" onClick={() => { window.history.pushState(null, "Alpaca Notify", "/welcome#1"); setStep(1)}}>Back</Button>
                    <Button sx={{ width: '128px' }} variant="contained" onClick={() => { window.history.pushState(null, "Alpaca Notify", "/welcome#3"); setStep(3)}}>Next</Button>
                </Paper>
            </Grid>
    </>
    )
}