import {
  FC,
  ChangeEvent,
  MouseEvent,
  SyntheticEvent,
  useState,
  ReactElement,
  Ref,
  forwardRef,
  useEffect
} from 'react';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import {
  Avatar,
  Box,
  Card,
  Checkbox,
  Grid,
  Slide,
  Divider,
  Tooltip,
  IconButton,
  InputAdornment,
  Link,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableContainer,
  TableRow,
  ToggleButton,
  ToggleButtonGroup,
  Tab,
  Tabs,
  TextField,
  Button,
  Typography,
  Dialog,
  Zoom,
  styled,
  Modal,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { TransitionProps } from '@mui/material/transitions';

import CloseIcon from '@mui/icons-material/Close';
import AddIcon from '@mui/icons-material/Add';
import ImportExportTwoToneIcon from '@mui/icons-material/ImportExportTwoTone';

import type { User, UserRole } from 'src/models/user';
import { useTranslation } from 'react-i18next';

import { useTheme } from '@mui/material/styles';
import useAuth from 'src/hooks/useAuth';
import { useNavigate } from 'react-router';

import clsx from 'clsx';
import LaunchTwoToneIcon from '@mui/icons-material/LaunchTwoTone';
import Label from 'src/components/Label';
import BulkActions from './BulkActions';
import SearchTwoToneIcon from '@mui/icons-material/SearchTwoTone';
import GridViewTwoToneIcon from '@mui/icons-material/GridViewTwoTone';
import TableRowsTwoToneIcon from '@mui/icons-material/TableRowsTwoTone';
import DeleteTwoToneIcon from '@mui/icons-material/DeleteTwoTone';
import { useSnackbar } from 'notistack';
import MoreVertTwoToneIcon from '@mui/icons-material/MoreVertTwoTone';
import { Groups } from '@mui/icons-material';

import { Formik } from 'formik'

import organizationAPI from 'src/controllers/organizations';
import googleAPI from '../../../controllers/googleFunctions';

const DialogWrapper = styled(Dialog)(
  () => `
      .MuiDialog-paper {
        overflow: visible;
      }
`
);

const AvatarError = styled(Avatar)(
  ({ theme }) => `
      background-color: ${theme.colors.error.lighter};
      color: ${theme.colors.error.main};
      width: ${theme.spacing(12)};
      height: ${theme.spacing(12)};

      .MuiSvgIcon-root {
        font-size: ${theme.typography.pxToRem(45)};
      }
`
);

const CardWrapper = styled(Card)(
  ({ theme }) => `

  position: relative;
  overflow: visible;

  &::after {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    border-radius: inherit;
    z-index: 1;
    transition: ${theme.transitions.create(['box-shadow'])};
  }
      
    &.Mui-selected::after {
      box-shadow: 0 0 0 3px ${theme.colors.primary.main};
    }
  `
);

const ButtonError = styled(Button)(
  ({ theme }) => `
     background: ${theme.colors.error.main};
     color: ${theme.palette.error.contrastText};

     &:hover {
        background: ${theme.colors.error.dark};
     }
    `
);

const TabsWrapper = styled(Tabs)(
  ({ theme }) => `
      .MuiTabs-scrollableX {
        overflow-x: auto !important;
      }

      .MuiTabs-indicator {
          box-shadow: none;
      }
    `
);

const modalStyle = {
  p: 4,
  display: "flex",
  alignItems: "center"
};

interface ResultsProps {
  users: User[],
  setSelectedUsers: Function,
  selectedItems: string[],
  submitGroupChange: Function,
  setGroupSelected: Function,
  groupSelected: any,
  isSubmittingGroupChange: boolean,
  submitGroupRemove: Function,
  handleImportGoogleUsers: (event: React.MouseEvent<HTMLInputElement>) => void,
  tabs: Array<any>,
  getUsers: Function,
  limit: any,
  setLimit: Function,
  totalCount: number,
  setTotalCount: Function,
}

interface Filters {
  role?: UserRole;
  group?: any;
}

const Transition = forwardRef(function Transition(
  props: TransitionProps & { children: ReactElement<any, any> },
  ref: Ref<unknown>
) {
  return <Slide direction="down" ref={ref} {...props} />;
});

type Color = 'error' | 'info' | 'warning' | 'success' | 'secondary' | 'primary';

const applyFilters = (
  users: User[],
  query: string,
  filters: Filters
): User[] => {
  return users.filter((user) => {
    let matches = true;
    let containsQuery = false;

    if (query.length !== 0) {

      const properties = ['email', 'name', 'id'];

      properties.forEach((property) => {
        if (user[property].toLowerCase().includes(query.toLowerCase())) {
          containsQuery = true;
        }
      });


      user['groups'].forEach((i) => {
        if (i.name.toLowerCase().includes(query.toLowerCase())) {
          containsQuery = true;
        }
      })
      if (!containsQuery) {
        matches = false;
      }
    }

    Object.keys(filters).forEach((key) => {
      const value = filters[key];

      if (key === 'group' && filters[key] != null) {
        if (user['groups'].length < 1) {
          matches = false;
        } else {
          for (let i=0; i<user['groups'].length; i++ ) {
            const groupObj = user['groups'][i];
            if (groupObj.id === value) {
              matches = true;
              break;
            } else if (!containsQuery) {
              matches = false;
            }
          }
        }
      } else {
        if (value && user[key] !== value) {
          matches = false;
        }
      }
    });

    return matches;
  });
};

const applyPagination = (
  users: User[],
  page: number,
  limit: number
): User[] => {
  return users.slice(page * limit, page * limit + limit);
};

const Results: FC<ResultsProps> = ({
   users, 
   setSelectedUsers, 
   selectedItems, 
   submitGroupChange,
   setGroupSelected,
   groupSelected,
   isSubmittingGroupChange,
   submitGroupRemove,
   handleImportGoogleUsers,
   tabs,
   getUsers,
   limit,
   setLimit,
   totalCount,
   setTotalCount
}) => {
  const { t }: { t: any } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const location = useLocation();
  const theme = useTheme();
  const { user } = useAuth();
  const navigate = useNavigate();
  const [page, setPage] = useState<number>(0);
  const [query, setQuery] = useState<string>('');
  const [filters, setFilters] = useState<Filters>({
    group: null
  });
  const [ selectedUsersInGroup, setSelectedUsersInGroup ] = useState<number>(0);

  const [ createGroupOpen, setCreateGroupOpen ] = useState<boolean>(false);

  const handleCloseCreateGroupMenu = () => {
    setCreateGroupOpen(false)
  }

  const handleTabsChange = (_event: SyntheticEvent, tabsValue: unknown) => {
    let value = null;

    if (tabsValue !== 'all') {
      value = tabsValue;
    }

    setFilters((prevFilters) => ({
      ...prevFilters,
      group: value
    }));

    setSelectedUsers([]);
  };

  const handleQueryChange = (event: ChangeEvent<HTMLInputElement>): void => {
    event.persist();
    setQuery(event.target.value);
  };

  const handleSelectAllUsers = (event: ChangeEvent<HTMLInputElement>): void => {
    setSelectedUsers(event.target.checked ? users.map((user) => user.email) : []);
  };

  const handleSelectOneUser = (
    _event: ChangeEvent<HTMLInputElement>,
    userEmail: string
  ): void => {
    if (!selectedItems.includes(userEmail)) {
      setSelectedUsers((prevSelected) => [...prevSelected, userEmail]);
    } else {
      setSelectedUsers((prevSelected) =>
        prevSelected.filter((email) => email !== userEmail)
      );
    }
  };

  const handlePageChange = (_event: any, newPage: number): void => {
    getUsers()
    setPage(newPage);
  };

  const handleLimitChange = (event: ChangeEvent<HTMLInputElement>): void => {
    setLimit(parseInt(event.target.value));
  };

  const filteredUsers = applyFilters(users, query, filters);
  const paginatedUsers = applyPagination(filteredUsers, page, limit);
  const selectedBulkActions = selectedItems.length > 0;
  const selectedSomeUsers =
    selectedItems.length > 0 && selectedItems.length < users.length;
  const selectedAllUsers = selectedItems.length === users.length;

  const [toggleView, setToggleView] = useState<string | null>('table_view');

  const handleViewOrientation = (
    _event: MouseEvent<HTMLElement>,
    newValue: string | null
  ) => {
    setToggleView(newValue);
  };

  const [openConfirmDelete, setOpenConfirmDelete] = useState(false);

  const handleConfirmDelete = () => {
    setOpenConfirmDelete(true);
  };

  const closeConfirmDelete = () => {
    setOpenConfirmDelete(false);
  };

  const handleDeleteCompleted = () => {
    setOpenConfirmDelete(false);

    enqueueSnackbar(t('The user account has been removed'), {
      variant: 'success',
      anchorOrigin: {
        vertical: 'top',
        horizontal: 'right'
      },
      TransitionComponent: Zoom
    });
  };

  const getUserGroupMembership = useEffect(() => {
    const countInGroup = selectedItems.filter((user) => {
      const fullUser = users.find(obj => { return obj.email === user })
      if (groupSelected && fullUser) {
        return (fullUser.groups.map(i => i.id).filter(item => item === groupSelected.id).length >= 1) 
      } else {
        return false
      }
    })
    setSelectedUsersInGroup(countInGroup.length / selectedItems.length)

  }, [selectedItems, groupSelected, users])

  const getUserGroupLabel = (group, colorIndex): JSX.Element => {
    /*
    const map = {
      admin: {
        text: 'Administrator',
        color: 'error'
      },
      customer: {
        text: 'Customer',
        color: 'info'
      },
      subscriber: {
        text: 'Subscriber',
        color: 'warning'
      }
    };
    */
  
    const colorsMap:Color[] = ['error', 'warning' , 'primary', 'success', 'secondary']
    // @ts-ignore:next-line
    return <Label onClick={() => {
      setFilters({ group: group.id }); 
      handleTabsChange(null, group.id)}} 
      key={group.id} 
      sx={{mr: 1, mb: .5, mt: .5}} 
      color={colorsMap[2]} >
        {group.name}
    </Label>;
  };

  return (
    <>
            
      <Grid
        display="flex"
        alignItems="center"
        flexDirection={{ xs: 'column', sm: 'row' }}
        justifyContent={{ xs: 'center', sm: 'space-between' }}
        pb={3}
      >
        <Grid sm={5} md={6} lg={7} item>
          <TabsWrapper
            onChange={handleTabsChange}
            scrollButtons="auto"
            textColor="secondary"
            value={filters.group || 'all'}
            variant="scrollable"
          >
            <Tab key={'all'} value={'all'} label={'All Users'} />
            {tabs.map((tab) => (
              <Tab key={tab.id} value={tab.id} label={tab.name} />
            ))}
          </TabsWrapper>
        </Grid>
        <Box
          display="flex"
          alignItems='center'
        >
          <Grid 
            sx={{ 
              backgroundColor: theme.colors.alpha.black[5],
              border: `1px solid ${theme.colors.alpha.black[10]}`,
              padding: '6px',
              display: 'flex',
              justifyContent: 'center',
              mr: 1,
              ml: 1,
              borderRadius: 1.5,
            }}
          item>
            <Button
              sx={{
                mt: { xs: 2, sm: 0 },
                mr: 1,
              }}
              onClick={handleImportGoogleUsers}
              size="medium"
              color="info"
              startIcon={<ImportExportTwoToneIcon fontSize="small" />}
            >
              {t('Import Google Users')}
            </Button>
            <Button
              size="medium"
              onClick={() => setCreateGroupOpen(true)}
              startIcon={<AddIcon />}
            >
              Create Group
            </Button>
          </Grid>
          <Modal
            sx={modalStyle}
            open={createGroupOpen}
            onClose={handleCloseCreateGroupMenu}
          >
            <Card 
              sx={{ p: 4, maxWidth: "40%", margin: 'auto'
            }}>
              <Formik
                initialValues={{ groupName: '' }}
                validate={values => {
                  const errors: any = {};
                  if (!values.groupName) {
                    errors.groupName = 'Required';
                  } 
                  return errors;
                }}
                onSubmit={async (values, { setSubmitting }) => {
                  setSubmitting(true);
                  const result = await organizationAPI.createGroup(values.groupName, user.domain)
                  setSubmitting(false);
                  handleCloseCreateGroupMenu();
                  navigate(0);
                }}
              >
                {({
                  values,
                  errors,
                  touched,
                  handleChange,
                  handleBlur,
                  handleSubmit,
                  isSubmitting,
                  /* and other goodies */
                }) => (
                  <form onSubmit={handleSubmit}>
                    <Typography sx={{ textAlign: 'center', mb: 3, }} variant="h3">Create a new group</Typography>
                    <TextField
                        fullWidth
                        size="medium"
                        label={t('Group Name')}
                        name="groupName"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.groupName}
                        variant="outlined"
                        sx={{ mt: 0, mb: 1 }}
                    />
                    {errors.groupName && touched.groupName && errors.groupName}
                    <LoadingButton size="large" variant="contained" 
                    sx={{mt: 2, background: theme.colors.gradients.purple3}} 
                    fullWidth 
                    type="submit"
                    loading={isSubmitting}>
                      Create Group
                    </LoadingButton>
                  </form>
                )}
              </Formik>

            </Card>
          </Modal>
          <ToggleButtonGroup
            sx={{
              mt: { xs: 2, sm: 0 },
              ml: 1
            }}
            value={toggleView}
            exclusive
            onChange={handleViewOrientation}
          >
            <ToggleButton disableRipple value="table_view">
              <TableRowsTwoToneIcon />
            </ToggleButton>
            <ToggleButton disableRipple value="grid_view">
              <GridViewTwoToneIcon />
            </ToggleButton>
          </ToggleButtonGroup>
        </Box>
      </Grid>
      {toggleView === 'table_view' && (
        <Card>
          <Box p={2}>
            {!selectedBulkActions && (
              <TextField
                sx={{
                  m: 0
                }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchTwoToneIcon />
                    </InputAdornment>
                  )
                }}
                onChange={handleQueryChange}
                placeholder={t('Search by name, email or group...')}
                value={query}
                size="small"
                fullWidth
                margin="normal"
                variant="outlined"
              />
            )}
            {selectedBulkActions && <BulkActions selectionInGroup={selectedUsersInGroup} submitGroupRemove={submitGroupRemove} isSubmittingGroupChange={isSubmittingGroupChange} groupSelected={groupSelected} setGroupSelected={setGroupSelected} submitGroupChange={submitGroupChange}/>}
          </Box>

          <Divider />

          {paginatedUsers.length === 0 ? (
            <>
              <Typography
                sx={{
                  py: 10
                }}
                variant="h3"
                fontWeight="normal"
                color="text.secondary"
                align="center"
              >
                {t("We couldn't find any users matching your search criteria")}
              </Typography>
            </>
          ) : (
            <>
              <TableContainer>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell padding="checkbox">
                        <Checkbox
                          checked={selectedAllUsers}
                          indeterminate={selectedSomeUsers}
                          onChange={handleSelectAllUsers}
                        />
                      </TableCell>
                      <TableCell>{t('Name')}</TableCell>
                      <TableCell>{t('Email')}</TableCell>
                      <TableCell>{t('Groups')}</TableCell>
                      <TableCell>{t('Google ID')}</TableCell>
                      <TableCell align="center">{t('Actions')}</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {paginatedUsers.map((user) => {
                      const isUserSelected = selectedItems.includes(user.email);
                      return (
                        <TableRow hover key={user.id} selected={isUserSelected}>
                          <TableCell padding="checkbox">
                            <Checkbox
                              checked={isUserSelected}
                              onChange={(event) =>
                                handleSelectOneUser(event, user.email)
                              }
                              value={isUserSelected}
                            />
                          </TableCell>
                          <TableCell>
                            <Box display="flex" alignItems="center">
                              <Box>
                                <Link
                                  variant="h5"
                                  component={RouterLink}
                                  to={
                                    `/${
                                      location.pathname.split('/')[1]
                                    }/management/users/single/` + user.id
                                  }
                                >
                                  {user.name}
                                </Link>
                              </Box>
                            </Box>
                          </TableCell>
                          <TableCell>
                            <Typography>{user.email}</Typography>
                          </TableCell>
                          { user.groups && <TableCell>{user.groups.map((val, index) => {return getUserGroupLabel(val, index)})}</TableCell> }
                          <TableCell>
                            <Typography variant="h5">
                              {user.id}
                            </Typography>
                          </TableCell>
                          <TableCell align="center"> 
                            <Typography noWrap>
                              <Tooltip title={t('View')} arrow>
                                <IconButton
                                  component={RouterLink}
                                  to={
                                    `/${
                                      location.pathname.split('/')[1]
                                    }/management/users/single/` + user.id
                                  }
                                  color="primary"
                                >
                                  <LaunchTwoToneIcon fontSize="small" />
                                </IconButton>
                              </Tooltip>
                              <Tooltip title={t('Delete')} arrow>
                                <IconButton
                                  onClick={handleConfirmDelete}
                                  color="primary"
                                >
                                  <DeleteTwoToneIcon fontSize="small" />
                                </IconButton>
                              </Tooltip>
                            </Typography>
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
              <Box p={2}>
                <TablePagination
                  component="div"
                  count={totalCount}
                  onPageChange={handlePageChange}
                  onRowsPerPageChange={handleLimitChange}
                  page={page}
                  rowsPerPage={limit}
                  rowsPerPageOptions={[5, 10, 15]}
                />
              </Box>
            </>
          )}
        </Card>
      )}
      {toggleView === 'grid_view' && (
        <>
          <Card
            sx={{
              p: 2,
              mb: 3
            }}
          >
            <Box
              display="flex"
              alignItems="center"
              justifyContent="space-between"
            >
              {paginatedUsers.length !== 0 && (
                <>
                  <Box display="flex" alignItems="center">
                    <Tooltip
                      arrow
                      placement="top"
                      title={t('Select all users')}
                    >
                      <Checkbox
                        checked={selectedAllUsers}
                        indeterminate={selectedSomeUsers}
                        onChange={handleSelectAllUsers}
                      />
                    </Tooltip>
                  </Box>
                  {selectedBulkActions && (
                    <Box flex={1} pl={2}>
                      <BulkActions selectionInGroup={selectedUsersInGroup} submitGroupRemove={submitGroupRemove} isSubmittingGroupChange={isSubmittingGroupChange} groupSelected={groupSelected} setGroupSelected={setGroupSelected} submitGroupChange={submitGroupChange} />
                    </Box>
                  )}
                </>
              )}
              {!selectedBulkActions && (
                <TextField
                  sx={{
                    my: 0,
                    ml: paginatedUsers.length !== 0 ? 2 : 0
                  }}
                  fullWidth
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <SearchTwoToneIcon />
                      </InputAdornment>
                    )
                  }}
                  onChange={handleQueryChange}
                  placeholder={t('Search by name, email or group...')}
                  value={query}
                  size="small"
                  margin="normal"
                  variant="outlined"
                />
              )}
            </Box>
          </Card>
          {paginatedUsers.length === 0 ? (
            <Typography
              sx={{
                py: 10
              }}
              variant="h3"
              fontWeight="normal"
              color="text.secondary"
              align="center"
            >
              {t("We couldn't find any users matching your search criteria")}
            </Typography>
          ) : (
            <>
              <Grid container spacing={3}>
                {paginatedUsers.map((user) => {
                  const isUserSelected = selectedItems.includes(user.email);

                  return (
                    <Grid item xs={12} sm={6} md={4} key={user.id}>
                      <CardWrapper
                        className={clsx({
                          'Mui-selected': isUserSelected
                        })}
                      >
                        <Box
                          sx={{
                            position: 'relative',
                            zIndex: '2'
                          }}
                        >
                          <Box
                            px={2}
                            pt={2}
                            display="flex"
                            alignItems="flex-end"
                            justifyContent="space-between"
                            sx={{ flexWrap: 'wrap-reverse' }}
                          >
                            <Box sx={{ width: '80%'}}>
                              {user.groups.map((val, index) => {return getUserGroupLabel(val, index)})}
                            </Box>
                            <IconButton
                              color="primary"
                              sx={{
                                p: 0.5
                              }}
                            >
                              <MoreVertTwoToneIcon />
                            </IconButton>
                          </Box>
                          <Box p={2} display="flex" alignItems="flex-start">
                            <Avatar
                              sx={{
                                width: 50,
                                height: 50,
                                mr: 2
                              }}
                              src={user.avatar}
                            />
                            <Box>
                              <Box>
                                <Link
                                  variant="h5"
                                  component={RouterLink}
                                  to={`/${
                                    location.pathname.split('/')[1]
                                  }/management/users/single/${user.id}`}
                                >
                                  {user.name}
                                </Link>{' '}
                                <Typography
                                  component="span"
                                  variant="body2"
                                  color="text.secondary"
                                >
                                  ({user.id})
                                </Typography>
                              </Box>
                              <Typography
                                sx={{
                                  pt: 0.3
                                }}
                                variant="subtitle2"
                              >
                                {user.jobtitle}
                              </Typography>
                              <Typography
                                sx={{
                                  pt: 1
                                }}
                                variant="h6"
                              >
                                {user.email}
                              </Typography>
                            </Box>
                          </Box>
                          <Divider />
                          <Box
                            pl={2}
                            py={1}
                            pr={1}
                            display="flex"
                            alignItems="center"
                            justifyContent="space-between"
                          >
                            <Checkbox
                              checked={isUserSelected}
                              onChange={(event) =>
                                handleSelectOneUser(event, user.email)
                              }
                              value={isUserSelected}
                            />
                          </Box>
                        </Box>
                      </CardWrapper>
                    </Grid>
                  );
                })}
              </Grid>
              <Card
                sx={{
                  p: 2,
                  mt: 3,
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between'
                }}
              >
                <Box >
                  <Typography component="span" variant="subtitle1">
                    {t('Showing')}
                  </Typography>{' '}
                  <b>{limit}</b> {t('of')} <b>{totalCount}</b>{' '}
                  <b>{t('users')}</b>
                </Box>
                <TablePagination
                  component="div"
                  count={totalCount}
                  onPageChange={handlePageChange}
                  onRowsPerPageChange={handleLimitChange}
                  page={page}
                  rowsPerPage={limit}
                  labelRowsPerPage=""
                  rowsPerPageOptions={[5, 10, 15]}
                />
              </Card>
            </>
          )}
        </>
      )}
      {!toggleView && (
        <Card
          sx={{
            textAlign: 'center',
            p: 3
          }}
        >
          <Typography
            align="center"
            variant="h4"
            fontWeight="normal"
            color="text.secondary"
            sx={{
              my: 5
            }}
            gutterBottom
          >
            {t(
              'Choose between table or grid views for displaying the users list.'
            )}
          </Typography>
        </Card>
      )}

      <DialogWrapper
        open={openConfirmDelete}
        maxWidth="sm"
        fullWidth
        TransitionComponent={Transition}
        keepMounted
        onClose={closeConfirmDelete}
      >
        <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
          flexDirection="column"
          p={5}
        >
          <AvatarError>
            <CloseIcon />
          </AvatarError>

          <Typography
            align="center"
            sx={{
              py: 4,
              px: 6
            }}
            variant="h3"
          >
            {t('Are you sure you want to permanently delete this user account')}
            ?
          </Typography>

          <Box>
            <Button
              variant="text"
              size="large"
              sx={{
                mx: 1
              }}
              onClick={closeConfirmDelete}
            >
              {t('Cancel')}
            </Button>
            <ButtonError
              onClick={handleDeleteCompleted}
              size="large"
              sx={{
                mx: 1,
                px: 3
              }}
              variant="contained"
            >
              {t('Delete')}
            </ButtonError>
          </Box>
        </Box>
      </DialogWrapper>
    </>
  );
};

Results.propTypes = {
  users: PropTypes.array.isRequired
};

Results.defaultProps = {
  users: []
};

export default Results;