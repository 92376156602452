import {
    AcUnit,
  Accessibility,
  Announcement,
  Architecture,
  AutoAwesome,
  Backpack,
  BakeryDining,
  Brush,
  BusAlert,
  CakeTwoTone,
  ClearTwoTone,
  Cottage,
  CrisisAlert,
  DeviceThermostat,
  Diamond,
  DirectionsBus,
  DirectionsWalk,
  Error,
  HistoryEdu,
  KeyboardArrowLeft,
  KeyboardArrowRight,
  LaptopChromebook,
  MenuBook,
  School,
  SportsFootballTwoTone,
  SquareFoot,
  StopCircleTwoTone,
  StopTwoTone,
  Storefront
} from '@mui/icons-material';

import { ReactComponent as Football } from './icons/football.svg';
import { ReactComponent as Cake } from './icons/cake.svg';
import { ReactComponent as Exit } from './icons/exit.svg';
import { ReactComponent as Stop } from './icons/stop.svg';
import { ReactComponent as Traffic } from './icons/traffic.svg';
import { ReactComponent as Alarm } from './icons/alarm.svg';
import { ReactComponent as Hold } from './icons/hold.svg';
import { ReactComponent as Lockdown } from './icons/lockdown.svg';
import { ReactComponent as Lockout } from './icons/lockout.svg';
import { ReactComponent as Shelter } from './icons/shelter.svg';
import { SvgIcon, Tooltip } from '@mui/material';
import BannerIcon from 'src/components/atoms/BannerIcon/BannerIcon';
import FullscreenIcon from 'src/components/atoms/FullscreenIcon/FullscreenIcon';
import PopupIcon from 'src/components/atoms/PopupIcon/PopupIcon';
import FullscreenBannerIcon from 'src/components/atoms/FullscreenBannerIcon/FullscreenBannerIcon';
import LogoIncludedIcon from 'src/components/atoms/LogoIncludedIcon/LogoIncludedIcon';
import LogoNotIncludedIcon from 'src/components/atoms/LogoNotIncludedIcon/LogoNotIncludedIcon';

export const iconOptions = [
    'none',
    'cake',
    'stop',
    'football',
    'exit',
    'alarm',
    'traffic',
    'lockdown',
    'lockout',
    'shelter',
    'hold',
    'school',
    'history',
    'square-foot',
    'cold',
    'bus',
    'store',
    'error',
    'bus-alert',
    'crisis-alert',
    'sparkles',
    'bakery',
    'protractor',
    'book',
    'chromebook',
    'backpack',
    'accessibility',
    'brush',
    'cottage',
    'temperature',
    'walking'
]
  
export const iconMap = {
    'none': <ClearTwoTone color="error"></ClearTwoTone>,
    'cake': <Cake></Cake>,
    'stop': <Stop></Stop>,
    'football': <Football></Football>,
    'exit': <Exit></Exit>,
    'alarm': <Alarm></Alarm>,
    'traffic': <Traffic ></Traffic>,
    'lockdown': <Lockdown ></Lockdown>,
    'lockout': <Lockout ></Lockout>,
    'shelter': <Shelter ></Shelter>,
    'hold': <Hold ></Hold>,
    'school': <School></School>,
    'history': <HistoryEdu></HistoryEdu>,
    'square-foot': <SquareFoot></SquareFoot>,
    'cold': <AcUnit></AcUnit>,
    'bus': <DirectionsBus></DirectionsBus>,
    'store': <Storefront></Storefront>,
    'error': <Error></Error>,
    'bus-alert': <BusAlert></BusAlert>,
    'crisis-alert': <CrisisAlert></CrisisAlert>,
    'sparkles': <AutoAwesome></AutoAwesome>,
    'bakery': <BakeryDining></BakeryDining>,
    'protractor': <Architecture></Architecture>,
    'book': <MenuBook></MenuBook>,
    'chromebook': <LaptopChromebook></LaptopChromebook>,
    'backpack': <Backpack></Backpack>,
    'accessibility': <Accessibility></Accessibility>,
    'brush': <Brush></Brush>,
    'cottage': <Cottage></Cottage>,
    'temperature': <DeviceThermostat></DeviceThermostat>,
    'walking': <DirectionsWalk></DirectionsWalk>
}

export function notifStyleIcon(notifStyle) {
    switch(notifStyle) {
        case 'banner':
          return (
            <Tooltip title="Banner notification style">
              <div>
              <BannerIcon color="primary" sx={{display: "block", marginLeft: '-4px', marginTop: '2px', height: '16px'}} ></BannerIcon>
              </div>
            </Tooltip>
          );
        case 'fullscreen':
            return (
              <Tooltip title="Fullscreen notification style">
                <div>
                  <FullscreenIcon color="primary" sx={{marginLeft: '-4px', marginTop: '2px', height: '16px'}} ></FullscreenIcon>
                </div>
              </Tooltip>
            )
        case 'popup':
          return (
            <Tooltip title="Popup notification style">
              <div>
                <PopupIcon color="primary" sx={{marginLeft: '-4px', marginTop: '2px', height: '16px'}} ></PopupIcon>
              </div>
            </Tooltip>
          );
        case 'fullscreenBanner':
          return (
            <Tooltip title="Fullscreen/banner notification style">
              <div>
                <FullscreenBannerIcon color="primary" sx={{marginLeft: '-4px', marginTop: '2px', height: '16px'}} ></FullscreenBannerIcon>
              </div>
            </Tooltip>
          )
    }
  }

export function logoInclusionIcon(logoIncluded: boolean) {
    return logoIncluded ? (
        <Tooltip title="Logo is included">
            <SvgIcon fontSize="small">
                <LogoIncludedIcon sx={{ marginTop: '2px' }} />
            </SvgIcon>
        </Tooltip>
    ) : (
        <Tooltip title="Logo is not included">
            <SvgIcon fontSize="small">
                <LogoNotIncludedIcon sx={{ marginTop: '2px' }} />
            </SvgIcon>
        </Tooltip>
    );
}
  