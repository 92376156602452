import { RouteObject } from 'react-router';
import { Suspense, lazy } from 'react';
import SuspenseLoader from 'src/components/SuspenseLoader';

import Authenticated from 'src/components/Authenticated';
import { Navigate } from 'react-router-dom';

import BaseLayout from 'src/layouts/BaseLayout';
import ExtendedSidebarLayout from 'src/layouts/ExtendedSidebarLayout';
import AlpacaCalendarPage from 'src/components/pages/AlpacaCalendarPage';

import accountRoutes from './account';
import baseRoutes from './base';

import UserManagementIndex from '../content/management/Users/index'
import AlpacaLogging from 'src/components/organisms/AlpacaLogging/AlpacaLogging';
import AlpacaWebhookManager from 'src/components/organisms/AlpacaWebhookManager/AlpacaWebhookManager';
import AlpacaOnboarding from 'src/components/organisms/AlpacaOnboarding/AlpacaOnboarding';

const Loader = (Component) => (props) =>
  (
    <Suspense fallback={<SuspenseLoader />}>
      <Component {...props} />
    </Suspense>
  );

const NotificationDispatchPage = Loader(
    lazy(() => import('src/components/pages/NotificationDispatchPage'))
);

const QuickSendSettingsPage = Loader(
    lazy(() => import('src/components/pages/QuickSendSettingsPage'))
);

const TemplateManagerPage = Loader(
    lazy(() => import('src/components/pages/TemplateManagerPage'))
)
/*
const AlpacaCalendarPage = Loader(
    lazy(() => import('src/components/pages/AlpacaCalendarPage'))
)
*/

const router: RouteObject[] = [
  {
    path: 'account',
    children: accountRoutes
  },
  {
    path: '',
    element: <BaseLayout />,
    children: baseRoutes
  },

  // App Routing
  {
    path: '/welcome',
    element: (
        <AlpacaOnboarding></AlpacaOnboarding>
    )
  },
  {
    path: '/',
    element: (
      <Authenticated>
        <ExtendedSidebarLayout />
      </Authenticated>
    ),
    children: [
        {
            path: 'library',
            element: <TemplateManagerPage />
        },
        {
            path: 'webhooks',
            element: <AlpacaWebhookManager></AlpacaWebhookManager>
        },
        {
            path: 'calendar',
            element: <AlpacaCalendarPage />
        },
        {
            path: 'compose',
            element: <NotificationDispatchPage />
        },
        {
            path: 'google-users',
            element: <UserManagementIndex />
        },
        {
            path: 'quick-send-settings',
            element: <QuickSendSettingsPage />
        },
        {
            path: "logging",
            element: <AlpacaLogging></AlpacaLogging>
        },
    ]
  }
];

export default router;
