import { Button, Checkbox, Container, FormControlLabel, Grid, Paper, Typography } from "@mui/material";
import { useCallback, useState } from "react";
import { useNavigate } from "react-router-dom";
import googleAPI from "src/controllers/googleFunctions";
import organizationAPI from "src/controllers/organizations";

export default function OnboardingStepFour({ setStep, ...otherProps }) {

    const navigate = useNavigate();
    const [ terms, setTerms ] = useState(false);
    const [ emergencyDeclaration, setEmergencyDeclaration ] = useState(false);

    const handleSubmit = useCallback(() => {
        organizationAPI.acceptTerms({ terms, emergencyDeclaration })
            .then((res) => {
                console.log(res);
                navigate('/calendar');
            })
            .catch((err) => { console.log(err) })
    }, [emergencyDeclaration, terms, navigate]); 

    return (
        <>
            <Container maxWidth="md" sx={{ pb: 10}}>
                <Paper sx={{ p: 3 }}>
                    <Grid container>
                        <Grid item xs={12}>
                            <Typography sx={{ textAlign: 'center' }} variant="h2">Terms of Service</Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography sx={{ textAlign: 'center' }} variant="subtitle2">Last updated: 08/17/2023</Typography>
                        </Grid>
                        <Paper variant="outlined" sx={{ p: 2, mt: 2, mb: 2 }}>
                            These terms and conditions (the "Terms and Conditions") govern the use of www.alpacanotify.com
                            (the "Site"). This Site is owned and operated by Alpaca Alerts LLC. This Site is a school messaging
                            application.<br/>
                            By using this Site, you indicate that you have read and understand these Terms and Conditions and
                            agree to abide by them at all times.<br/><br/>
                            THESE TERMS AND CONDITIONS CONTAIN A DISPUTE RESOLUTION CLAUSE THAT
                            IMPACTS YOUR RIGHTS ABOUT HOW TO RESOLVE DISPUTES. PLEASE READ IT
                            CAREFULLY.<br/><br/>

                            <Typography variant="h5">
                            Intellectual Property
                            </Typography>
                            All content published and made available on our Site is the property of Alpaca Alerts LLC and the
                            Site's creators. This includes, but is not limited to images, text, logos, documents, downloadable files
                            and anything that contributes to the composition of our Site.<br/><br/>
                            <Typography variant="h5">
                            Acceptable Use
                            </Typography>

                            As a user of our Site, you agree to use our Site legally, not to use our Site for illegal purposes, and not
                            to:<br/><br/>
                            
                            <li>
                            Harass or mistreat other users of our Site;
                            </li>
                            <li>
                            Violate the rights of other users of our Site;
                            </li>
                            <li>
                            Violate the intellectual property rights of the Site owners or any third party to the Site;
                            </li>
                            <li>
                            Hack into the account of another user of the Site;
                            </li>
                            <li>
                            Act in any way that could be considered fraudulent; or
                            </li>
                            <li>
                            Post any material that may be deemed inappropriate or offensive.
                            </li>
                            <br/>
                            If we believe you are using our Site illegally or in a manner that violates these Terms and Conditions,
                            we reserve the right to limit, suspend or terminate your access to our Site. We also reserve the right to
                            take any legal steps necessary to prevent you from accessing our Site.<br/><br/>
                            <Typography variant="h5">
                            Accounts
                            </Typography>
                            When you create an account on our Site, you agree to the following:
                            <br/>
                            1. You are solely responsible for your account and the security and privacy of your account,
                            including passwords or sensitive information attached to that account; and
                            2. All personal information you provide to us through your account is up to date, accurate, and
                            truthful and that you will update your personal information if it changes.
                            We reserve the right to suspend or terminate your account if you are using our Site illegally or if you
                            violate these Terms and Conditions.
                            <br/><br/>
                            <Typography variant="h5">
                            Sale of Goods And Services
                            </Typography>
                            These Terms and Conditions govern the sale of goods and services available on our Site.
                            The following goods are available on our Site:
                            <li>School Messaging Platform;</li> 
                            <li>Google Chrome Extension; and</li>
                            <li>Chromecast Tool for Digital Signage.</li>
                            The following services are available on our Site:
                            <li>Integrations and IT Support.</li>
                            <br/>
                            The services will be paid for in full when the services are ordered.
                            These Terms and Conditions apply to all the goods and services that are displayed on our Site at the
                            time you access it. This includes all products listed as being out of stock. All information, descriptions,
                            or images that we provide about our goods and services are as accurate as possible. However, we are
                            not legally bound by such information, descriptions, or images as we cannot guarantee the accuracy of
                            all goods and services we provide. You agree to purchase goods and services from our Site at your own
                            risk.<br/>
                            We reserve the right to modify, reject or cancel your order whenever it becomes necessary. If we cancel
                            your order and have already processed your payment, we will give you a refund equal to the amount
                            you paid. You agree that it is your responsibility to monitor your payment instrument to verify receipt
                            of any refund.<br/><br/>
                            <Typography variant="h5">
                            Subscriptions
                            </Typography>
                            Your subscription automatically renews and you will be automatically billed until we receive
                            notification that you want to cancel the subscription.<br/>
                            To cancel your subscription, please follow these steps: Money back guarantee within 30 days of
                            corresponding purchase.<br/><br/>
                            <Typography variant="h5">
                            Payments
                            </Typography>
                            We accept the following payment methods on our Site:
                            <li>Credit Card;</li>
                            <li>PayPal;</li>
                            <li>Debit;</li>
                            <li>Direct Debit; and</li>
                            <li>ACH Payments.</li><br/>
                            When you provide us with your payment information, you authorize our use of and access to the
                            payment instrument you have chosen to use. By providing us with your payment information, you
                            authorize us to charge the amount due to this payment instrument.<br/>
                            If we believe your payment has violated any law or these Terms and Conditions, we reserve the right to
                            cancel or reverse your transaction.<br/><br/>
                            <Typography variant="h5">
                            Refunds
                            </Typography>
                            Refunds for Goods
                            All goods sold on our Site are non-refundable.
                            Refunds for Services
                            We provide refunds for services sold on our Site as follows:
                            - The services will be fully refunded if the services are cancelled at least 48 hours before the
                            services were scheduled to be provided.
                            <br/><br/>
                            <Typography variant="h5">
                            Consumer Protection Law
                            </Typography>
                            Where any consumer protection legislation in your jurisdiction applies and cannot be excluded, these
                            Terms and Conditions will not limit your legal rights and remedies under that legislation. These Terms
                            and Conditions will be read subject to the mandatory provisions of that legislation. If there is a conflict
                            between these Terms and Conditions and that legislation, the mandatory provisions of the legislation
                            will apply.<br/><br/>   
                            <Typography variant="h5">
                            Links to Other Websites
                            </Typography>
                            Our Site contains links to third party websites or services that we do not own or control. We are not
                            responsible for the content, policies, or practices of any third party website or service linked to on our
                            Site. It is your responsibility to read the terms and conditions and privacy policies of these third party
                            websites before using these sites.<br/><br/>
                            <Typography variant="h5">
                            Limitation of Liability
                            </Typography>
                            Alpaca Alerts LLC and our directors, officers, agents, employees, subsidiaries, and affiliates will not be
                            liable for any actions, claims, losses, damages, liabilities and expenses including legal fees from your
                            use of the Site.<br/><br/>
                            <Typography variant="h5">
                            Indemnity
                            </Typography>
                            Except where prohibited by law, by using this Site you indemnify and hold harmless Alpaca Alerts
                            LLC and our directors, officers, agents, employees, subsidiaries, and affiliates from any actions, claims,
                            losses, damages, liabilities and expenses including legal fees arising out of your use of our Site or your
                            violation of these Terms and Conditions.<br/><br/>
                            <Typography variant="h5">
                            Applicable Law
                            </Typography>
                            These Terms and Conditions are governed by the laws of the State of Oregon.<br/><br/>
                            <Typography variant="h5">
                            Dispute Resolution
                            </Typography>
                            Subject to any exceptions specified in these Terms and Conditions, if you and Alpaca Alerts LLC are
                            unable to resolve any dispute through informal discussion, then you and Alpaca Alerts LLC agree to
                            submit the issue first before a non-binding mediator and to an arbitrator in the event that mediation
                            fails. The decision of the arbitrator will be final and binding. Any mediator or arbitrator must be a
                            neutral party acceptable to both you and Alpaca Alerts LLC. The costs of any mediation or arbitration
                            will be paid by the unsuccessful party.<br/>
                            Notwithstanding any other provision in these Terms and Conditions, you and Alpaca Alerts LLC agree
                            that you both retain the right to bring an action in small claims court and to bring an action for
                            injunctive relief or intellectual property infringement.<br/><br/>
                            <Typography variant="h5">
                            Severability
                            </Typography>
                            If at any time any of the provisions set forth in these Terms and Conditions are found to be inconsistent
                            or invalid under applicable laws, those provisions will be deemed void and will be removed from these
                            Terms and Conditions. All other provisions will not be affected by the removal and the rest of these
                            Terms and Conditions will still be considered valid.<br/><br/>
                            <Typography variant="h5">
                            Changes
                            </Typography>
                            These Terms and Conditions may be amended from time to time in order to maintain compliance with
                            the law and to reflect any changes to the way we operate our Site and the way we expect users to
                            behave on our Site. We will notify users by email of changes to these Terms and Conditions or post a
                            notice on our Site.
                            Contact Details
                            Please contact us if you have any questions or concerns. Our contact details are as follows:<br/>
                            (520) 780-4953<br/>
                            kyle@alpacanotify.com<br/>
                            7135 SW 36th Ave, Portland, Oregon, 97219<br/>
                            You can also contact us through the feedback form available on our Site.
                        </Paper>
                        
                        <FormControlLabel control={
                            <Checkbox onChange={(e, checked) => { setTerms(checked) }} value={terms} sx={{ pt: .25, pb: .25 }}></Checkbox>
                        } label="I accept the terms of service"></FormControlLabel>

                        <FormControlLabel control={
                            <Checkbox onChange={(e, checked) => { setEmergencyDeclaration(checked) }} value={emergencyDeclaration} sx={{ pt: .25, pb: .25 }}></Checkbox>
                        } label="I acknowledge that Alpaca Notify will not be my organization's primary form of emergency notifications."></FormControlLabel>
                        
                        
                    </Grid>
                </Paper>
            </Container>
            <Grid item justifySelf="flex-end" sx={{ position: "fixed", bottom: 0 }} xs={12}>
                <Paper variant="outlined" sx={{
                    height: '64px',
                    width: "100svw",
                    paddingLeft: '20svw',
                    paddingRight: '20svw',
                    display: 'flex',
                    justifyContent: 'space-between',
                    paddingTop: '12px',
                    paddingBottom: '12px',
                    borderRadius: 0,
                }}>
                    <Button sx={{ width: '128px' }} variant="contained" onClick={() => { /* submit */ setStep(2)}}>Back</Button>
                    <Button variant="contained" onClick={handleSubmit} >Complete Onboarding</Button>
                </Paper>
            </Grid>
        </>
    )
}